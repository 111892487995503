import { api } from "../api";

export const createProject = async (
  data
  //  {
  //   clientId,
  //   internalClient,
  //   projectNumber,
  //   projectName,
  //   stageId,
  //   providenceId,
  //   deadline,
  //   powerDistributionCompanyId,
  //   engineerId,
  //   power,
  //   price,
  //   note,
  //   clientType,
  //   npParams,
  //   leParams,
  //   street,
  //   number,
  //   district,
  //   addressDetails,
  //   city,
  //   state,
  //   zipCode
  // }
) => {
  let url = `/projects`;

  return await api.post(url, data);
};

export const deleteProject = async (projectId) => {
  let url = `/projects/${projectId}`;

  return await api.delete(url);
};

export const generateAttorneyPdf = async (projectId) => {
  let url = `/projects/${projectId}/attorney`;
  return await api.post(
    url,
    {},
    {
      responseType: "blob",
    }
  );
};
