import { Box, Button, Skeleton } from "@mui/material";
import React, { useState } from "react";
import { useQueryClient } from "react-query";
import { ToastContainer } from "react-toastify";
import { useFetchAllowedEmails } from "../../../hooks/api/adminApiHooks";
import { useLoginTextFields } from "../../../hooks/forms/loginHooks";
import { useLoadingToast } from "../../../hooks/toast/toasts";
import { createAllowedEmail } from "../../../services/api/api";
import EmailTextField from "../../textFields/EmailTextField";
import EmailsResult from "./email/EmailsResult";

export default function EmailManagement() {
  const queryClient = useQueryClient();
  const { initializeLoadingToast, updateLoadingToast } = useLoadingToast();

  const [createEmailOpen, setCreateEmailOpen] = useState(false);
  const [saveRequestLoading, setSaveRequestLoading] = useState(false);
  const { allowedEmailsLoading, allowedEmailsData } = useFetchAllowedEmails();
  const { reset: emailReset, ...email } = useLoginTextFields("email", "");

  const handleCreateEmailClick = () => {
    setCreateEmailOpen(!createEmailOpen);
  };

  const handleSaveNewEmailClick = async (event) => {
    event.preventDefault();
    let createToastId;
    try {
      setSaveRequestLoading(true);
      createToastId = initializeLoadingToast({
        text: `Adicionando E-mail...`,
      });

      await createAllowedEmail(email.value);

      queryClient.invalidateQueries({ queryKey: ["allowedEmails"] });

      emailReset();
      handleCreateEmailClick();

      updateLoadingToast({
        id: createToastId,
        text: `E-mail Adicionado com sucesso`,
        type: "success",
      });
    } catch (error) {
      updateLoadingToast({
        id: createToastId,
        text: error.response.data.message ?? "Erro ao adicionar E-mail, tente novamente",
        type: "error",
      });
    } finally {
      setSaveRequestLoading(false);
    }
  };

  return (
    <Box width="100%">
      <ToastContainer />

      <Button sx={{ mb: 2 }} variant="contained" onClick={handleCreateEmailClick}>
        Adicionar novo Email
      </Button>

      {createEmailOpen && (
        <React.Fragment>
          <form
            onSubmit={handleSaveNewEmailClick}
            style={{
              display: "flex",
              gap: 2,
              mb: 2,
            }}
          >
            <EmailTextField params={email} required={true} width="40%" disabled={false} />

            <Button
              variant="outlined"
              type="submit"
              disabled={email.error || email.value === "" || saveRequestLoading}
              sx={{ height: 56 }}
            >
              {saveRequestLoading ? "Adicionando..." : "Adicionar"}
            </Button>
          </form>
        </React.Fragment>
      )}

      {allowedEmailsLoading ? (
        <Skeleton variant="rounded" width="100%" height={130} />
      ) : (
        <Box display="flex" flexDirection="column">
          {allowedEmailsData.map((allowedEmail) => {
            return (
              <EmailsResult
                key={allowedEmail.id}
                emailData={allowedEmail}
                initializeLoadingToast={initializeLoadingToast}
                updateLoadingToast={updateLoadingToast}
              />
            );
          })}
        </Box>
      )}
    </Box>
  );
}
