import React, { useContext, useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import CustomTextField from "../../textFields/CustomTextField";

import { useQueryClient } from "react-query";
import { ToastContainer } from "react-toastify";
import { ApiDataContext } from "../../../contexts/ApiDataContext";
import useDialog from "../../../hooks/dialog/useDialog";
import { useFormField } from "../../../hooks/forms/FormHooks";
import {
  useCheckBox,
  useManagementTextField,
} from "../../../hooks/forms/ManagementHooks";
import { useLoadingToast } from "../../../hooks/toast/toasts";
import { deletePdc, updatePdc } from "../../../services/api/api";
import CustomAutoComplete from "../../autoCompletes/CustomAutoComplete";
import CustomLoadingButton from "../../button/CustomLoadingButton";
import CustomDialog from "../../dialog/CustomDialog";
import { FormatMoney } from "../../format/Formats";
import FormatedCustomTextField from "../../textFields/FormatedCustomTextField";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function EditPowerDistributionCompany() {
  const { apiData } = useContext(ApiDataContext);
  const { initializeLoadingToast, updateLoadingToast } = useLoadingToast();

  const [powerDistributionCompanyValue, setPowerDistributionCompanyValue] =
    useState("");

  const handlePowerDistributionCompanyChange = async (event, newValue) => {
    setPowerDistributionCompanyValue(newValue);
  };

  const powerDistributionCompany = {
    value: powerDistributionCompanyValue,
    onChange: handlePowerDistributionCompanyChange,
  };

  const {
    reset: resetPowerDistributionCompanyName,
    ...powerDistributionCompanyName
  } = useManagementTextField(powerDistributionCompanyValue?.name);

  const { reset: resetState, ...state } = useFormField(
    "autocompleteChangeInitialValue",
    powerDistributionCompanyValue?.states,
    false
  );

  const hasProcessing = useCheckBox(
    powerDistributionCompanyValue?.processing === 1
  );
  const hasADifferentPrice = useCheckBox(
    powerDistributionCompanyValue?.isArtDifferent === 1
  );
  const { reset: resetPrice, ...price } = useManagementTextField(
    powerDistributionCompanyValue?.artPrice
  );

  const isDisabled = false;

  const clearAllFields = () => {
    setPowerDistributionCompanyValue(null);
  };
  const queryClient = useQueryClient();
  const [requestLoading, setRequestLoading] = useState(false);

  const handleUpdateClick = async () => {
    let updateToastId;
    try {
      setRequestLoading(true);
      updateToastId = initializeLoadingToast({
        text: `Atualizando Concessionária...`,
      });

      const processing = hasProcessing.checked === true ? 1 : 0;

      const differentARTPrice = hasADifferentPrice.checked === true ? 1 : 0;

      const { status } = await updatePdc(
        powerDistributionCompanyValue.id,
        powerDistributionCompanyName.value,
        state.value.id,
        processing,
        differentARTPrice,
        price.value
      );

      if (status === 200) {
        clearAllFields();
        queryClient.invalidateQueries({ queryKey: ["baseDataQuery"] });

        updateLoadingToast({
          id: updateToastId,
          text: `Concessionária Atualizada com sucesso`,
          type: "success",
        });
      }
    } catch (error) {
      updateLoadingToast({
        id: updateToastId,
        text:
          error.response.data.message ??
          `Erro ao atualizar Concessionária, tente novamente`,
        type: "error",
      });
    } finally {
      setRequestLoading(false);
    }
  };

  const [deleteRequestLoading, setDeleteRequestLoading] = useState(false);
  const { open, handleClickOpen, handleClickClose } = useDialog();

  const handleDeleteClick = async () => {
    let deleteToastId;
    try {
      setDeleteRequestLoading(true);
      deleteToastId = initializeLoadingToast({
        text: `Excluindo Concessionária...`,
      });

      const { status } = await deletePdc(powerDistributionCompanyValue.id);

      if (status === 200) {
        clearAllFields();
        queryClient.invalidateQueries({ queryKey: ["baseDataQuery"] });

        updateLoadingToast({
          id: deleteToastId,
          text: `Concessionária Excluída com sucesso`,
          type: "success",
        });
      }
    } catch (error) {
      updateLoadingToast({
        id: deleteToastId,
        text:
          error.response.data.message ??
          `Erro ao Excluir Concessionária, tente novamente`,
        type: "error",
      });
    } finally {
      setDeleteRequestLoading(false);
      handleClickClose();
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        height: 450,
      }}
    >
      <ToastContainer />

      <CustomDialog
        open={open}
        handleClickClose={handleClickClose}
        title="Excluir Concessionária"
        contentText="As informações da concessionária serão excluídas e não poderão ser recuperadas."
        requestLoading={deleteRequestLoading}
        handleConfirmationClick={handleDeleteClick}
        confirmationClickText="Excluir"
        requestLoadingText="Excluindo..."
      />
      <CustomAutoComplete
        params={powerDistributionCompany}
        options={apiData.powerDistributionCompanies}
        disabled={false}
        label="Selecione a concessionária"
        width="40%"
      />
      {powerDistributionCompanyValue ? (
        <Box
          sx={{
            mt: 2,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            p: 2,
            justifyContent: "space-between",
            borderRadius: 3,
            height: "55%",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                width: "45%",
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
              }}
            >
              <CustomTextField
                params={powerDistributionCompanyName}
                disabled={isDisabled}
                label="Nome da Concessionária"
                errorLabel="Nome Incorreto"
                maxLength={30}
              />
              <CustomAutoComplete
                params={state}
                options={apiData.states}
                disabled={isDisabled}
                label="Estado"
                width="100%"
              />
            </Box>
            <Box
              sx={{
                width: "45%",
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
              }}
            >
              <FormControlLabel
                control={<Checkbox {...hasProcessing} />}
                label="Possui processamento?"
                labelPlacement="start"
              />
              <Box display="flex" sx={{ justifyContent: "space-between" }}>
                <FormControlLabel
                  control={<Checkbox {...hasADifferentPrice} />}
                  label="Outro Valor de ART?"
                  labelPlacement="start"
                />
                <FormatedCustomTextField
                  params={price}
                  disabled={!hasADifferentPrice.checked}
                  label="Valor"
                  errorLabel="Valor Incorreto"
                  inputcomponent={FormatMoney}
                  required={false}
                  width="45%"
                />
              </Box>
            </Box>
          </Box>
          <Box
            display="flex"
            width="45%"
            sx={{
              justifyContent: "space-between",
              alignSelf: "flex-end",
              mt: 5,
            }}
          >
            <Button
              variant="outlined"
              color="error"
              sx={{
                width: "45%",
              }}
              disabled={requestLoading || deleteRequestLoading}
              onClick={handleClickOpen}
            >
              Excluir
            </Button>

            <CustomLoadingButton
              buttonText="Atualizar"
              buttonLoadingText="Atualizando..."
              sx={{ width: "45%" }}
              disabled={requestLoading || deleteRequestLoading}
              handleButtonClick={handleUpdateClick}
              requestLoading={requestLoading}
            />
          </Box>
        </Box>
      ) : null}
    </Box>
  );
}
