import Container from "@mui/material/Container";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { Box } from "@mui/system";
import React, { useState } from "react";
import AppBarMenu from "../../components/AppBarMenu";
import CustomTabPanel from "../../components/CustomTabPanel";
import EmailManagement from "../../components/admin/adminPanel/EmailManagement";
import UsersManagement from "../../components/admin/adminPanel/UsersManagement";
import ReportsManagement from "../../components/admin/adminPanel/report";

export default function AdminPanelPage() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <AppBarMenu>
      <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 3 }}>
          <Tabs value={value} onChange={handleChange}>
            <Tab label="Emails" />
            <Tab label="Usuários" />
            <Tab label="Relatórios" />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <EmailManagement />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <UsersManagement />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <ReportsManagement />
        </CustomTabPanel>
      </Container>
    </AppBarMenu>
  );
}
