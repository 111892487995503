import React, { useState } from "react";
import Box from "@mui/material/Box";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomLoadingButton from "../../button/CustomLoadingButton";
import useEnginnerForm from "./controller";
import EngineerForm from "./EngineerForm";
import { useLoadingToast } from "../../../hooks/toast/toasts";
import useAddressForm from "../../forms/address/controller";
import { createEngineer } from "../../../services/api/api";
import { useQueryClient } from "react-query";

export default function CreateEngineer() {
  const controller = useEnginnerForm();
  const addressController = useAddressForm();
  const [requestLoading, setRequestLoading] = useState(false);
  const queryClient = useQueryClient();
  const { initializeLoadingToast, updateLoadingToast } = useLoadingToast();
  const totalWidth = "100%";

  const handleCreateClick = async () => {
    let toastId;
    try {
      if (!controller.areBasicFieldsValid()) {
        return;
      }
      setRequestLoading(true);
      toastId = initializeLoadingToast({
        text: `Criando Engenheiro...`,
      });
      const engineerData = {
        ...controller.values(),
        ...addressController.values(),
      };
      const { status } = await createEngineer(engineerData);
      if (status === 201) {
        controller.clearAllFields();
        addressController.clearAllFields();
        queryClient.invalidateQueries({ queryKey: ["baseDataQuery"] });
        updateLoadingToast({
          id: toastId,
          text: `Engenheiro criado com sucesso`,
          type: "success",
        });
      }
    } catch (error) {
      updateLoadingToast({
        id: toastId,
        text: error.response?.data?.message ?? "Erro ao criar engenheiro, tente novamente",
        type: "error",
      });
    } finally {
      setRequestLoading(false);
    }
  };

  return (
    <Box
      sx={{
        width: totalWidth,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <ToastContainer />

      <Box
        sx={{
          width: totalWidth,
          display: "flex",
          flexDirection: "column",
          p: 2,
          justifyContent: "space-between",
          borderRadius: 3,
          height: "100%",
        }}
      >
        <EngineerForm controller={controller} addressController={addressController} />

        <CustomLoadingButton
          buttonText="Cadastrar"
          buttonLoadingText="Cadastrando..."
          sx={{ alignSelf: "flex-end", width: "15%", mt: 2 }}
          disabled={requestLoading}
          handleButtonClick={handleCreateClick}
          requestLoading={requestLoading}
        />
      </Box>
    </Box>
  );
}
