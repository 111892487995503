import { Box, Skeleton } from "@mui/material";
import React from "react";
import { ToastContainer } from "react-toastify";
import { useFetchUsers } from "../../../hooks/api/adminApiHooks";
import UsersResult from "./users/UsersResult";
import { useLoadingToast } from "../../../hooks/toast/toasts";

export default function UsersManagement() {
  const { usersLoading, usersData } = useFetchUsers();
  const { initializeLoadingToast, updateLoadingToast } = useLoadingToast();

  return (
    <Box width="100%">
      <ToastContainer />

      {usersLoading ? (
        <Skeleton variant="rounded" width="100%" height={325}/>
      ) : (
        <Box display="flex" flexDirection="column">
          {usersData.map((userData) => {
            return (
              <UsersResult
                key={userData.id}
                userData={userData}
                initializeLoadingToast={initializeLoadingToast}
                updateLoadingToast={updateLoadingToast}
              />
            );
          })}
        </Box>
      )}
    </Box>
  );
}
