import React, { useState } from "react";
import AppBarMenu from "../components/AppBarMenu";

import Container from "@mui/material/Container";

import EditComissions from "../components/comissions/EditComissions";
import VisualizeComissions from "../components/comissions/VisualizeComissions";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import CustomTabPanel from "../components/CustomTabPanel";

export default function ComissionsPage() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <AppBarMenu>
      <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 3}}>
          <Tabs

            value={value}
            onChange={handleChange}
          >
            <Tab label="Visualizar" />
            <Tab label="Editar" />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <VisualizeComissions />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <EditComissions />
        </CustomTabPanel>
      </Container>
    </AppBarMenu>
  );
}
