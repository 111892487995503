import { api } from "../api";
export const getStages = async () => {
  let url = "/stages";
  return await api.get(url);
};

export const getClientStages = async (clientId) => {
  let url = `/stages/client/${clientId}`;

  return await api.get(url);
};

export const getStagesHistory = async (projectId) => {
  let url = `/stages/history/${projectId}`;

  return await api.get(url);
};

export const sendClientStagesReport = async (clients) => {
  let url = `/stages/report`;

  return await api.post(url, {
    clients,
  });
};

export const sendClientsMessage = async (clients, message) => {
  let url = `/stages/message`;

  return await api.post(url, {
    clients,
    message
  });
};
