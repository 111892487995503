import Check from "@mui/icons-material/Check";
import { Typography } from "@mui/material";
import Step from "@mui/material/Step";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { formatDate } from "../../services/date";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg, rgb(8, 4, 76) 0%,rgb(104, 80, 62) 40%,rgb(202, 153, 0) 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg, rgb(8, 4, 76) 0%,rgb(104, 80, 62) 40%,rgb(202, 153, 0) 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.isActive && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(202, 153, 0) 0%,rgb(104, 80, 62) 40%,rgb(8, 4, 76) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
}));

function ColorlibStepIcon(props) {
  const { isActive, icon, onClick } = props;

  return (
    <ColorlibStepIconRoot
      ownerState={{ isActive }}
      onClick={onClick}
      style={{ cursor: "pointer" }} // Add cursor style to indicate it's clickable
    >
      {isActive ? <Check /> : icon}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  isActive: PropTypes.bool.isRequired,
  icon: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default function CustomStepper({ steps }) {
  return (
    <Stepper
      alternativeLabel
      activeStep={steps.findIndex((step) => step.isActive)}
      connector={<ColorlibConnector />}
    >
      {steps.map((step, i) => (
        <Step key={i} completed={step.isActive}>
          <StepLabel
            StepIconComponent={(props) => (
              <ColorlibStepIcon
                {...props}
                isActive={step.isActive}
                icon={step.icon}
                // onClick={() => step.onToggle()}
              />
            )}
          >
            <Typography sx={{ fontWeight: "bold" }}>{step.label}</Typography>
            <Typography sx={{ fontSize: 14 }}>
              {step.date != null && step.date != undefined
                ? formatDate(step.date)
                : ""}
            </Typography>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}
