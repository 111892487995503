import React, { useState } from "react";
import AppBarMenu from "../components/AppBarMenu";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import ClientManagement from "../components/management/ClientManagement";
import PowerDistributionCompanyManagement from "../components/management/PowerDistributionCompanyManagement";
import ProjectManagement from "../components/management/ProjectManagement";
import EngineerManagement from "../components/management/EgineerManagement";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={index} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export default function ManagementPage() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <AppBarMenu>
      <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 3 }}>
              <Tabs value={value} onChange={handleChange}>
                <Tab label="Projeto" />
                <Tab label="Integrador" />
                <Tab label="Concessionária" />
                <Tab label="Engenheiros" />
              </Tabs>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Paper
              sx={{
                p: 3,
                display: "flex",
                flexDirection: "column",
                minHeight: 600,
              }}
            >
              <CustomTabPanel value={value} index={0}>
                <ProjectManagement />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <ClientManagement />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                <PowerDistributionCompanyManagement />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={3}>
                <EngineerManagement />
              </CustomTabPanel>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </AppBarMenu>
  );
}
