import AddCircleIcon from "@mui/icons-material/AddCircle";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import Groups2Icon from "@mui/icons-material/Groups2";
import HomeIcon from "@mui/icons-material/Home";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import LogoutIcon from "@mui/icons-material/Logout";
import PendingIcon from "@mui/icons-material/Pending";
import TaskIcon from "@mui/icons-material/Task";
import Divider from "@mui/material/Divider";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useTheme } from "@mui/system";
import * as React from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import "./listItem.css";

export default function ListItems() {
  const { logout } = useContext(AuthContext);
  const theme = useTheme();

  const textColor = theme.palette.text.primary;
  const iconColor = theme.palette.icon.primary;
  const dividerColor = theme.palette.icon.secondary;

  const menuLinkStyle = {
    textDecoration: "none",
    color: textColor,
  };

  const iconStyle = {
    color: iconColor,
  };

  const dividerStyle = {
    my: 1,
    backgroundColor: dividerColor,
  };

  return (
    <React.Fragment>
      <Link to="/home" style={menuLinkStyle}>
        <ListItemButton>
          <ListItemIcon>
            <HomeIcon style={iconStyle} />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItemButton>
      </Link>
      <Link to="/finances" style={menuLinkStyle}>
        <ListItemButton>
          <ListItemIcon>
            <AccountBalanceIcon style={iconStyle} />
          </ListItemIcon>
          <ListItemText primary="Financeiro" />
        </ListItemButton>
      </Link>
      <Link to="/activities" style={menuLinkStyle}>
        <ListItemButton>
          <ListItemIcon>
            <TaskIcon style={iconStyle} />
          </ListItemIcon>
          <ListItemText primary="Atividades" />
        </ListItemButton>
      </Link>

      <Link to="/manage" style={menuLinkStyle}>
        <ListItemButton>
          <ListItemIcon>
            <AddCircleIcon style={iconStyle} />
          </ListItemIcon>
          <ListItemText primary="Gestão" />
        </ListItemButton>
      </Link>

      <Link to="/stages" style={menuLinkStyle}>
        <ListItemButton>
          <ListItemIcon>
            <PendingIcon style={iconStyle} />
          </ListItemIcon>
          <ListItemText primary="Etapas" />
        </ListItemButton>
      </Link>

      <Divider sx={dividerStyle} />

      <Link to="/meeting" style={menuLinkStyle}>
        <ListItemButton>
          <ListItemIcon>
            <Groups2Icon style={iconStyle} />
          </ListItemIcon>
          <ListItemText primary="Reunião" />
        </ListItemButton>
      </Link>

      <Link to="/comissions" style={menuLinkStyle}>
        <ListItemButton>
          <ListItemIcon>
            <LocalAtmIcon style={iconStyle} />
          </ListItemIcon>
          <ListItemText primary="Comissões" />
        </ListItemButton>
      </Link>

      <Link to="/admin-panel" style={menuLinkStyle}>
        <ListItemButton>
          <ListItemIcon>
            <AdminPanelSettingsIcon style={iconStyle} />
          </ListItemIcon>
          <ListItemText primary="Painel do Adm" />
        </ListItemButton>
      </Link>

      {/* <Link to="/time-management" style={menuLinkStyle}>
        <ListItemButton>
          <ListItemIcon>
            <TimerIcon style={iconStyle} />
          </ListItemIcon>
          <ListItemText primary="Gestão de Tempo" />
        </ListItemButton>
      </Link> */}

      <Divider sx={dividerStyle} />
      <ListItemButton onClick={logout}>
        <ListItemIcon>
          <LogoutIcon style={iconStyle} />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItemButton>
    </React.Fragment>
  );
}
