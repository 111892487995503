import React, { useContext, useState } from "react";
import { useAutocomplete, useFormField } from "../../hooks/forms/FormHooks";
import StagesAutoComplete from "../autoCompletes/StagesAutoComplete";

import EditIcon from "@mui/icons-material/Edit";
import EditOffIcon from "@mui/icons-material/EditOff";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useEffect, useRef } from "react";
import { useQueryClient } from "react-query";
import { ApiDataContext } from "../../contexts/ApiDataContext";
import { updateFilteredProject } from "../../services/api/api";
import DatePicker from "../DatePicker";
import ProvidencesAutoComplete from "../autoCompletes/ProvidencesAutoComplete";
import CustomLoadingProgressButton from "../button/CustomLoadingProgressButton";
import { useDatePicker } from "../../hooks/forms/useDatePicker";

export default function FilterResultSummary({ index, project }) {
  const { apiData } = useContext(ApiDataContext);

  const [expanded, setExpanded] = useState();

  const handleAccordionChange = (value) => {
    setExpanded(value);
  };

  const handleCancel = () => {
    resetStage();
    resetProvidence();
    resetDeadline();
    setExpanded(0);
  };

  const timer = useRef();

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const queryClient = useQueryClient();

  const [requestLoading, setRequestLoading] = useState(false);
  const [requestSuccess, setRequestSuccess] = useState(false);
  const [requestError, setRequestError] = useState(false);

  const handleSave = async () => {
    try {
      setRequestSuccess(false);
      setRequestError(false);
      setRequestLoading(true);

      const projectId = project.id;

      const historyStageId = project.stage.id;
      const historyProvidenceId = project.providence.id;

      const stageId = stage.value.id;
      const providenceId = providence.value.id;

      const { status } = await updateFilteredProject(
        projectId,
        historyStageId,
        historyProvidenceId,
        stageId,
        providenceId,
        deadline.value
      );

      if (status === 200) {
        queryClient.invalidateQueries({ queryKey: ["homeBaseQuery"] });
        queryClient.invalidateQueries({ queryKey: ["filteredProjectsQuery"] });
        setRequestSuccess(true);

        timer.current = window.setTimeout(() => {
          setExpanded(0);
        }, 500);
      }
    } catch (error) {
      setRequestError(true);
    } finally {
      setRequestLoading(false);
    }
  };

  const initialStageValue = {
    id: project.stage.stageId,
    stageName: project.stage.stageName,
  };

  const { reset: resetStage, ...stage } = useAutocomplete(initialStageValue, false);

  const initialProvidenceValue = {
    id: project.providence.providenceId,
    providenceName: project.providence.providenceName,
  };

  const { reset: resetProvidence, ...providence } = useAutocomplete(initialProvidenceValue, false);

  const { reset: resetDeadline, ...deadline } = useDatePicker(project.dueDate);

  return (
    <Accordion
      expanded={expanded === index + 1}
      // onChange={() => handleAccordionChange(index + 1)}
      sx={{
        px: 1,
        py: 2,
        mt: 1,
        cursor: "initial !important",
      }}
    >
      <AccordionSummary
        expandIcon={
          expanded === index + 1 ? (
            <EditOffIcon
              onClick={() => handleAccordionChange(0)}
              sx={{ transform: "rotate(180deg)" }}
            />
          ) : (
            <EditIcon onClick={() => handleAccordionChange(index + 1)} />
          )
        }
        sx={{ cursor: "initial", width: "100%" }}
        aria-controls="panel2a-content"
        id="panel2a-header"
      >
        <Box sx={{ display: "flex", gap: 1, cursor: "initial", width: "98%" }}>
          <TextField
            disabled={true}
            label="Numero"
            value={project.projectNumber}
            sx={{ width: "10%" }}
          />
          <TextField disabled={true} label="Nome" value={project.name} sx={{ width: "15%" }} />

          <TextField
            disabled={true}
            label="Integrador"
            value={project.clientName}
            sx={{ width: "15%" }}
          />

          <StagesAutoComplete
            disabled={!(expanded === index + 1)}
            params={stage}
            options={apiData.stages}
            label="Etapa"
            width="30%"
          />

          <ProvidencesAutoComplete
            disabled={!(expanded === index + 1)}
            params={providence}
            options={apiData.providences}
            label="Providencia"
            width="15%"
          />

          <DatePicker
            disabled={!(expanded === index + 1)}
            width="15%"
            label="Prazo Máximo"
            params={deadline}
          />
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: 2,
          }}
        >
          <Button variant="outlined" color="error" onClick={handleCancel} disabled={requestLoading}>
            CANCELAR
          </Button>
          <CustomLoadingProgressButton
            requestSuccess={requestSuccess}
            requestError={requestError}
            requestLoading={requestLoading}
            handleButtonClick={handleSave}
            buttonText="Salvar"
            buttonLoadingText="Salvando..."
          />
          {/* <Button variant="contained" onClick={handleSave}>
            SALVAR
          </Button> */}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
