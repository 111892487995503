import React from "react";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

export default function StagesAutoComplete({
  params: params,
  disabled: disabled,
  options: options,
  label: label,
  width: width
}) {
  return (
    <Autocomplete
      disableClearable
      disabled={disabled}
      options={options}
      {...params}
      isOptionEqualToValue={(option, value) => {
        if (value.stageName === "") {
          return true;
        }
        return option.stageName === value.stageName;
      }}
      getOptionLabel={(option) => {
        if (option === "") {
          return "";
        }
        return option.stageName;
      }}
      sx={{ width: width }}
      renderInput={(params) => <TextField {...params} label={label} required />}
    />
  );
}
