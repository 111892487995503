import { format, parseISO, parseJSON } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";

export const formatDate = (date) => {
  return format(parseISO(date.slice(0, 10)), "dd/MM/yyyy");
};

export const formatDateToDatabase = (date) => {
  if (typeof date === "string") return format(parseJSON(date), "yyyy-MM-dd");
  return format(date, "yyyy-MM-dd");
};

export const formatToFileDate = (date) => {
  if (typeof date === "string") return format(parseJSON(date), "MM-yyyy");
  return format(date, "MM-yyyy");
};

export const formatMachineDate = (date) => {
  return format(parseISO(date.slice(0, 10)), "yyyy-MM-dd");
};

export const formatMachineDateBR = (date) => {
  return format(parseISO(date.slice(0, 10)), "dd/MM/yyyy");
};

export const formatDateHour = (date) => {
  return format(parseJSON(date), "dd/MM/yyyy - HH:mm", {
    locale: ptBR,
  });
};

export const createDateFromString = (dateStr) => {
  return new Date(parseISO(dateStr));
};
