import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { createDateFromString, formatMachineDate } from "../../services/date";

export const useDatePicker = (initialValue) => {
  const [value, setValue] = useState(null);
  const [firstValue, setFirstValue] = useState(null);

  useEffect(() => {
    if (initialValue === null || initialValue === "") {
      setValue(null);
    } else {
      // setValue(createDateFromString(initialValue));
      setValue(dayjs(formatMachineDate(initialValue)));
      // setValue(parseISO(initialValue));
      // setValue(verifiedInitialValue);

      // setFirstValue(createDateFromString(initialValue));
      setFirstValue(dayjs(formatMachineDate(initialValue)));
      // setFirstValue(verifiedInitialValue);
    }
  }, [initialValue]);

  const onChange = (value) => {
    setValue(value);
  };

  const reset = () => {
    setValue(firstValue);
  };

  return {
    value,
    onChange,
    reset,
  };
};
