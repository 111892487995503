import React from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { FormatMoneyTypography } from "../../format/Formats";

export default function ConditionSummary({ data }) {
  const width = "22%";

  const titleSx = {
    fontFamily: "Red Hat Display",
    fontSize: "1.125rem",
    fontWeight: "500",
    color: "black",
    minWidth: width,
    maxWidth: width,
  };

  const bodySx = {
    fontFamily: "Red Hat Display",
    color: "black",
    minWidth: width,
    maxWidth: width,
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      height="100%"
      sx={{
        borderRadius: "0.625rem",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.3)",
      }}
    >
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        sx={{
          paddingX: 3.5,
          paddingY: 2,
          backgroundColor: "#EEEEEE",
          borderRadius: "0.625rem",
        }}
      >
        <Typography sx={{ fontSize: "1.25rem", fontWeight: 600 }}>
          Resumo por Situação
        </Typography>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        height="100%"
        sx={{ p: 3 }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{ mb: 2, gap: 2 }}
        >
          <Typography sx={titleSx}> Situação </Typography>
          <Typography sx={titleSx}>Quantidade</Typography>
          <Typography sx={titleSx}>Valor</Typography>
        </Box>

        {data.map((entry, index) => {
          return (
            <React.Fragment key={index}>
              <Box display="flex" justifyContent="space-between" gap={2}>
                <Typography sx={bodySx}>{entry.name}</Typography>
                <Typography sx={bodySx}>{entry.quantity}</Typography>

                <FormatMoneyTypography value={entry.value} sx={bodySx} />
              </Box>
              <Divider sx={{ mb: 1 }} />
            </React.Fragment>
          );
        })}
      </Box>
    </Box>
  );
}
