import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";

export default function CustomDialog({
  open,
  handleClickClose,
  title,
  contentText,
  requestLoading,
  handleConfirmationClick,
  confirmationClickText,
  requestLoadingText,
}) {
  return (
    <Dialog open={open} onClose={handleClickClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{contentText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClickClose}
          variant="outlined"
          disabled={requestLoading}
        >
          Cancelar
        </Button>
        <Button
          onClick={handleConfirmationClick}
          disabled={requestLoading}
          variant="contained"
          color="error"
          autoFocus
        >
          {requestLoading ? requestLoadingText : confirmationClickText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
