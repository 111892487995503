import { useState } from "react";
import { useManagementTextField } from "../../../hooks/forms/ManagementHooks";

export default function useEnginnerForm() {
  const [id, setId] = useState(-1);
  const name = useManagementTextField();
  const cpf = useManagementTextField();
  const rg = useManagementTextField();
  const issuingBody = useManagementTextField();
  const nationality = useManagementTextField();
  const phone = useManagementTextField();
  const crea = useManagementTextField();

  const loadData = (_data) => {
    if (_data) {
      setId(_data?.id ?? -1); // Use setId to update the state
      name.onCustomChange(_data.name ?? "");
      cpf.onCustomChange(_data.cpf ?? "");
      rg.onCustomChange(_data.rg ?? "");
      issuingBody.onCustomChange(_data.issuingBody ?? "");
      nationality.onCustomChange(_data.nationality ?? "");
      phone.onCustomChange(_data.phone ?? "");
      crea.onCustomChange(_data.crea ?? "");
    }
  };

  const areBasicFieldsValid = () => {
    const isNameValid = name.isFieldValid();
    return isNameValid;
  };

  const clearAllFields = () => {
    setId(-1);
    name.onCustomChange("");
    cpf.onCustomChange("");
    rg.onCustomChange("");
    issuingBody.onCustomChange("");
    nationality.onCustomChange("");
    phone.onCustomChange("");
    crea.onCustomChange("");
  };

  const values = () => {
    return {
      name: name.value,
      cpf: cpf.value,
      rg: rg.value,
      issuingBody: issuingBody.value,
      phone: phone.value,
      crea: crea.value,
      nationality: nationality.value,
    };
  };

  return {
    id,
    name,
    cpf,
    rg,
    issuingBody,
    phone,
    nacionality: nationality,
    crea,
    loadData,
    areBasicFieldsValid,
    values,
    clearAllFields,
  };
}
