import { useInfiniteQuery, useQuery } from "react-query";
import { getPayments, getSummary } from "../../services/api/finances/financesApi";

const fetchSummary = async (filters) => {
  const { data } = await getSummary(filters);
  return data;
};

const fetchPayments = async (filters) => {
  const { data } = await getPayments(filters);
  return data;
};


export const useSummary = (filters) => {
  return useQuery(["summary", filters], () => fetchSummary(filters));
};

export const usePayments = (filters, { page = 0, size = 10 }) => {
  return useQuery(
    ["payments", filters, page, size],
    () => fetchPayments({ ...filters, page, size }),
    {
      keepPreviousData: true,
    }
  );
};
