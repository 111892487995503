import { Box, Typography } from "@mui/material";
import { FormatMoneyTypography } from "../format/Formats";

export default function SummaryComponent({ data }) {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box
          sx={{
            width: 12,
            height: 12,
            mr: 1,
            borderRadius: 20,
            backgroundColor: data.color,
          }}
        />

        <Typography>{data.name}</Typography>
      </Box>
      <FormatMoneyTypography value={data.value} sx={{ fontSize: "1.125rem", fontWeight: "bold" }} />
    </Box>
  );
}
