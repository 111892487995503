import React from "react";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';

export default function DatePicker({
  disabled,
  width,
  label,
  params,
  disableFuture = false
}) {

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
      <DesktopDatePicker
        label={label}
        // format="dd/MM/yyyy"
        format="DD/MM/YYYY"
        sx={{ width: width }}
        disabled={disabled}
        value={params.value}
        onChange={params.onChange}
        disableFuture={disableFuture}
      />
    </LocalizationProvider>
  );
}
