import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import React from "react";
import { FormatMoneyTypography } from "../../format/Formats";

export default function ValuesSummary({ data }) {
  const valuesString = {
    totalValue: {
      name: "Valor Total",
      color: "#2B2E44",
    },
    approvedValue: {
      name: "Valor aprovado",
      color: "#A17B00",
    },
    comission: {
      name: "Comissão",
      color: "#039586",
    },
  };

  return (
    <Box display="flex" justifyContent="space-between"  width="100%" height="100%">
      {Object.entries(data).map((entry, index) => {
        return (
            <Paper key={index}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                borderRadius: "0.625rem",
                height:"100%",
                width:"30%",
                backgroundColor: valuesString[entry[0]].color,
                color: "#F5F5F5",
                paddingX: 3.5,
                paddingY: 2,
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.3)",
              }}
            >
              <Typography sx={{ fontSize: "1.25rem", fontWeight: 500 }}>
                {valuesString[entry[0]].name}
              </Typography>
              <FormatMoneyTypography
                value={entry[1]}
                sx={{ fontSize: "1.125rem", fontFamily: "Red Hat Display" }}
              />
            </Paper>
        );
      })}
    </Box>
  );
}
