import { api } from "../api";

export const getHomeBaseData = async () => {
  let url = "/home";
  return await api.get(url);
};

export const getProvidences = async () => {
  let url = "/home/providences";

  return await api.get(url);
};

export const getFilteredProjects = async (selectedProgress, selectedStages) => {
  let url = "/home/projects";

  return await api.get(url, {
    params: { progress: selectedProgress, stages: selectedStages },
  });
};

export const updateFilteredProject = async (
  projectId,
  historyStageId,
  historyProvidenceId,
  stageId,
  providenceId,
  deadline
) => {
  let url = `/home/projects/${projectId}`;

  return await api.put(url, {
    historyStageId: historyStageId,
    historyProvidenceId: historyProvidenceId,
    stageId: stageId,
    providenceId: providenceId,
    deadline: deadline,
  });
};
