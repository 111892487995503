import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import React, { useContext, useState } from "react";
import { useQueryClient } from "react-query";
import { ToastContainer } from "react-toastify";
import { ApiDataContext } from "../../../contexts/ApiDataContext";
import useDialog from "../../../hooks/dialog/useDialog";
import CustomAutoComplete from "../../autoCompletes/CustomAutoComplete";
import CustomLoadingButton from "../../button/CustomLoadingButton";
import CustomDialog from "../../dialog/CustomDialog";
import useAddressForm from "../../forms/address/controller";
import useEnginnerForm from "./controller";
import EngineerForm from "./EngineerForm";
import { deleteEngineer, updateEngineer } from "../../../services/api/api";
import { useLoadingToast } from "../../../hooks/toast/toasts";

export default function EditEngineer() {
  const controller = useEnginnerForm();
  const addressController = useAddressForm();
  const { apiData } = useContext(ApiDataContext);
  const [engineerValue, setEngineerValue] = useState("");
  const [requestLoading, setRequestLoading] = useState(false);
  const [deleteRequestLoading, setDeleteRequestLoading] = useState(false);
  const { initializeLoadingToast, updateLoadingToast } = useLoadingToast();

  const handleEngineerChange = async (event, newValue) => {
    setEngineerValue(null);
    setEngineerValue(newValue);

    controller.loadData(newValue);
    addressController.loadData(newValue);
  };

  const engineer = {
    value: engineerValue,
    onChange: handleEngineerChange,
  };

  const queryClient = useQueryClient();

  const { open, handleClickOpen, handleClickClose } = useDialog();

  const handleUpdateClick = async () => {
    let updateToastId;
    try {
      if (!controller.areBasicFieldsValid()) {
        return;
      }
      setRequestLoading(true);
      updateToastId = initializeLoadingToast({
        text: `Atualizando Engenheiro...`,
      });

      const engineerData = {
        ...controller.values(),
        ...addressController.values(),
      };
      const { status } = await updateEngineer(controller.id, engineerData);

      if (status === 200) {
        queryClient.invalidateQueries({ queryKey: ["baseDataQuery"] });

        updateLoadingToast({
          id: updateToastId,
          text: `Engenheiro atualizado com sucesso`,
          type: "success",
        });
      }
    } catch (error) {
      updateLoadingToast({
        id: updateToastId,
        text: error?.response?.data?.message ?? `Erro ao atualizar engenheiro, tente novamente`,
        type: "error",
      });
    } finally {
      setRequestLoading(false);
    }
  };

  const handleDeleteClick = async () => {
    let deleteToastId;
    try {
      setRequestLoading(true);

      deleteToastId = initializeLoadingToast({
        text: `Excluindo Engenheiro...`,
      });

      // Chama a função de deleção
      const response = await deleteEngineer(controller.id);
      const { status, data } = response;

      if (status === 200) {
        queryClient.invalidateQueries({ queryKey: ["baseDataQuery"] });
        controller.clearAllFields();
        addressController.clearAllFields();
        updateLoadingToast({
          id: deleteToastId,
          text: `Engenheiro Excluído com sucesso`,
          type: "success",
        });
        handleClickClose();
        setEngineerValue(null);
      }
    } catch (error) {
      updateLoadingToast({
        id: deleteToastId,
        text: error.response?.data?.message ?? `Erro ao Excluir Engenheiro, tente novamente`,
        type: "error",
      });
    } finally {
      setRequestLoading(false);
    }
  };

  return (
    <Box
      sx={{
        mt: 2,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <ToastContainer />

      <CustomDialog
        open={open}
        handleClickClose={handleClickClose}
        title="Excluir Engenheiro"
        contentText="As informações do engenheiro serão excluídas e não poderão ser recuperadas."
        requestLoading={deleteRequestLoading}
        handleConfirmationClick={handleDeleteClick}
        confirmationClickText="Excluir"
        requestLoadingText="Excluindo..."
      />

      <CustomAutoComplete
        params={engineer}
        options={apiData.engineers}
        disabled={false}
        label="Selecione o Engenheiro"
        width="40%"
      />

      {engineerValue ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            p: 2,
            justifyContent: "space-between",
            borderRadius: 3,
          }}
        >
          <EngineerForm controller={controller} addressController={addressController} />

          <Box
            display="flex"
            width="45%"
            sx={{ justifyContent: "space-between", alignSelf: "flex-end", mt: 2 }}
          >
            <Button
              variant="outlined"
              color="error"
              sx={{
                width: "30%",
              }}
              disabled={requestLoading || deleteRequestLoading}
              onClick={handleClickOpen}
            >
              Excluir
            </Button>

            <CustomLoadingButton
              buttonText="Atualizar"
              buttonLoadingText="Atualizando..."
              sx={{ width: "35%" }}
              disabled={requestLoading || deleteRequestLoading}
              handleButtonClick={handleUpdateClick}
              requestLoading={requestLoading}
            />
          </Box>
        </Box>
      ) : null}
    </Box>
  );
}
