import { createTheme } from "@mui/material/styles";

export const lightTheme = createTheme({
  palette: {
    primary: {
      main: "#08044c", // Dark Blue (AppBar)
    },
    secondary: {
      main: "#CA9900", // Medium Yellow (Buttons)
      hover: "#c67200", // Dark Yellow (Buttons)
    },
    info: {
      main: "#4890EF", // Light Blue (Secondary Buttons)
      hover: "#1976D2", // Medium Blue (Secondary Buttons)
    },
    error: {
      main: "#d32f2f", // Dark Red (Cancel/Delete Buttons)
    },
    background: {
      default: "#ffffff", // White (Background)
      secondary: "#f5f5f5", // Light Gray (Background)
    },
    text: {
      primary: "#131313", // Dark Gray or Black (Text)
    },
    icon: {
      primary: "#757575",
      secondary: "e8e4e4",
    },
  },
  typography: {
    fontSize: 14, // Base font size in pixels
    htmlFontSize: 18,
  },
});

export const darkTheme = createTheme({
  palette: {
    primary: {
      main: "#08044c", // Dark Blue (AppBar)
    },
    secondary: {
      main: "#CA9900", // Medium Yellow (Buttons)
      hover: "#c67200", // Dark Yellow (Buttons)
    },
    info: {
      main: "#1976d2", // Medium Blue (Buttons)
    },
    error: {
      main: "#d32f2f", // Dark Red (Cancel/Delete Buttons)
    },
    background: {
      default: "#333333", // Dark Background
      secondary: "#515151", // Light Gray (Background)
    },
    text: {
      primary: "#ffffff", // White Text
    },
    icon: {
      primary: "#f0f0f0",
      secondary: "#606060",
    },
  },
  typography: {
    fontSize: 14, // Base font size in pixels
    htmlFontSize: 18,
  },
});
