import { useQuery } from "react-query";
import { getAllProvidences, getUserProvidences } from "../../services/api/api";

const userProvidencesApi = {
  fetchUserProvidences: async (userProvidences) => {
    if (userProvidences == null) {
      const { data } = await getAllProvidences();
      return data;
    }

    const { data } = await getUserProvidences(userProvidences);
    return data;
  },
};

export const useFetchUserProvidences = (userProvidences, searchValue) => {
  const { isLoading: userProvidencesLoading, data: userProvidencesData } =
    useQuery(
      ["userProvidences", [userProvidences, searchValue]],
      () => userProvidencesApi.fetchUserProvidences(userProvidences),
      {
        staleTime: 5000,
        retry: 5,
        select: (activities) => {
          if (searchValue.length != 0) {
            return activities.filter(
              (activity) => activity.id === searchValue.id
            );
          }
          return activities;
        },
      }
    );

  return {
    userProvidencesLoading,
    userProvidencesData,
  };
};
