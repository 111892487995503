import Box from "@mui/material/Box";
import React from "react";

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { ReactSearchAutocomplete } from "react-search-autocomplete";

export default function SearchBar({
  data,
  isMyActivitiesMarked,
  setIsMyActivitiesMarked,
  searchValue,
  setSearchValue,
}) {

  const handleOnSearch = (string, results) => {
    // setSearchValue(results);
  };

  const handleOnHover = (result) => {
  };

  const handleOnSelect = (item) => {
    // window.location = `/activities?id=${item.id}`;
    setSearchValue(item);
  };

  const handleOnFocus = () => {
  };

  const handleOnClear = () => {
    setSearchValue([]);
  };

  const handleSwitchClick = () => {
    setIsMyActivitiesMarked(!isMyActivitiesMarked);
  };

  const formatResult = (item) => {
    return (
      <div className="result-wrapper">
        <span className="result-span">
          {item.projectNumber} - {item.name}
        </span>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Box display="flex" width="100%">
        <Box width="70%" sx={{ p: 1 }}>
          <ReactSearchAutocomplete
            items={data || []}
            maxResults={15}
            onSearch={handleOnSearch}
            onHover={handleOnHover}
            onSelect={handleOnSelect}
            onFocus={handleOnFocus}
            placeholder="Busque por nome ou numero"
            onClear={handleOnClear}
            showNoResultsText="Nenhum resultado"
            fuseOptions={{ keys: ["projectNumber", "name"] }}
            resultStringKeyName="name"
            formatResult={formatResult}
            styling={{
              zIndex: 4,
            }}
            value={searchValue}
            className="search" // To display it on top of the search box below
          />
        </Box>

        <Box width="30%" sx={{ p: 1 }}>
          <FormControlLabel
            control={
              <Switch
                onClick={handleSwitchClick}
                checked={isMyActivitiesMarked}
              />
            }
            label="Minhas Atividades"
          />
        </Box>
      </Box>
    </React.Fragment>
  );
}
