import { useEffect, useState } from "react";

function useStepper(label, icon, initialState, initialDate) {
  const [isActive, setIsActive] = useState(initialState);
  const [date, setDate] = useState(initialDate);

  useEffect(() => {
    setDate(initialDate);
    setIsActive(initialState);
  }, [initialState, initialDate]);

  // const onToggle = () => {
  //   setIsActive(!isActive);

  //   if (!isActive == false) {
  //     setDate(null);
  //   } else {
  //     setDate(new Date().toISOString());
  //   }
  // };

  // const onCustomToggle = (value) => {
  //   setIsActive(value);

  //   if (value == false) {
  //     setDate(null);
  //   } else {
  //     setDate(new Date().toISOString());
  //   }
  // };

  return {
    label,
    icon,
    isActive,
    date,
    // , onToggle, onCustomToggle
  };
}

export default useStepper;
