import { api } from "../api";
export const createPdc = async (
    powerDistributionCompanyName,
    stateId,
    hasProcessing,
    hasDifferentArtPrice,
    artPrice
  ) => {
    let url = `/pdc`;
  
    return await api.post(url, {
      powerDistributionCompanyName,
      stateId,
      hasProcessing,
      hasDifferentArtPrice,
      artPrice,
    });
  };
  
  export const updatePdc = async (
    powerDistributionCompanyId,
    powerDistributionCompanyName,
    stateId,
    hasProcessing,
    hasDifferentArtPrice,
    artPrice
  ) => {
    let url = `/pdc/${powerDistributionCompanyId}`;
  
    return await api.put(url, {
      powerDistributionCompanyName,
      stateId,
      hasProcessing,
      hasDifferentArtPrice,
      artPrice,
    });
  };
  
  export const deletePdc = async (
    powerDistributionCompanyId
  ) => {
    let url = `/pdc/${powerDistributionCompanyId}`;
  
    return await api.delete(url);
  };