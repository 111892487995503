import { Container, Divider, Grid, Paper, Typography } from "@mui/material";
import { subDays } from "date-fns";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AppBarMenu from "../components/AppBarMenu";
import Filters from "../components/finances/Filters";
import Payments from "../components/finances/Payments";
import Summary from "../components/finances/Summary";

const validateStatus = (status) => {
  if (!status) return "";
  const VALID_STATUSES = ["PAID", "MARKED_AS_PAID", "WAITING", "OVERDUE", "EXPIRED"];
  if (VALID_STATUSES.includes(status)) {
    return status;
  }
  return "";
};

const validateClientId = (clientId) => {
  if (!clientId) return "";
  const id = parseInt(clientId, 10);
  if (Number.isInteger(id) && id > 0) {
    return id;
  }
  return "";
};

const validateSearchParams = (searchParams) => {
  const status = validateStatus(searchParams.get("status"));
  const clientId = validateClientId(searchParams.get("clientId"));
  const startDate = searchParams.get("startDate") || subDays(new Date(), 30).toISOString();
  const endDate = searchParams.get("endDate") || new Date().toISOString().split("T")[0];
  return {
    status,
    clientId,
    startDate,
    endDate,
  };
};

export default function FinancesPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const validSearchParams = validateSearchParams(searchParams);
  const [filters, setFilters] = useState(validSearchParams);

  useEffect(() => {
    setSearchParams(filters);
  }, [filters, setSearchParams]);

  const applyFilters = (newFilters) => {
    setFilters(newFilters);
  };

  const clearFilter = (filterKey) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterKey]: "",
    }));
  };

  return (
    <AppBarMenu>
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <ToastContainer />

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper
              sx={{
                p: 3,
                display: "flex",
                flexDirection: "column",
                minHeight: 600,
              }}
            >
              <Typography
                sx={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  mb: 2,
                  alignSelf: "flex-start",
                }}
              >
                Gestão de Finanças
              </Typography>
              <Divider sx={{ mb: 2 }} />

              <Filters filters={filters} applyFilters={applyFilters} clearFilter={clearFilter} />
              <Summary filters={filters} />
              <Payments filters={filters} />

              {/* <Box display="flex" width="70%" sx={{ justifyContent: "space-between", mt: 4 }}>
                {apiData
                  ? apiData.map((data, index) => {
                      return (
                        <React.Fragment>
                          <SummaryComponent data={data} />
                          {index !== apiData.length - 1 ? (
                            <Divider orientation="vertical" variant="middle" flexItem />
                          ) : null}
                        </React.Fragment>
                      );
                    })
                  : null}
              </Box> */}
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </AppBarMenu>
  );
}
