import { useState } from "react";
import validateEmail from "../../services/regExp/validateEmail";
import { useEffect } from "react";

export const useLoginTextFields = (type, firstValue) => {
  const [value, setValue] = useState("");
  const [error, setError] = useState(false);
  const [errorLabel, setErrorLabel] = useState("");
  const [initialValue, setInitialValue] = useState("");

  let onChange;

  useEffect(() => {
    if(firstValue || firstValue == ""){
      setValue(firstValue);
      setInitialValue(firstValue);
    }
  }, [firstValue])

  if (type === "email") {
    onChange = (event) => {
      if (!validateEmail(event.target.value)) {
        if (event.target.value) {
          setError(true);
          setErrorLabel("E-mail Inválido");

        } else {
          setError(false);
        }
      } else {
        setError(false);
      }
      setValue(event.target.value);
    };
  } else {
    onChange = (event) => {
      setValue(event.target.value);
    };
  }

  const isFieldValid = () => {
    if (value.trim() === "") {
      setError(true);
      setErrorLabel("O campo é obrigatório");
      return false;
    }

    setError(false);
    setErrorLabel("");
    return true;
  };

  const reset = () => {
    setValue(initialValue);
  };

  return {
    value,
    error,
    onChange,
    reset,
    isFieldValid,
    errorLabel
  };
};

export const usePassword = () => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  return {
    showPassword,
    handleClickShowPassword,
  };
};
