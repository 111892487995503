import React from "react";

import Box from "@mui/material/Box";

import { Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import NotFoundError from "../media/illustrations/NotFoundError.jpg";

export default function ErrorPage() {
  return (
    <Box
      display="flex"
      width="100vw"
      height="100vh"
      sx={{
        backgroundColor: "#1D1F2D",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        display="flex"
        height="70%"
        width="80%"
        maxWidth="500px"
        sx={{
          borderRadius: 10,
          backgroundColor: "#ffffff",
          flexDirection: "column",
          //   justifyContent: "space-around",
          alignItems: "center",
          p: 5,
        }}
      >
        <img
          width="80%"
          src={NotFoundError}
          style={{marginBottom: 20}}
          alt="A logo that contains a blue chef hat and under it a blue mustache. On the left, on the same size as the image and in black it is written 'Recipe mate, '"
        />
        <Typography sx={{color: "#727482"}}>
          {"Desculpe, esta página não pode ser encontrada. "}
          <NavLink
            style={{ color: "black", fontStyle: "italic"}}
            to="/home"
          >
             Voltar para o Início
          </NavLink>
        </Typography>
      </Box>
    </Box>
  );
}
