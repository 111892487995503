import React from "react";
import { Box, Grid, useTheme, useMediaQuery } from "@mui/material";
import CustomLoadingButton from "../../button/CustomLoadingButton";
import FormatedCustomTextField from "../../textFields/FormatedCustomTextField";
import CustomTextField from "../../textFields/CustomTextField";
import { FormatZipCode } from "../../format/Formats";

const AddressForm = ({ controller, areAddressFieldsDisabled = false, required = true }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box>
      <form
        onSubmit={controller.handleZipCodeClick}
        style={{
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <FormatedCustomTextField
          params={controller.zipCode}
          disabled={areAddressFieldsDisabled}
          label="Cep"
          inputcomponent={FormatZipCode}
          required={required}
          fullWidth={!isSmallScreen}
          width={isSmallScreen ? "100%" : "48%"}
        />

        <CustomLoadingButton
          buttonText="Buscar"
          buttonLoadingText="Buscando..."
          sx={{
            alignSelf: "flex-end",
            width: isSmallScreen ? "100%" : "30%",
            mb: theme.spacing(2),
          }}
          disabled={controller.zipCodeRequestLoading || areAddressFieldsDisabled}
          requestLoading={controller.zipCodeRequestLoading}
          type="submit"
        />
      </form>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <CustomTextField
            params={controller.street}
            disabled={areAddressFieldsDisabled}
            label="Logradouro"
            required={required}
            maxLength={100}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomTextField
            params={controller.number}
            disabled={areAddressFieldsDisabled}
            label="Número"
            required={required}
            maxLength={100}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomTextField
            params={controller.addressDetails}
            disabled={areAddressFieldsDisabled}
            label="Complemento"
            maxLength={50}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <CustomTextField
            params={controller.district}
            disabled={areAddressFieldsDisabled}
            label="Bairro"
            maxLength={50}
            required={required}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomTextField
            params={controller.city}
            disabled={areAddressFieldsDisabled}
            label="Cidade"
            required={required}
            maxLength={50}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomTextField
            params={controller.state}
            disabled={areAddressFieldsDisabled}
            label="Estado"
            required={required}
            maxLength={2}
            fullWidth
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddressForm;
