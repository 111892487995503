import { useEffect, useState } from "react";
import { useManagementTextField } from "../../../hooks/forms/ManagementHooks";
import { useLoadingToast } from "../../../hooks/toast/toasts";
import { getAddressFromZipCode } from "../../../services/api/api";

export default function useAddressForm(data) {
  const { onCustomChange: onCustomStreetChange, ...street } = useManagementTextField(
    data?.street ?? ""
  );
  const { onCustomChange: onCustomNumberChange, ...number } = useManagementTextField(
    data?.number ?? ""
  );
  const { onCustomChange: onCustomDistrictChange, ...district } = useManagementTextField(
    data?.district ?? ""
  );
  const { onCustomChange: onCustomAddressDetailsChange, ...addressDetails } =
    useManagementTextField(data?.addressDetails ?? "");
  const { onCustomChange: onCustomCityChange, ...city } = useManagementTextField(data?.city ?? "");
  const { onCustomChange: onCustomStateChange, ...state } = useManagementTextField(
    data?.state ?? ""
  );
  const { onCustomChange: onCustomZipCodeChange, ...zipCode } = useManagementTextField(
    data?.zipCode ?? ""
  );

  const _setAddressFields = (data) => {
    if (data) {
      onCustomStreetChange(data.street ?? "");
      onCustomNumberChange(data.number ?? "");
      onCustomDistrictChange(data.district ?? "");
      onCustomAddressDetailsChange(data.addressDetails ?? "");
      onCustomCityChange(data.city ?? "");
      onCustomStateChange(data.state ?? "");
    }
  };

  const loadData = (data) => {
    if (data) {
      _setAddressFields(data);
      onCustomZipCodeChange(data.zipCode ?? "");
    }
  };

  const values = () => {
    return {
      street: street.value,
      number: number.value,
      district: district.value,
      addressDetails: addressDetails.value,
      city: city.value,
      state: state.value,
      zipCode: zipCode.value,
    };
  };

  useEffect(() => {
    loadData(data);
  }, [data]);

  const { initializeLoadingToast, updateLoadingToast } = useLoadingToast();
  const [zipCodeRequestLoading, setZipCodeRequestLoading] = useState(false);
  const [zipCodeLoaded, setZipCodeLoaded] = useState(false);

  const handleZipCodeClick = async (event) => {
    event.preventDefault();
    let toastId;
    try {
      clear();
      setZipCodeLoaded(false);
      setZipCodeRequestLoading(true);

      toastId = initializeLoadingToast({
        text: `Buscando Cep ${zipCode.value}...`,
      });

      const { status, data } = await getAddressFromZipCode(zipCode.value);

      if (status === 200) {
        _setAddressFields(data);

        updateLoadingToast({
          id: toastId,
          text: `Cep ${zipCode.value} encontrado`,
          type: "success",
        });
      } else {
        updateLoadingToast({
          id: toastId,
          text: `Cep ${zipCode.value} não encontrado`,
          type: "error",
        });
      }
    } catch (error) {
      updateLoadingToast({
        id: toastId,
        text: `Cep ${zipCode.value} não encontrado`,
        type: "error",
      });
    } finally {
      setZipCodeRequestLoading(false);
      setZipCodeLoaded(true);
    }
  };

  const clear = () => {
    onCustomStreetChange("");
    onCustomNumberChange("");
    onCustomDistrictChange("");
    onCustomAddressDetailsChange("");
    onCustomCityChange("");
    onCustomStateChange("");
  };

  const clearAllFields = () => {
    clear();
    onCustomZipCodeChange("");
  };

  const validate = () => {
    street.isFieldValid();
    number.isFieldValid();
    district.isFieldValid();
    addressDetails.isFieldValid();
    city.isFieldValid();
    state.isFieldValid();
    zipCode.isFieldValid();
  };

  const removeErrors = (field) => {
    street.removeFieldError();
    number.removeFieldError();
    district.removeFieldError();
    addressDetails.removeFieldError();
    city.removeFieldError();
    state.removeFieldError();
    zipCode.removeFieldError();
  };

  return {
    zipCodeRequestLoading,
    zipCodeLoaded,
    street,
    number,
    district,
    zipCode,
    addressDetails,
    city,
    state,
    loadData,
    values,
    handleZipCodeClick,
    validate,
    removeErrors,
    clearAllFields,
  };
}
