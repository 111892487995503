import { api } from "../api";

export const getAllowedEmails = async () => {
  let url = `/admin/emails`;
  return await api.get(url);
};

export const deleteAllowedEmail = async (id) => {
  let url = `/admin/emails/${id}`;
  return await api.delete(url);
};

export const createAllowedEmail = async (email) => {
  let url = `/admin/emails`;
  return await api.post(url, {
    email: email,
  });
};

export const updateAllowedEmail = async (id, email) => {
  let url = `/admin/emails/${id}`;
  return await api.put(url, {
    email: email,
  });
};
