import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import React from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultipleAutocomplete({
  width,
  required,
  disabled = false,
  valueKey = "name",
  label,
  params,
  values,
  isChecked,
  margin = 2
}) {
  return (
    <Box width={width} sx={{ mb: margin }}>
      <FormControl sx={{ width: width }}>
        <InputLabel disabled={disabled} required={required}>{label}</InputLabel>
        <Select
          multiple
          disabled={disabled}
          {...params}
          input={<OutlinedInput label={label} />}
          renderValue={(selected) => {
            const initialValue = "";
            const str = selected.reduce(
              (accumulator, currentValue) =>
                accumulator + currentValue[valueKey] + ", ",
              initialValue
            );
            return str;
          }}
          MenuProps={MenuProps}
        >
          {values.map((value) => (
            <MenuItem key={value.id} value={value}>
              <Checkbox checked={isChecked(value)} />
              <ListItemText primary={value[valueKey]} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Typography color="error">
        {params.error ? params.errorLabel : ""}
      </Typography>
    </Box>
  );
}
