import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { formatDate } from "../../services/date";
import ActivityModal from "./ActivityModal";

export default function ActivitiesList({ data }) {
  const [isActivityBeingDone, setIsActivityBeingDone] = useState(false);
  const userName = JSON.parse(localStorage.getItem("user")).userName;
  const fontFamily = "Red Hat Display";
  const titleSx = {
    fontWeight: 600,
    fontSize: "1.25rem",
    fontFamily: fontFamily,
  };
  const subtitleSx = {
    fontWeight: 500,
    fontSize: "1.125rem",
    fontFamily: fontFamily,
  };
  const subtitle2Sx = {
    fontWeight: 500,
    fontSize: "1.1rem",
    fontFamily: fontFamily,
    width: "30%",
  };
  const bodySx = {
    fontFamily: fontFamily,
    maxWidth: "70%",
  };

  return (
    <React.Fragment>
      {data.map((project) => {
        let parsedDeadline;

        if (project.dueDate != null) {
          parsedDeadline = formatDate(project.dueDate);
        }

        return (
          <Accordion
            key={project.id}
            sx={{ mb: 2, boxShadow: 3, borderRadius: 2 }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box display="flex" width="100%">
                <Typography width="20%" sx={titleSx}>
                  {project.projectNumber}
                  <br />
                  <Typography width="100%" sx={subtitleSx}>
                    {project.pdcName}
                  </Typography>
                </Typography>
                <Typography width="40%" sx={titleSx}>
                  {project.providenceName}
                </Typography>
                {project.beingRealized === 1 ? (
                  <Typography width="40%" color={"green"}>
                    Atividade sendo realizada por{" "}
                    {project.performUser === userName
                      ? "você"
                    : project.performUser}
                  </Typography>
                ) : null}
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box display="flex" width="100%">
                <Box
                  width="100%"
                  display="flex"
                  gap={1}
                  sx={{ flexDirection: "column" }}
                >
                  <Box width="100%" display="flex">
                    <Typography sx={subtitle2Sx}>Nome</Typography>
                    <Typography sx={bodySx}>{project.name}</Typography>
                  </Box>
                  <Divider orientation="horizontal" />

                  <Box width="100%" display="flex">
                    <Typography sx={subtitle2Sx}>Integrador</Typography>
                    <Typography sx={bodySx}>{project.clientName}</Typography>
                  </Box>

                  <Divider orientation="horizontal" />

                  <Box width="100%" display="flex">
                    <Typography sx={subtitle2Sx}>Etapa</Typography>
                    <Typography sx={bodySx}>
                      {project.stageName}
                    </Typography>
                  </Box>

                  <Divider orientation="horizontal" />

                  <Box width="100%" display="flex">
                    <Typography sx={subtitle2Sx}>Prazo Máximo</Typography>
                    <Typography sx={bodySx}>{parsedDeadline}</Typography>
                  </Box>
                  <Divider orientation="horizontal" />

                  <Box width="100%" display="flex">
                    <Typography sx={subtitle2Sx}>Observação</Typography>
                    <Typography sx={bodySx}>
                      {project.note}
                    </Typography>
                  </Box>

                  <Divider orientation="horizontal" />
                </Box>
                <Box
                  height={40}
                  width="30%"
                  display="flex"
                  sx={{ justifyContent: "center", alignSelf: "flex-end" }}
                >
                  <ActivityModal activityData={project} />
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </React.Fragment>
  );
}
