import { Navigate, Outlet, useLocation } from "react-router-dom";
import { ApiDataProvider } from "../contexts/ApiDataContext";
import useAuth from "../hooks/useAuth";

export default function RequireAuth({ allowedRoles }) {
  const { authenticated, loading, auth } = useAuth();
  const location = useLocation();
  
  return loading ? null : auth?.roles.find((role) =>
      allowedRoles?.includes(role)
    ) ? (
    <ApiDataProvider>
      <Outlet />
    </ApiDataProvider>
  ) : authenticated ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/signIn" state={{ from: location }} replace />
  );
}
