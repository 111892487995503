import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import { useManagementTextField } from "../../../hooks/forms/ManagementHooks";
import { useLoadingToast } from "../../../hooks/toast/toasts";
import { getProject } from "../../../services/api/api";
import useAddressForm from "../../forms/address/controller";
import CustomTextField from "../../textFields/CustomTextField";
import CommonEdit from "./CommonEdit";
import SimpleEdit from "./SimpleEdit";
import useAttorneyForm from "../../forms/attorney/controller";

export default function EditProject() {
  const { initializeLoadingToast, updateLoadingToast } = useLoadingToast();
  const { reset: resetProjectNumber, ...projectNumber } = useManagementTextField("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [projectData, setProjectData] = useState();
  const [disableAllFields, setDisableAllFields] = useState(false);
  const [clientValue, setClientValue] = useState("");
  const addressController = useAddressForm();
  let attorneyController = useAttorneyForm();


  const handleClientChange = async (event, newValue) => {
    setClientValue(newValue);
  };

  const clearAllFields = () => {
    resetProjectNumber();
    setProjectData(null);
  };

  const handleSearchProject = async (event) => {
    event.preventDefault();
    let searchToastId;
    try {
      clearAllFields();
      setSearchLoading(true);
      searchToastId = initializeLoadingToast({
        text: `Buscando Projeto...`,
      });

      const { data } = await getProject(projectNumber.value);

      setClientValue(data.clients);

      setProjectData(data);
      addressController.loadData({
        street: data.street,
        number: data.number,
        district: data.district,
        addressDetails: data.addressDetails,
        city: data.city,
        state: data.state,
        zipCode: data.zipCode,
      });
      attorneyController.loadData(data)

      updateLoadingToast({
        id: searchToastId,
        text: `Projeto encontrado com sucesso`,
        type: "success",
      });
    } catch (error) {
      updateLoadingToast({
        id: searchToastId,
        text: error.response.data.message ?? `Erro ao buscar Projeto, tente novamente`,
        type: "error",
      });
      clearAllFields();
    } finally {
      setSearchLoading(false);
      searchToastId = null;
    }
  };

  const client = {
    value: clientValue,
    onChange: handleClientChange,
  };

  return (
    <Box sx={{ mt: 3 }}>
      <ToastContainer />

      <form
        onSubmit={handleSearchProject}
        style={{ display: "flex", width: "45%", justifyContent: "space-between" }}
      >
        <CustomTextField
          params={projectNumber}
          disabled={false}
          label="Insira o numero do Projeto"
          errorLabel="Numero Incorreto"
          maxLength={7}
          width="65%"
        />
        <Button
          variant="contained"
          sx={{ width: "25%", height: "100%" }}
          disabled={searchLoading}
          type="submit"
        >
          {searchLoading ? "Buscando..." : "Buscar"}
        </Button>
      </form>

      {projectData ? (
        <React.Fragment>
          <SimpleEdit
            projectData={projectData}
            clearAllFields={clearAllFields}
            client={client}
            isDisabled={disableAllFields}
            setDisabled={setDisableAllFields}
            initializeLoadingToast={initializeLoadingToast}
            updateLoadingToast={updateLoadingToast}
            addressController={addressController}
            attorneyController={attorneyController}
          />
          <CommonEdit
            projectData={projectData}
            clearAllFields={clearAllFields}
            isDisabled={disableAllFields}
            setDisabled={setDisableAllFields}
            initializeLoadingToast={initializeLoadingToast}
            updateLoadingToast={updateLoadingToast}
          />
        </React.Fragment>
      ) : null}
    </Box>
  );
}
