import { api } from "../api";

export const getEngineer = async () => {
  let url = "/engineers";
  return await api.get(url);
};

export const getEngineerById = async (id) => {
  let url = `/engineers/${id}`;
  return await api.get(url);
};

export const deleteEngineer = async (id) => {
  let url = `/engineers/${id}`;
  return await api.delete(url);
};

export const updateEngineer = async (id, engineerData) => {
  let url = `/engineers/${id}`;
  return await api.put(url, engineerData);
};

export const createEngineer = async (engineerData) => {
  let url = `/engineers`;
  return await api.post(url, engineerData);
};
