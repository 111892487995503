import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { startOfMonth } from "date-fns";
import React, { useEffect, useState } from "react";
import { getComissionData, getComissionSummary } from "../../services/api/api";
import {
  formatDateToDatabase,
  formatToFileDate
} from "../../services/date";
import { generateComissionPDF } from "../../services/pdf/pdf";
import CustomLoadingButton from "../button/CustomLoadingButton";
import Chart from "./visualize/Chart";
import ConditionSummary from "./visualize/ConditionSummary";
import Costs from "./visualize/Costs";
import EmployeesSummary from "./visualize/EmployeesSummary";
import ValuesSummary from "./visualize/ValuesSummary";

export default function VisualizeComissions() {
  const [date, setDate] = useState(startOfMonth(new Date()));
  const [comissionData, setComissionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isPDFloading, setPDFLoading] = useState(false);

  const handleDateChange = (value) => {
    setDate(value);
    fetchData(value);
  };

  useEffect(() => {
    const today = startOfMonth(new Date());
    setDate(today);
    fetchData(today);
  }, []);

  const fetchData = async (date) => {
    setComissionData(null);
    setLoading(true);
    try {
      const formatedDeadline = date !== "" ? formatDateToDatabase(date) : "";
      const { data: comissionData } = await getComissionData(formatedDeadline);
      setComissionData(comissionData);
      // setAlertSeverity("success");
      // setAlertTitle("Sucesso!");
      // setAlertMessage("Projeto encontrado");
    } catch (error) {
      // setAlertSeverity("error");
      // setAlertTitle(`Erro - ${error.response.statusText}`);
      // setAlertMessage("Projeto não encontrado");
      setComissionData(null);
    } finally {
      setLoading(false);
      // alert.handleOpen();
    }
  };

  const handlePDFClick = async () => {
    try {
      setPDFLoading(true);

      const formatedDeadline = date !== "" ? formatDateToDatabase(date) : "";
      const fileDate = date !== "" ? formatToFileDate(date) : "";

      const { data: pdfData } = await getComissionSummary(formatedDeadline);

      generateComissionPDF(pdfData, fileDate);
    } catch (error) {
      console.error(error);
    } finally {
      setPDFLoading(false);
    }
  };

  const buttonStyle = {
    width: "15%",
    mb: 2,
    mr: 4,
    height: "70%",
    alignSelf: "flex-end",
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <CustomLoadingButton
          startIcon={<PictureAsPdfIcon />}
          buttonText="Gerar PDF"
          buttonLoadingText="Gerando PDF..."
          sx={buttonStyle}
          disabled={isPDFloading || date === ""}
          handleButtonClick={handlePDFClick}
          requestLoading={isPDFloading}
        />

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            label={"Selecione ano e mês"}
            disableFuture={true}
            views={["month", "year"]}
            value={date}
            onChange={handleDateChange}
            sx={{
              width: "35%",
              alignSelf: "flex-end",
              paddingBottom: 2,
              maxWidth: 200,
            }}
          />
        </LocalizationProvider>
      </Box>

      {loading ? (
        <CircularProgress
          size={56}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
        />
      ) : (
        <React.Fragment>
          {comissionData == null || comissionData.length == 0 ? (
            <Box
              sx={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <Typography sx={{ fontSize: "1.3rem", fontWeight: 700, color: "red" }}>
                Não existem projetos cadastrados para o mês enviado
              </Typography>
            </Box>
          ) : (
            <Box
              sx={{
                height: "750px",
                display: "grid",
                gap: "20px",
                gridTemplateColumns: "repeat(10, 1fr)",
                gridTemplateRows: "15% 15% 15% 15% 15% 15%",
              }}
            >
              <Box
                sx={{
                  gridColumnStart: 1,
                  gridColumnEnd: 8,
                  gridRowStart: 1,
                  gridRowEnd: 2,
                }}
              >
                <ValuesSummary data={comissionData.values} />
              </Box>

              <Box
                sx={{
                  gridColumnStart: 8,
                  gridColumnEnd: 11,
                  gridRowStart: 1,
                  gridRowEnd: 5,
                }}
              >
                <Costs data={comissionData.general} />
              </Box>

              <Box
                sx={{
                  gridColumnStart: 1,
                  gridColumnEnd: 8,
                  gridRowStart: 2,
                  gridRowEnd: 5,
                }}
              >
                <EmployeesSummary data={comissionData.employees} />
              </Box>

              <Box
                sx={{
                  gridColumnStart: 1,
                  gridColumnEnd: 6,
                  gridRowStart: 5,
                  gridRowEnd: 8,
                }}
              >
                <ConditionSummary data={comissionData.conditionSummary} />
              </Box>

              <Box
                sx={{
                  gridColumnStart: 6,
                  gridColumnEnd: 11,
                  gridRowStart: 5,
                  gridRowEnd: 8,
                }}
              >
                <Chart data={comissionData.conditionSummary} />
              </Box>
            </Box>
          )}
        </React.Fragment>
      )}
    </Box>
  );
}
