import React, { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { api, login as serviceLogin, signup as serviceSignUp } from "../services/api/api";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [auth, setAuth] = useState(null);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    const recoveredUser = localStorage.getItem("user");
    const token = localStorage.getItem("token");

    if (recoveredUser) {
      setAuth(JSON.parse(recoveredUser));
      api.defaults.headers.common.Authorization = `Bearer ${token}`;
    }

    setLoading(false);
  }, []);


  api.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      
      if (error.response.status === 401) {
        logout();
      }

      return Promise.reject(error);
    }
  );

  
  const login = async (email, password) => {
    try {
      const { data } = await serviceLogin(email, password);

      const loggedUser = data.user;
      const token = data.token;


      localStorage.setItem("user", JSON.stringify(loggedUser));
      localStorage.setItem("token", token);

      api.defaults.headers.common.Authorization = `Bearer ${token}`;


      setAuth(loggedUser);

      navigate("/home");
    } catch (error) {
      throw error;
    }
  };

  const signUp = async (userName, email, password) => {
    const response = await serviceSignUp(userName, email, password);
    navigate("/signIn");
  };

  const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    api.defaults.headers.common.Authorization = null;
    setAuth(null);
    navigate("/signIn");
  };

  return (
    <AuthContext.Provider
      value={{ authenticated: !!auth, auth, setAuth, loading, login, logout, signUp }}
    >
      {/* The !!user is the same as Boolean(user) - a boolean casting */}
      {children}
    </AuthContext.Provider>
  );
};
