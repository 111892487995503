import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import React, { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import EmailTextField from "../components/textFields/EmailTextField";
import PasswordTextField from "../components/textFields/PasswordTextField";
import { AuthContext } from "../contexts/AuthContext";
import { useLoginTextFields, usePassword } from "../hooks/forms/loginHooks";
import { useLoadingToast } from "../hooks/toast/toasts";
import CompleteLogo from "../media/logo/CompleteLogoBlue.png";

export default function SignInPage() {
  const { login } = useContext(AuthContext);
  const { initializeLoadingToast, updateLoadingToast } = useLoadingToast();

  const { reset: emailReset, ...email } = useLoginTextFields("email");
  const { reset: passwordReset, ...password } = useLoginTextFields();
  const { showPassword, handleClickShowPassword } = usePassword();

  const [loading, setLoading] = useState(false);

  const handleSignInClick = async (event) => {
    event.preventDefault();
    let signInToastId;

    try {
      setLoading(true);
      signInToastId = initializeLoadingToast({
        text: `Verificando Campos...`,
      });

      if (email.value === "" || password.value === "") {
        const error = new Error("ClientError");
        error.response = {
          statusCode: 400,
          statusText: "Campos não preenchidos",
        };
        throw error;
      }

      if (!email.error) {
        await login(email.value, password.value);

        updateLoadingToast({
          id: signInToastId,
          text: `Login realizado com sucesso, você será redirecionado...`,
          type: "success",
        });
      }
    } catch (error) {
      updateLoadingToast({
        id: signInToastId,
        text: error.response.statusText ?? `Verifique os campos e tente novamente.`,
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      display="flex"
      width="100vw"
      height="100vh"
      sx={{
        backgroundColor: "#1D1F2D",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        display="flex"
        height="80%"
        width="80%"
        maxWidth="400px"
        sx={{
          borderRadius: 10,
          backgroundColor: "#EEEEEE",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          p: 3,
        }}
      >
        <img
          width="70%"
          style={{ marginBottom: 25 }}
          src={CompleteLogo}
          alt="A logo that contains a blue chef hat and under it a blue mustache. On the left, on the same size as the image and in black it is written 'Recipe mate, '"
        />
        <ToastContainer />

        <form style={{ width: "100%" }} onSubmit={handleSignInClick}>
          <EmailTextField required={true} params={email} width="100%" disabled={false} />
          <PasswordTextField
            showPassword={showPassword}
            params={password}
            handleClickShowPassword={handleClickShowPassword}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={loading}
            sx={{ backgroundColor: "#CA9900", mt: 5, mb: 10 }}
          >
            {loading ? "Carregando..." : "Entrar"}
          </Button>
        </form>

        <Box
          display="flex"
          sx={{
            justifyContent: "space-between",
            alignSelf: "flex-start",
            width: "100%",
          }}
        >
          <NavLink style={{ textDecoration: "none", color: "#727482" }}>Esqueceu a senha?</NavLink>
          <NavLink style={{ textDecoration: "none", color: "#727482" }} to="/signUp">
            Não possui conta? Cadastre-se
          </NavLink>
        </Box>
      </Box>
    </Box>
  );
}
