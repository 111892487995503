import { useEffect, useState } from "react";

export const useRadioGroup = (initialState, initialStateIfUndefined) => {
  const [value, setValue] = useState(initialState);

  const parsedInitialValue = initialState === undefined ? initialStateIfUndefined : initialState;

  useEffect(() => {
    setValue(parsedInitialValue);
  }, [parsedInitialValue]);

  const onCustomChange = (value) => {
    setValue(value);
  };

  const onChange = (event) => {
    setValue(event.target.value);
  };

  return {
    value,
    onChange,
    onCustomChange,
  };
};
