import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useEffect } from "react";
import MultipleAutocomplete from "../../autoCompletes/MultipleAutocomplete";
import { FormatCNPJ, FormatCPF, FormatCellPhone } from "../../format/Formats";
import AddressForm from "../../forms/address/AddressForm";
import CustomTextField from "../../textFields/CustomTextField";
import FormatedCustomTextField from "../../textFields/FormatedCustomTextField";
import CustomRadioGroup from "../CustomRadioGroup";

export default function ClientForm({
  isCreation = true,
  totalWidth,
  columnHeight,
  apiData,
  isEngineer,
  willReceiveAutomaticCharges,
  whereToSentCharges,
  isAutomaticChargesSelected,
  clientName,
  isDisabled,
  cellPhone,
  email,
  invoiceId,
  isActive,
  isChecked,
  powerDistributionCompanies,
  clientType,
  cpf,
  cnpj,
  addressController,
  areAddressFieldsDisabled,
  clearCnpj,
  clearCpf,
  removeChargeFieldsErrors,
  clearChargeFields,
}) {
  useEffect(() => {
    if (!willReceiveAutomaticCharges.checked) {
      removeChargeFieldsErrors();
      clearChargeFields();
    }
  }, [willReceiveAutomaticCharges.checked]);

  useEffect(() => {
    if (clientType.value == "cpf") {
      clearCnpj();
    } else {
      clearCpf();
    }
  }, [clientType.value]);

  return (
    <Box
      sx={{
        width: totalWidth,
        display: "flex",
        justifyContent: "space-between",
        mt: 2,
      }}
    >
      <Box
        sx={{
          width: "45%",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        {isCreation ? (
          ""
        ) : (
          <FormControlLabel
            control={<Checkbox {...isActive} />}
            label="Está ativo?"
            labelPlacement="start"
            sx={{ marginLeft: 0 }}
          />
        )}
        <FormControlLabel
          control={<Checkbox {...isEngineer} />}
          label="É engenheiro?"
          labelPlacement="start"
          sx={{ marginLeft: 0 }}
        />

        <FormControlLabel
          control={<Checkbox {...willReceiveAutomaticCharges} />}
          label="Cobranças automáticas? (Boleto)"
          labelPlacement="start"
          sx={{ marginLeft: 0 }}
        />

        <CustomTextField
          params={clientName}
          disabled={isDisabled}
          label="Nome do Cliente"
          maxLength={30}
          width={totalWidth}
          required={true}
        />
        <FormatedCustomTextField
          params={cellPhone}
          disabled={isDisabled}
          label="Whatsapp"
          inputcomponent={FormatCellPhone}
          width={totalWidth}
          required={true}
        />
        <CustomTextField
          params={email}
          disabled={isDisabled}
          label="Email"
          width={totalWidth}
          required={true}
        />

        <MultipleAutocomplete
          width={totalWidth}
          required={true}
          label="Concessionárias"
          params={powerDistributionCompanies}
          values={apiData.powerDistributionCompanies}
          isChecked={isChecked}
        />

        <CustomTextField
          params={invoiceId}
          disabled={isDisabled}
          label="ID de Nota Fiscal"
          width={totalWidth}
          required={false}
        />
      </Box>
      <Box
        sx={{
          width: "45%",
          height: columnHeight,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <CustomRadioGroup
          radioGroupParams={whereToSentCharges}
          label={"Modo de envio de cobranças:"}
          radioValues={["whatsapp", "email"]}
          radioLabels={["Whatsapp", "E-mail"]}
          disabled={isAutomaticChargesSelected}
        />
        <CustomRadioGroup
          radioGroupParams={clientType}
          label={"Tipo de cliente:"}
          radioValues={["cpf", "cnpj"]}
          radioLabels={["Pessoa Física", "Pessoa Jurídica"]}
          disabled={isAutomaticChargesSelected}
        />
        <Box
          display="flex"
          sx={{
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <FormatedCustomTextField
            params={cpf}
            disabled={isAutomaticChargesSelected || clientType.value == "cnpj"}
            label="CPF"
            inputcomponent={FormatCPF}
            required={true}
            width="48.5%"
          />
          <FormatedCustomTextField
            params={cnpj}
            disabled={isAutomaticChargesSelected || clientType.value == "cpf"}
            label="CNPJ"
            inputcomponent={FormatCNPJ}
            required={true}
            width="48.5%"
          />
        </Box>
        <AddressForm
          controller={addressController}
          areAddressFieldsDisabled={areAddressFieldsDisabled}
        />
      </Box>
    </Box>
  );
}
