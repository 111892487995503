import React, { createContext, useContext, useEffect, useState } from "react";
import { useBaseData } from "../hooks/api/baseDataApiHooks";
import { AuthContext } from "./AuthContext";

export const ApiDataContext = createContext();

export const ApiDataProvider = ({ children }) => {
  const { logout } = useContext(AuthContext);

  const [apiData, setApiData] = useState();
  const { baseDataLoading, baseData, baseDataError } = useBaseData();

  useEffect(() => {
    if (!baseDataLoading) {
      setApiData(baseData);
    }
  }, [baseData, baseDataLoading]);

  return (
    <React.Fragment>
      {apiData === undefined ? null : (
        <ApiDataContext.Provider value={{ apiData }}>
          {children}
        </ApiDataContext.Provider>
      )}
    </React.Fragment>
  );
};
