import React from "react";
import { useRadioGroup } from "../../hooks/forms/RadioGroupHooks";
import CustomRadioGroup from "./CustomRadioGroup";
import CreatePowerDistributionCompany from "./powerDistributionCompany/CreatePowerDistributionCompany";
import EditPowerDistributionCompany from "./powerDistributionCompany/EditPowerDistributionCompany";

export default function PowerDistributionCompanyManagement() {
  const { ...radioGroupParams } = useRadioGroup("create");

  return (
    <React.Fragment>
      <CustomRadioGroup
        radioGroupParams={radioGroupParams}
        label={"O que fazer?"}
        radioValues={["create", "edit"]}
        radioLabels={["Criar", "Editar"]}
      />
      {radioGroupParams.value === "create" ? (
        <CreatePowerDistributionCompany />
      ) : (
        <EditPowerDistributionCompany />
      )}
    </React.Fragment>
  );
}
