import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import { FormatCellPhone, FormatCPF } from "../../format/Formats";
import AddressForm from "../../forms/address/AddressForm";
import CustomTextField from "../../textFields/CustomTextField";
import FormatedCustomTextField from "../../textFields/FormatedCustomTextField";

export default function EngineerForm({ controller, addressController }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        mt: 2,
      }}
    >
      <Box
        sx={{
          width: "45%",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <CustomTextField params={controller.name} label="Nome" maxLength={30} required={true} />
          </Grid>
          {/* <Grid item xs={12} sm={12}>
            <CustomTextField params={controller.email} label="Email" required={true} />
          </Grid> */}
          <Grid item xs={12} sm={6}>
            <FormatedCustomTextField
              params={controller.cpf}
              label="CPF"
              inputcomponent={FormatCPF}
              required={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTextField params={controller.nacionality} label="Nacionalidade" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTextField params={controller.rg} label="RG" required={true} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <CustomTextField
              params={controller.issuingBody}
              label="Órgão Expedidor"
              errorLabel="Órgão Expedidor Incorreto"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTextField
              params={controller.crea}
              label="Crea Origem"
              errorLabel="Dados Crea incorreto"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormatedCustomTextField
              params={controller.phone}
              label="Telefone"
              inputcomponent={FormatCellPhone}
              required={true}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          width: "45%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <AddressForm controller={addressController} />
      </Box>
    </Box>
  );
}
