import { api } from "../api";

export const getAllProvidences = async () => {
  let url = "/userProvidences";
  return await api.get(url);
};

export const getUserProvidences = async (userProvidencesIds) => {
  let url = "/userProvidences";

  return await api.get(url, {
    params: { providencesIds: userProvidencesIds },
  });
};

export const updateActivityStatus = async (
  projectId,
  providenceStatus,
  user,
  date
) => {
  let url = `/userProvidences/finish/${projectId}`;

  return await api.post(url, {
    providenceStatus: providenceStatus,
    user: user,
    date: date,
  });
};

export const updateStartedActivity = async (historyProvidenceId, userName) => {
  let url = `/userProvidences/start/${historyProvidenceId}`;

  return await api.post(url, {
    userName,
  });
};

export const updateCanceledActivity = async (historyProvidenceId) => {
  let url = `/userProvidences/cancel/${historyProvidenceId}`;

  return await api.post(url);
};

export const updateFinishedActivity = async (
  projectId,
  historyProvidenceId,
  historyStageId,
  nextDeadline,
  nextStage,
  nextProvidence,
  note
) => {
  let url = `/userProvidences/finish/${projectId}`;

  return await api.post(url, {
    historyProvidenceId: historyProvidenceId,
    historyStageId: historyStageId,
    nextDeadline: nextDeadline,
    nextStageId: nextStage,
    nextProvidenceId: nextProvidence,
    note: note,
  });
};
