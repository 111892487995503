import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { useQueryClient } from "react-query";
import useDialog from "../../../../hooks/dialog/useDialog";
import { useLoginTextFields } from "../../../../hooks/forms/loginHooks";
import {
  deleteAllowedEmail,
  updateAllowedEmail,
} from "../../../../services/api/api";
import EmailTextField from "../../../textFields/EmailTextField";

export default function EmailsResult({
  emailData,
  initializeLoadingToast,
  updateLoadingToast,
}) {
  const { reset: emailReset, ...email } = useLoginTextFields(
    "email",
    emailData?.email
  );

  const [isUserEditing, setIsUserEditing] = useState(false);

  const handleEditClick = () => {
    setIsUserEditing(!isUserEditing);
  };

  const { open, handleClickOpen, handleClickClose } = useDialog();

  const queryClient = useQueryClient();

  const [deleteRequestLoading, setDeleteRequestLoading] = useState(false);

  const handleDeleteEmail = async () => {
    let deleteToastId;
    try {
      setDeleteRequestLoading(true);
      deleteToastId = initializeLoadingToast({
        text: `Excluindo E-mail...`,
      });

      await deleteAllowedEmail(emailData.id);

      queryClient.invalidateQueries({ queryKey: ["allowedEmails"] });
      queryClient.invalidateQueries({ queryKey: ["users"] });

      updateLoadingToast({
        id: deleteToastId,
        text: `E-mail Excluído com sucesso`,
        type: "success",
      });
    } catch (error) {
      updateLoadingToast({
        id: deleteToastId,
        text:
          error.response.data.message ??
          `Erro ao Excluir E-mail, tente novamente`,
        type: "error",
      });
    } finally {
      setDeleteRequestLoading(false);
      handleClickClose();
    }
  };

  const [updateRequestLoading, setUpdateRequestLoading] = useState(false);

  const handleUpdateEmail = async () => {
    let updateToastId;
    try {
      setUpdateRequestLoading(true);
      updateToastId = initializeLoadingToast({
        text: `Atualizando E-mail...`,
      });

      await updateAllowedEmail(emailData.id, email.value);
      queryClient.invalidateQueries({ queryKey: ["allowedEmails"] });

      updateLoadingToast({
        id: updateToastId,
        text: `E-mail Atualizado com sucesso`,
        type: "success",
      });
    } catch (error) {
      updateLoadingToast({
        id: updateToastId,
        text:
          error.response.data.message ??
          `Erro ao atualizar E-mail, tente novamente`,
        type: "error",
      });
    } finally {
      setUpdateRequestLoading(false);
      handleEditClick();
    }
  };

  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleClickClose}>
        <DialogTitle>Excluir email</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Caso exista um usuário ativo com este email, este também será
            excluído.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickClose} variant="outlined">
            Cancelar
          </Button>
          <Button
            onClick={handleDeleteEmail}
            disabled={deleteRequestLoading}
            variant="contained"
            color="error"
            autoFocus
          >
            {deleteRequestLoading ? "Excluindo..." : "Excluir"}
          </Button>
        </DialogActions>
      </Dialog>

      <Box
        display="flex"
        sx={{
          justifyContent: "space-between",
          flexDirection: "column",
          borderRadius: 2,
          p: 2,
          backgroundColor: "#D9D9D9",
          boxShadow: 3,
          mb: 1,
        }}
      >
        <Box
          display="flex"
          width="100%"
          justifyContent="space-between"
          sx={{ mb: 1 }}
        >
          <Typography sx={{ color: "green" }}>
            {emailData?.users[0]?.userName == undefined
              ? null
              : `Usuário ativo: ${emailData?.users[0]?.userName}`}
          </Typography>
          <Box width="20%" display="flex" justifyContent="space-between">
            <Button
              variant="text"
              startIcon={<EditIcon />}
              onClick={handleEditClick}
            >
              Editar
            </Button>
            <Button
              variant="text"
              startIcon={<DeleteIcon />}
              color="error"
              disabled={isUserEditing}
              onClick={handleClickOpen}
            >
              Excluir
            </Button>
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <EmailTextField
            params={email}
            required={true}
            width="40%"
            disabled={!isUserEditing}
          />
          {isUserEditing && (
            <Button
              sx={{ height: "40%" }}
              variant="outlined"
              disabled={
                email.error ||
                email.value === "" ||
                updateRequestLoading ||
                deleteRequestLoading
              }
              onClick={handleUpdateEmail}
            >
              {updateRequestLoading ? "Salvando..." : "Salvar"}
            </Button>
          )}
        </Box>
      </Box>
    </React.Fragment>
  );
}
