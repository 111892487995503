import AssignmentLateOutlinedIcon from "@mui/icons-material/AssignmentLateOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import React, { useContext } from "react";

import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";

import { ApiDataContext } from "../../contexts/ApiDataContext";
import { FilterContext } from "../../contexts/FilterContext";

export default function Filter({ progressData }) {
  const [
    selectedProgress,
    setSelectedProgress,
    selectedStages,
    setSelectedStages,
  ] = useContext(FilterContext);

  const handleFilterClick = (buttonSelectedProgress) => {
    let name;

    switch (buttonSelectedProgress) {
      case 1:
        name = "em atraso";
        break;
      case 2:
        name = "até hoje";
        break;
      case 3:
        name = "no prazo";
        break;
      case 0:
        name = "sem prazo Maximo";
        break;
      default:
        name = "";
        break;
    }

    setSelectedProgress({
      name: name,
      value: buttonSelectedProgress,
    });

    sessionStorage.setItem("selectedProgressName", name);
    sessionStorage.setItem("selectedProgressValue", buttonSelectedProgress);
  };

  return (
    <React.Fragment>
      <Typography sx={{fontSize: "1.1em", fontWeight: "bold"}} >Filtros</Typography>
      <Box display={"flex"} sx={{ justifyContent: "space-around" }}>
        <Box
          display={"flex"}
          sx={{ flexDirection: "column", alignItems: "center" }}
        >
          <IconButton onClick={() => handleFilterClick(1)}>
            <Badge badgeContent={progressData.lateDeadline} color="primary">
              <AssignmentLateOutlinedIcon sx={{ fontSize: 50 }} />
            </Badge>
          </IconButton>
          <Typography variant="subtitle2">Em atraso</Typography>
        </Box>

        <Box
          display={"flex"}
          sx={{ flexDirection: "column", alignItems: "center" }}
        >
          <IconButton onClick={() => handleFilterClick(2)}>
            <Badge badgeContent={progressData.todayDeadline} color="primary">
              <AssignmentOutlinedIcon sx={{ fontSize: 50 }} />
            </Badge>
          </IconButton>
          <Typography variant="subtitle2">Hoje</Typography>
        </Box>

        <Box
          display={"flex"}
          sx={{ flexDirection: "column", alignItems: "center" }}
        >
          <IconButton onClick={() => handleFilterClick(3)}>
            <Badge badgeContent={progressData.onSchedule} color="primary">
              <AssignmentTurnedInOutlinedIcon sx={{ fontSize: 50 }} />
            </Badge>
          </IconButton>
          <Typography variant="subtitle2">No prazo</Typography>
        </Box>
      </Box>

      <Divider sx={{ mb: 2, mt: 2 }} />

      <StagesDropdown />
    </React.Fragment>
  );
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function StagesDropdown() {
  const [
    selectedProgress,
    setSelectedProgress,
    selectedStages,
    setSelectedStages,
  ] = useContext(FilterContext);

  const { apiData } = useContext(ApiDataContext);

  const isAllSelected =
    apiData.stages.length > 0 && selectedStages.length === apiData.stages.length;

  const handleChange = (event) => {
    const value = event.target.value;

    if (value[value.length - 1] === "all") {
      if (selectedStages.length === apiData.stages.length) {
        setSelectedStages([]);
        sessionStorage.clear("selectedStages");
      } else {
        setSelectedStages(apiData.stages);
        sessionStorage.setItem("selectedStages", JSON.stringify(apiData.stages));
      }

      return;
    }
    setSelectedStages(value);
    sessionStorage.setItem("selectedStages", JSON.stringify(value));
  };

  const isChecked = (value) => {
    let isEqual = false;

    selectedStages.forEach((e) => {
      if (e.id === value.id) {
        isEqual = true;
      }
    });
    return isEqual;
  };

  return (
    <div>
      <FormControl sx={{ m: 1, width: "97%" }}>
        <InputLabel id="mutiple-select-label">Etapas</InputLabel>
        <Select
          labelId="mutiple-select-label"
          multiple
          value={selectedStages}
          onChange={handleChange}
          input={<OutlinedInput label="Etapas" />}
          renderValue={(selectedStages) => {
            const initialValue = "";
            const str = selectedStages.reduce(
              (accumulator, currentValue) =>
                accumulator + currentValue.stageName + ", ",
              initialValue
            );
            return str;
          }}
          MenuProps={MenuProps}
        >
          <MenuItem value="all">
            <ListItemIcon>
              <Checkbox
                checked={isAllSelected}
                indeterminate={
                  selectedStages.length > 0 &&
                  selectedStages.length < apiData.stages.length
                }
              />
            </ListItemIcon>
            <ListItemText>
              <Typography sx={{ fontWeight: "bold" }}>
                Selecione todos
              </Typography>
            </ListItemText>
          </MenuItem>

          {apiData.stages.map((value) => (
            <MenuItem key={value.id} value={value}>
              <Checkbox checked={isChecked(value)} />
              <ListItemText primary={value.stageName} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
