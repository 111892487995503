import jsPDF from "jspdf";
import { formatMachineDateBR } from "../date";

const headers = {
  projectNumber: {
    value: "Nº do Projeto",
    width: 0,
  },
  name: {
    value: "Nome do Cliente",
    width: 0,
  },

  clientName: {
    value: "Integrador",
    width: 0,
  },
  power: {
    value: "Potência",
    width: 0,
  },
  price: {
    value: "Valor",
    width: 0,
  },
  firstSentDate: {
    value: "Data de Envio",
    width: 0,
  },
  stageName: {
    value: "Etapa",
    width: 0,
  },
  conditionName: {
    value: "Situação",
    width: 0,
  },
  firstPaymentDate: {
    value: "1ª Parcela",
    width: 0,
  },
  secondPaymentDate: {
    value: "2ª Parcela",
    width: 0,
  },
};

const getHeadersWidths = ({
  doc: doc,
  headers: headers,
  fontSize: fontSize,
}) => {
  headers.forEach((header) => {
    var headerKey = header[0];
    var headerValue = header[1].value;
    var stringWidth =
      (doc.getStringUnitWidth(headerValue) * fontSize) / (72 / 25.6) + 16;

    switch (headerKey) {
      case "projectNumber":
      case "power":
      case "firstSentDate":
        stringWidth -= 12;
        break;
      case "price":
        stringWidth -= 8;
        break;
      case "stageName":
        stringWidth += 25;
        break;
    }

    header[1].width = stringWidth;
  });
};

const drawHeaders = ({
  doc: doc,
  headers: headers,
  fontSize: fontSize,
  x: x,
  y: y,
  docHeight,
  docWidth,
}) => {
  doc.setFont("times", "italic", "bold");
  doc.setFontSize(fontSize);

  headers.forEach((header, headerIndex) => {
    var headerValue = header[1].value;
    var stringWidth = header[1].width;

    // Add header to PDF
    doc.text(headerValue, x, y);

    if (headerIndex < headers.length - 1) {
      doc.line(x + stringWidth - 2, y - 5, x + stringWidth - 2, docHeight);
    }

    if (headerIndex == 0) {
      doc.line(0, y + 3, docWidth, y + 3);
    }

    x += stringWidth;
  });
};

export const generateComissionPDF = (data, fileDate) => {
  var doc = new jsPDF({
    orientation: "l",
    unit: "mm",
    format: "a4",
  });

  // Set font for headers
  var headerFontSize = 10;
  // Set font for data
  var dataFontSize = 8;
  //Set line width
  doc.setLineWidth(0.1);

  var x = 2;
  var y = 5;

  var docWidth = doc.internal.pageSize.getWidth();
  var docHeight = doc.internal.pageSize.getHeight();

  var headersArray = Object.entries(headers);
  getHeadersWidths({
    doc: doc,
    headers: headersArray,
    fontSize: headerFontSize,
  });
  drawHeaders({
    doc: doc,
    headers: headersArray,
    fontSize: headerFontSize,
    x: x,
    y: y,
    docHeight: docHeight,
    docWidth: docWidth,
  });

  doc.setFont("helvetica", "normal");
  doc.setFontSize(dataFontSize);
  data.forEach((project, pIndex) => {
    x = 2;
    y += 10;

    Object.entries(project).forEach((item, iIndex) => {
      var itemKey = item[0];

      if (itemKey == "id") {
        return;
      }

      var headerWidth = headers[itemKey].width;

      var itemValue = item[1];
      var formattedValue = String(itemValue || "-"); // Convert to string

      if (itemKey.toLowerCase().includes("date") && formattedValue != "-") {
        formattedValue = formatMachineDateBR(formattedValue).toString();
      } else if (itemKey.toLowerCase() == "price" && formattedValue != "-") {
        formattedValue = "R$ " + formattedValue;
      } else if (itemKey.toLowerCase() == "power" && formattedValue != "-") {
        formattedValue = formattedValue + " kW";
      }

      // Calculate the width of the formatted value
      var itemValueWidth =
        (doc.getStringUnitWidth(formattedValue) * dataFontSize) / (72 / 25.6);

      // Truncate the value if it exceeds the available space
      if (itemValueWidth >= headerWidth - 3) {
        // Calculate the number of characters to keep
        var charsToKeep =
          Math.floor(
            (headerWidth - 3) * (formattedValue.length / itemValueWidth)
          ) - 2;

        formattedValue = formattedValue.substring(0, charsToKeep) + "...";
      }

      doc.text(formattedValue, x, y);

      // If it's the last data entry, draw a horizontal line below it
      if (iIndex === Object.keys(project).length - 1) {
        doc.line(0, y + 3, docWidth, y + 3);
      }

      x += headerWidth;
    });

    if (pIndex > 0 && pIndex % 20 == 0) {
      doc.addPage();
      y = 5;
      x = 2;

      drawHeaders({
        doc: doc,
        headers: headersArray,
        fontSize: headerFontSize,
        x: x,
        y: y,
        docHeight: docHeight,
        docWidth: docWidth,
      });
      doc.setFont("helvetica", "normal");
      doc.setFontSize(dataFontSize);
    }
  });

  doc.save("comissão " + fileDate + ".pdf");
};
