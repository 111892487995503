import { createSlice } from "@reduxjs/toolkit";

export const pageSlice = createSlice({
  name: "payment-page",
  initialState: {
    value: 0,
  },
  reducers: {
    changePage: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changePage } = pageSlice.actions;

export default pageSlice.reducer;
