import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { PieChart, Pie, Cell } from "recharts";
import { FormatPercentageTypography } from "../../format/Formats";

export default function Chart({ data }) {
  let parsedProgressData = [];
  data.forEach((d) => {
    let obj = {};
    obj.name = d.name;
    obj.value = parseFloat(d.percentage);

    parsedProgressData.push(obj);
  });

  const sx = {
    fontFamily: "Red Hat Display",
    fontSize: "0.875rem",
    color: "#292929",
  };

  const COLORS = ["#f8c752", "#62C283", "#CA5878", "#E27474", "#039586"];

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      height="100%"
      sx={{
        borderRadius: "0.625rem",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.3)",
      }}
    >
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        sx={{
          paddingX: 3.5,
          paddingY: 2,
          backgroundColor: "#EEEEEE",
          borderRadius: "0.625rem",
        }}
      >
        <Typography sx={{ fontSize: "1.25rem", fontWeight: 600 }}>
          Gráfico de Situação
        </Typography>
      </Box>

      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        width="100%"
        height="100%"
        sx={{ p: 4 }}
      >
        <PieChart width={190} height={200}>
          <Pie
            data={parsedProgressData}
            cx={90}
            cy={95}
            labelLine={false}
            // label={renderCustomizedLabel}
            outerRadius={90}
            innerRadius={50}
            fill="#8884d8"
            dataKey="value"
          >
            {parsedProgressData.map((value, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
        </PieChart>

        <Box
          display={"flex"}
          sx={{
            width: "45%",
            flexDirection: "column",
            mt: 2,
            justifyContent: "space-around",
          }}
        >
          {parsedProgressData.map((value, index) => {
            return (
              <Box display={"flex"} justifyContent="space-between" key={index}>
                <Box display={"flex"}>
                  <Box
                    sx={{
                      width: 20,
                      height: 20,
                      mr: 1,
                      borderRadius: 20,
                      backgroundColor: COLORS[index % COLORS.length],
                    }}
                  />
                  <Typography sx={sx}>{value.name}</Typography>
                </Box>
                <FormatPercentageTypography value={value.value} sx={sx} />
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
}
