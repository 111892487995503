import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import React, { useContext, useState } from "react";

import Filter from "../components/home/Filter";
import FilterResult from "../components/home/FilterResult";
import StagesPieChart from "../components/home/StagesPieChart";

import { Box, LinearProgress } from "@mui/material";
import { FilterContext } from "../contexts/FilterContext";
import { useHomeBaseData } from "../hooks/api/homeApiHooks";

import AppBarMenu from "../components/AppBarMenu";
import { ApiDataContext } from "../contexts/ApiDataContext";
import { useTheme } from "@mui/system";

export default function HomePage() {
  const [selectedProgress, setSelectedProgress] = useState();
  const [selectedStages, setSelectedStages] = useState([]);

  const { homeBaseDataLoading, homeBaseData } = useHomeBaseData();

  const { apiData } = useContext(ApiDataContext);
  const theme = useTheme();

  const bgColor = theme.palette.background.default;

  return (
    <FilterContext.Provider
      value={[
        selectedProgress,
        setSelectedProgress,
        selectedStages,
        setSelectedStages,
      ]}
    >
      {homeBaseDataLoading || apiData === undefined ? (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      ) : (
        <AppBarMenu>
          <Container maxWidth={false} sx={{ maxWidth: "95%" }}>
            <Grid container spacing={3} sx={{ padding: 4 }}>
              <Grid item xs={12} md={6} lg={6}>
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    height: 240,
                  }}
                >
                  <Filter progressData={homeBaseData} />
                </Paper>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    height: 240,
                  }}
                >
                  <StagesPieChart progressData={homeBaseData} />
                </Paper>
              </Grid>

              <Grid item xs={12}>
                <FilterResult />
              </Grid>
            </Grid>
          </Container>
        </AppBarMenu>
      )}
    </FilterContext.Provider>
  );
}
