import Button from "@mui/material/Button";
import { useTheme } from "@mui/system";
import React from "react";

export default function CustomLoadingButton({
  startIcon = null,
  color = null,
  hoverColor = null,
  requestLoading,
  handleButtonClick = null,
  buttonText,
  buttonLoadingText,
  sx,
  disabled,
  type = "button",
}) {
  const theme = useTheme();

  let buttonSx;
  buttonSx = {
    ...sx,
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.hover,
    },
  };

  if (color !== null && hoverColor !== null) {
    buttonSx = {
      ...sx,
      backgroundColor: color,
      "&:hover": {
        backgroundColor: hoverColor,
      },
    };
  }

  return (
    <Button
      type={type}
      startIcon={startIcon}
      variant="contained"
      sx={buttonSx}
      disabled={disabled}
      onClick={handleButtonClick}
    >
      {requestLoading ? buttonLoadingText : buttonText}
    </Button>
  );
}
