import React from "react";
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box } from "@mui/material";
import { FormatCNPJ, FormatCPF } from "../../format/Formats";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomRadioGroup from "../../management/CustomRadioGroup";
import FormatedCustomTextField from "../../textFields/FormatedCustomTextField";
import CustomTextField from "../../textFields/CustomTextField";
import AddressForm from "../address/AddressForm";

export default function AttorneyForm({ isDisabled, controller, addressController }) {
  return (
    <Accordion sx={{ mt: 2 }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ fontSize: "1.1rem", fontWeight: 600 }}>
          Dados para Procuração *
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
        <CustomRadioGroup
          radioGroupParams={controller.clientType}
          label={"Tipo de cliente:"}
          radioValues={["PERSON", "ENTITY"]}
          radioLabels={["Pessoa Física", "Pessoa Jurídica"]}
        />

        <Box
          sx={{
            height: 400,
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            mt: 2,
          }}
        >
          <Box
            sx={{
              width: "48%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {controller.clientType.value === "ENTITY" && (
              <React.Fragment>
                <FormatedCustomTextField
                  params={controller.cnpj}
                  disabled={isDisabled}
                  label="CNPJ"
                  inputcomponent={FormatCNPJ}
                />
                <CustomTextField
                  params={controller.repName}
                  disabled={isDisabled}
                  label="Nome - Representante Legal"
                  errorLabel="Representante Legal Incorreto"
                />
                <CustomTextField
                  params={controller.repNationality}
                  disabled={isDisabled}
                  label="Nacionalidade - Representante Legal"
                />
              </React.Fragment>
            )}
            <FormatedCustomTextField
              params={controller.cpf}
              disabled={isDisabled}
              label={controller.clientType.value === "ENTITY" ? "CPF - Representante Legal" : "CPF"}
              inputcomponent={FormatCPF}
            />
            <CustomTextField
              params={controller.rg}
              disabled={isDisabled}
              label={controller.clientType.value === "ENTITY" ? "RG - Representante Legal" : "RG"}
            />
            <CustomTextField
              params={controller.issuingBody}
              disabled={isDisabled}
              label={
                controller.clientType.value === "ENTITY"
                  ? "Órgão Expedidor - Representante Legal"
                  : "Órgão Expedidor"
              }
              errorLabel="Órgão Expedidor Incorreto"
            />
          </Box>
          <Box
            sx={{
              width: "48%",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <AddressForm controller={addressController} required={false} />
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
