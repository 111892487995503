import { useTheme } from "@emotion/react";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import React, { useContext, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { ToastContainer } from "react-toastify";
import { ApiDataContext } from "../../../contexts/ApiDataContext";
import useDialog from "../../../hooks/dialog/useDialog";
import { useCheckBox, useManagementTextField } from "../../../hooks/forms/ManagementHooks";
import { useRadioGroup } from "../../../hooks/forms/RadioGroupHooks";
import { useLoginTextFields } from "../../../hooks/forms/loginHooks";
import { useLoadingToast } from "../../../hooks/toast/toasts";
import { deleteClient, updateClient } from "../../../services/api/api";
import { getServiceProvisionContract, getAddressFromZipCode } from "../../../services/api/api";
import CustomAutoComplete from "../../autoCompletes/CustomAutoComplete";
import CustomLoadingButton from "../../button/CustomLoadingButton";
import CustomDialog from "../../dialog/CustomDialog";
import ClientForm from "./ClientForm";
import { isValueNullOrEmpty } from "../../../services/validation";
import AddressFormController from "../../forms/address/controller";

export default function EditClient() {
  const { apiData } = useContext(ApiDataContext);
  const theme = useTheme();

  const { initializeLoadingToast, updateLoadingToast } = useLoadingToast();

  const [clientValue, setClientValue] = useState("");
  const [clientPDCs, setClientPDCs] = useState();

  const handleClientChange = async (event, newValue) => {
    clearAllFields();
    setClientValue(newValue);
    let arrayClientPDCs = [];
    newValue.clientsPDC.map((PDC) => {
      arrayClientPDCs.push(PDC.powerDistributionCompanies);
    });

    setClientPDCs(arrayClientPDCs);
    addressController.loadData(newValue);
  };

  const client = {
    value: clientValue,
    onChange: handleClientChange,
  };

  const {
    reset: resetClientName,
    isFieldValid: validateClientName,
    ...clientName
  } = useManagementTextField(clientValue?.name);

  const {
    reset: resetEmail,
    isFieldValid: validateEmail,
    ...email
  } = useLoginTextFields("email", clientValue?.email ?? "");

  const {
    reset: resetCellPhone,
    isFieldValid: validateCellPhone,
    ...cellPhone
  } = useManagementTextField(clientValue?.phone);

  const {
    reset: resetInvoiceId,
    isFieldValid: validateInvoiceId,
    ...invoiceId
  } = useManagementTextField(clientValue?.invoiceId ?? "");

  const [selectedPDCs, setSelectedPDCS] = useState([]);

  useEffect(() => {
    if (clientPDCs) {
      setSelectedPDCS(
        clientPDCs.map(
          (clientPDC) =>
            apiData.powerDistributionCompanies[
              apiData.powerDistributionCompanies.findIndex((obj) => obj.id === clientPDC.id)
            ]
        )
      );
    }
  }, [clientPDCs]);

  const handlePDCSelect = (event) => {
    setSelectedPDCS(event.target.value);
  };

  const powerDistributionCompanies = {
    value: selectedPDCs,
    onChange: handlePDCSelect,
  };

  const isChecked = (selected) => {
    let isEqual = false;

    selectedPDCs?.forEach((e) => {
      if (e.id === selected.id) {
        isEqual = true;
      }
    });
    return isEqual;
  };

  const isEngineer = useCheckBox(clientValue?.clientsEngineers?.length !== 0);
  const willReceiveAutomaticCharges = useCheckBox(clientValue?.automaticCharges === 1);
  const isActive = useCheckBox(clientValue?.isActive === 1);

  const whereToSentCharges = useRadioGroup(clientValue?.communicationChannel, "whatsapp");

  const clientType = useRadioGroup(isValueNullOrEmpty(clientValue?.cnpj) ? "cpf" : "cnpj");

  const {
    reset: resetCPF,
    isFieldValid: validateCpf,
    removeFieldError: removeCpfError,
    ...cpf
  } = useManagementTextField(clientValue?.cpf ?? "");
  const {
    reset: resetCNPJ,
    isFieldValid: validateCnpj,
    removeFieldError: removeCnpjError,
    ...cnpj
  } = useManagementTextField(clientValue?.cnpj ?? "");

  const {
    reset: resetZipCode,
    isFieldValid: validateZipCode,
    removeFieldError: removeZipCodeError,
    ...zipCode
  } = useManagementTextField(clientValue?.zipCode ?? "");

  const {
    reset: resetStreet,
    onCustomChange: onCustomStreetChange,
    isFieldValid: validateStreet,
    removeFieldError: removeStreetError,
    ...street
  } = useManagementTextField(clientValue?.street ?? "");

  const {
    reset: resetNumber,
    isFieldValid: validateNumber,
    removeFieldError: removeNumberError,
    ...number
  } = useManagementTextField(clientValue?.number ?? "");

  const {
    reset: resetDistrict,
    onCustomChange: onCustomDistrictChange,
    isFieldValid: validateDistrict,
    removeFieldError: removeDistrictError,
    ...district
  } = useManagementTextField(clientValue?.district ?? "");

  const {
    reset: resetAddressDetails,
    onCustomChange: onCustomAddressDetailsChange,
    ...addressDetails
  } = useManagementTextField(clientValue?.addressDetails ?? "");

  const {
    reset: resetCity,
    onCustomChange: onCustomCityChange,
    isFieldValid: validateCity,
    removeFieldError: removeCityError,
    ...city
  } = useManagementTextField(clientValue?.city ?? "");

  const {
    reset: resetState,
    onCustomChange: onCustomStateChange,
    isFieldValid: validateState,
    removeFieldError: removeStateError,
    ...state
  } = useManagementTextField(clientValue?.state ?? "");

  const setAddressFields = (data) => {
    onCustomStreetChange(data.street);
    onCustomDistrictChange(data.district);
    onCustomAddressDetailsChange(data.addressDetails);
    onCustomCityChange(data.city);
    onCustomStateChange(data.state);
  };

  const clearAddressFields = () => {
    resetStreet();
    resetNumber();
    resetDistrict();
    resetCity();
    resetState();
  };

  const clearCpf = () => {
    cpf.onCustomChange("");
    removeCpfError();
  };

  const clearCnpj = () => {
    cnpj.onCustomChange("");
    removeCnpjError();
  };

  const clearChargeFields = () => {
    resetCPF();
    resetCNPJ();
    resetZipCode();
    clearAddressFields();
  };
  const addressController = AddressFormController();
  const removeChargeFieldsErrors = () => {
    removeCpfError();
    removeCnpjError();
    removeZipCodeError();
    removeStreetError();
    removeNumberError();
    removeDistrictError();
    removeCityError();
    removeStateError();
  };

  const [zipCodeRequestLoading, setZipCodeRequestLoading] = useState(false);
  const [zipCodeLoaded, setzipCodeLoaded] = useState(false);

  useEffect(() => {
    if (clientValue?.zipCode != null) {
      setzipCodeLoaded(true);
      return;
    }
    setzipCodeLoaded(false);
  }, [clientValue?.zipCode]);

  const handleZipCodeClick = async (event) => {
    event.preventDefault();
    let toastId;
    try {
      clearAddressFields();
      setzipCodeLoaded(false);
      setZipCodeRequestLoading(true);

      toastId = initializeLoadingToast({
        text: `Buscando Cep ${zipCode.value}...`,
      });

      const { status, data } = await getAddressFromZipCode(zipCode.value);

      if (status === 200) {
        setAddressFields(data);

        updateLoadingToast({
          id: toastId,
          text: `Cep ${zipCode.value} encontrado`,
          type: "success",
        });
      }
    } catch (error) {
      updateLoadingToast({
        id: toastId,
        text: `Cep ${zipCode.value} não encontrado`,
        type: "error",
      });
    } finally {
      setZipCodeRequestLoading(false);
      setzipCodeLoaded(true);
    }
  };

  const isDisabled = false;

  const clearAllFields = () => {
    setClientValue(null);
  };

  const queryClient = useQueryClient();

  const [requestLoading, setRequestLoading] = useState(false);

  const areBasicFieldsValid = () => {
    const isNameValid = validateClientName();
    const isCellPhoneValid = validateCellPhone();
    const isEmailValid = validateEmail();

    return isNameValid && isCellPhoneValid && isEmailValid;
  };

  const areChargeFieldsValid = () => {
    const isCpfCnpjValid = clientType.value == "cpf" ? validateCpf() : validateCnpj();
    const isZipCodeValid = validateZipCode();
    const isStreetValid = validateStreet();
    const isNumberValid = validateNumber();
    const isDistrictValid = validateDistrict();
    const isCityValid = validateCity();
    const isStateValid = validateState();

    return (
      isCpfCnpjValid &&
      isCpfCnpjValid &&
      isZipCodeValid &&
      isStreetValid &&
      isNumberValid &&
      isDistrictValid &&
      isCityValid &&
      isStateValid
    );
  };

  const handleUpdateClick = async () => {
    let updateToastId;
    try {
      setRequestLoading(true);

      if (!areBasicFieldsValid()) {
        return;
      }

      if (willReceiveAutomaticCharges.checked) {
        if (!areChargeFieldsValid()) {
          return;
        }
      }

      updateToastId = initializeLoadingToast({
        text: `Atualizando Cliente...`,
      });

      let powerDistributionCompaniesIds = [];

      selectedPDCs.map((pdc) => {
        const powerDistributionCompanyId = {
          powerDistributionCompanyId: pdc.id,
        };

        powerDistributionCompaniesIds.push(powerDistributionCompanyId);
      });

      const communicationChannel =
        willReceiveAutomaticCharges.checked === true ? whereToSentCharges.value : null;

      const { status } = await updateClient(clientValue.id, {
        clientName: clientName.value,
        cellPhone: cellPhone.value,
        email: email.value,
        powerDistributionCompanies: powerDistributionCompaniesIds,
        isEngineer: isEngineer.checked,
        willReceiveAutomaticCharges: willReceiveAutomaticCharges.checked,
        communicationChannel,
        invoiceId: invoiceId.value,
        isActive: isActive.checked,
        cpf: cpf.value,
        cnpj: cnpj.value,
        ...addressController.values(),
      });

      if (status === 200) {
        queryClient.invalidateQueries({ queryKey: ["baseDataQuery"] });
        clearAllFields();

        updateLoadingToast({
          id: updateToastId,
          text: `Cliente Atualizado com sucesso`,
          type: "success",
        });
      }
    } catch (error) {
      console.error(error);
      updateLoadingToast({
        id: updateToastId,
        text: error?.response?.data.message ?? `Erro ao atualizar cliente, tente novamente`,
        type: "error",
      });
    } finally {
      setRequestLoading(false);
    }
  };

  const [deleteRequestLoading, setDeleteRequestLoading] = useState(false);
  const { open, handleClickOpen, handleClickClose } = useDialog();

  const handleDeleteClick = async () => {
    let deleteToastId;
    try {
      setDeleteRequestLoading(true);
      deleteToastId = initializeLoadingToast({
        text: `Excluindo Cliente...`,
      });

      const { status } = await deleteClient(
        clientValue.id,
        clientValue.clientsEngineers?.engineerId
      );

      if (status === 200) {
        queryClient.invalidateQueries({ queryKey: ["baseDataQuery"] });
        clearAllFields();

        updateLoadingToast({
          id: deleteToastId,
          text: `Cliente Excluído com sucesso`,
          type: "success",
        });
      }
    } catch (error) {
      updateLoadingToast({
        id: deleteToastId,
        text: error.response.data.message ?? `Erro ao Excluir cliente, tente novamente`,
        type: "error",
      });
    } finally {
      setDeleteRequestLoading(false);
      handleClickClose();
    }
  };

  const totalWidth = "100%";
  const columnHeight = "100%";
  let isAutomaticChargesSelected = !willReceiveAutomaticCharges.checked;
  let areAddressFieldsDisabled = requestLoading || isAutomaticChargesSelected || !zipCodeLoaded;

  const [isPDFloading, setPDFLoading] = useState(false);

  const handlePDFClick = async () => {
    let pdfToastId;

    try {
      setPDFLoading(true);
      const clientDoc = clientType.value == "cpf" ? cpf.value : cnpj.value;

      pdfToastId = initializeLoadingToast({
        text: `Gerando Contrato...`,
      });

      const { status, data } = await getServiceProvisionContract(
        clientName.value,
        clientType.value,
        clientDoc,
        street.value,
        number.value,
        district.value,
        city.value,
        state.value,
        zipCode.value,
        email.value
      );

      if (status === 200) {
        const blobUrl = URL.createObjectURL(data);
        const downloadLink = document.createElement("a");
        downloadLink.href = blobUrl;
        downloadLink.download = "contrato_servicos.docx";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

        updateLoadingToast({
          id: pdfToastId,
          text: `Contrato Gerado com sucesso`,
          type: "success",
        });
      }
    } catch (error) {
      updateLoadingToast({
        id: pdfToastId,
        text: error.response.data.message ?? `Erro ao Gerar Contrato, tente novamente`,
        type: "error",
      });
      console.error(error);
    } finally {
      setPDFLoading(false);
    }
  };

  return (
    <Box
      sx={{
        mt: 2,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <ToastContainer />

      <CustomDialog
        open={open}
        handleClickClose={handleClickClose}
        title="Excluir Integrador"
        contentText="As informações do integrador serão excluídas e não poderão ser recuperadas."
        requestLoading={deleteRequestLoading}
        handleConfirmationClick={handleDeleteClick}
        confirmationClickText="Excluir"
        requestLoadingText="Excluindo..."
      />

      <CustomAutoComplete
        params={client}
        options={apiData.clients}
        disabled={false}
        label="Selecione o Integrador"
        width="40%"
      />

      {clientValue ? (
        <Box
          sx={{
            width: totalWidth,
            display: "flex",
            flexDirection: "column",
            p: 2,
            justifyContent: "space-between",
            borderRadius: 3,
          }}
        >
          <ClientForm
            totalWidth={totalWidth}
            columnHeight={columnHeight}
            apiData={apiData}
            isEngineer={isEngineer}
            isCreation={false}
            isActive={isActive}
            willReceiveAutomaticCharges={willReceiveAutomaticCharges}
            whereToSentCharges={whereToSentCharges}
            isAutomaticChargesSelected={isAutomaticChargesSelected}
            clientName={clientName}
            isDisabled={isDisabled}
            cellPhone={cellPhone}
            email={email}
            isChecked={isChecked}
            powerDistributionCompanies={powerDistributionCompanies}
            invoiceId={invoiceId}
            clientType={clientType}
            cpf={cpf}
            cnpj={cnpj}
            zipCode={zipCode}
            zipCodeRequestLoading={zipCodeRequestLoading}
            handleZipCodeClick={handleZipCodeClick}
            areAddressFieldsDisabled={areAddressFieldsDisabled}
            addressController={addressController}
            clearCnpj={clearCnpj}
            clearCpf={clearCpf}
            removeChargeFieldsErrors={removeChargeFieldsErrors}
            clearChargeFields={clearChargeFields}
          />

          <Box
            display="flex"
            width="45%"
            sx={{ justifyContent: "space-between", alignSelf: "flex-end", mt: 2 }}
          >
            <CustomLoadingButton
              color={theme.palette.info.main}
              hoverColor={theme.palette.info.hover}
              startIcon={<PictureAsPdfIcon />}
              buttonText="Contrato"
              buttonLoadingText="Gerando..."
              sx={{
                width: "22%",
                mr: 5,
              }}
              disabled={isPDFloading}
              handleButtonClick={handlePDFClick}
              requestLoading={isPDFloading}
            />

            <Button
              variant="outlined"
              color="error"
              sx={{
                width: "30%",
              }}
              disabled={requestLoading || deleteRequestLoading}
              onClick={handleClickOpen}
            >
              Excluir
            </Button>

            <CustomLoadingButton
              buttonText="Atualizar"
              buttonLoadingText="Atualizando..."
              sx={{ width: "35%" }}
              disabled={requestLoading || deleteRequestLoading}
              handleButtonClick={handleUpdateClick}
              requestLoading={requestLoading}
            />
          </Box>
        </Box>
      ) : null}
    </Box>
  );
}
