import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { useFetchStagesHistory } from "../../hooks/api/stagesHistoryApiHooks";
import {
  updateCanceledActivity,
  updateStartedActivity,
} from "../../services/api/api";
import { formatDateHour } from "../../services/date";
import CustomButton from "../button/CustomButton";
import ActivityConfirmationModal from "./ActivityConfirmationModal";

export default function ActivityModal({ activityData }) {
  const [isActivityModalOpen, setIsActivityModalOpen] = useState(false);
  // const [isRunning, setRunning] = useState();
  // const [watch, setWatch] = useState(null);
  // const [currentTime, setCurrentTime] = useState(0);
  const [initialDate, setInitialDate] = useState(null);
  // const [finalDate, setFinalDate] = useState("");

  const projectId = activityData.id;

  const providenceHistoryId = activityData.providenceHistoryId;
  const isBeingRealized = activityData.beingRealized;
  const performUser = activityData.performUser;

  const providenceName = activityData.providenceName;
  const stageName = activityData.stageName;

  const user = JSON.parse(localStorage.getItem("user"));
  const { userName, userId } = user;

  const { stagesHistoryLoading, stagesHistoryData } =
    useFetchStagesHistory(projectId);

  const handleFirstOpen = async () => {
    if (isBeingRealized === 1) {
    }
    setIsActivityModalOpen(true);
    setInitialDate(formatDateHour(new Date()));

    await updateStartedActivity(providenceHistoryId, userName);
  };

  const handleOpen = (item) => {
    if (isBeingRealized === 1) {
    }
    setIsActivityModalOpen(true);
  };

  const handleClose = (event, reason) => {
    // if ((reason && reason === "backdropClick") || reason === "escapeKeyDown") {
    //   return;
    // }
    setIsActivityModalOpen(false);
    // setCurrentTime(0);
    // setRunning(false);
    // updateStatus(historyProvidenceId, null);
  };

  const [cancelRequestLoading, setCancelRequestLoading] = useState(false);

  const handleCancel = async () => {
    try {
      setCancelRequestLoading(true);
      await updateCanceledActivity(providenceHistoryId);
    } catch (error) {
      console.error(error);
    } finally {
      setCancelRequestLoading(false);
      setIsActivityModalOpen(false);
    }
  };

  // useEffect(() => {
  //   if (isRunning) {
  //     setWatch(
  //       setInterval(() => {
  //         setCurrentTime((time) => time + 10);
  //       }, 10)
  //     );
  //   } else {
  //     clearInterval(watch);
  //   }
  //   return () => {
  //     clearInterval(watch);
  //   };
  // }, [isRunning]);

  const buttonStyle = {
    width: "70%",
    heigth: "100%",
    borderRadius: "15px 0 15px 0",
  };

  return (
    <React.Fragment>
      {isBeingRealized === 1 ? (
        <React.Fragment>
          {performUser !== userName ? null : (
            <CustomButton
              buttonText="Continuar"
              sx={buttonStyle}
              disabled={false}
              handleButtonClick={(item) => handleOpen(item)}
            />
          )}
        </React.Fragment>
      ) : (
        <CustomButton
          buttonText="Realizar"
          sx={buttonStyle}
          disabled={false}
          handleButtonClick={(item) => handleFirstOpen(item)}
        />
      )}

      <Dialog
        open={isActivityModalOpen}
        // onClose={handleClose}
        fullWidth={true}
        maxWidth={"md"}
      >
        <Box sx={{ p: 5 }}>
          <Box display="flex" sx={{ justifyContent: "space-between" }}>
            <Typography sx={{ fontSize: "1.2rem", fontWeight: 700 }}>
              Projeto {activityData.projectNumber}
            </Typography>
            {activityData.providenceInitialDate === null ? (
              <React.Fragment>
                {initialDate === null ? null : (
                  <Typography sx={{ fontSize: "1.2rem", fontWeight: 700 }}>
                    Iniciou em
                    {" " + initialDate}
                  </Typography>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Typography sx={{ fontSize: "1.2rem", fontWeight: 700 }}>
                  Iniciou em
                  {" " + formatDateHour(activityData.providenceInitialDate)}
                </Typography>
              </React.Fragment>
            )}
          </Box>
          <Box
            display="flex"
            height={500}
            sx={{
              flexDirection: "column",
              borderRadius: 2,
              boxShadow: 3,
              p: 4,
            }}
          >
            <Box display="flex">
              <Typography width="20%" sx={{ fontWeight: "500" }}>
                Nome
              </Typography>
              <Typography width="80%" sx={{ fontWeight: "300" }}>
                {activityData.name}
              </Typography>
            </Box>
            <Divider orientation="horizontal" />
            <Box display="flex">
              <Typography width="20%" sx={{ fontWeight: "500" }}>
                Providência
              </Typography>
              <Typography width="80%" sx={{ fontWeight: "300" }}>
                {providenceName}
              </Typography>
            </Box>
            <Divider orientation="horizontal" />

            <Box display="flex">
              <Typography width="20%" sx={{ fontWeight: "500" }}>
                Etapa
              </Typography>
              <Typography width="80%" sx={{ fontWeight: "300" }}>
                {stageName}
              </Typography>
            </Box>
            <Divider orientation="horizontal" />

            <List
              sx={{
                position: "relative",
                overflow: "auto",
                maxHeight: 500,
                px: 3,
                mt: 3,
              }}
            >
              {stagesHistoryLoading ? (
                <Box>
                  <CircularProgress />
                </Box>
              ) : (
                <Box sx={{ minHeight: 600 }}>
                  {stagesHistoryData.map((stage, index, array) => {
                    const parsedInitialDate = formatDateHour(stage.initialDate);

                    let parsedFinalDate = "";
                    if (stage.finalDate != null) {
                      parsedFinalDate = formatDateHour(stage.finalDate);
                    }

                    return (
                      <ListItem
                        key={index}
                        sx={{
                          p: 2,
                          border: "1px solid black",
                          borderRadius: 2,
                          mb: 2,
                          // display: "flex",
                          // flexDirection: "column"
                        }}
                      >
                        <ListItemText
                          primary={stage.stages.stageName}
                          secondary={
                            <React.Fragment>
                              <Typography>
                                {parsedInitialDate} --- {parsedFinalDate}
                              </Typography>
                              <Typography>{stage.note}</Typography>
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                    );
                  })}
                </Box>
              )}
            </List>
            <Box display="flex" gap={2} sx={{ alignSelf: "flex-end", mt: 5 }}>
              <Button
                variant="outlined"
                color="error"
                width="50%"
                onClick={handleCancel}
                disabled={cancelRequestLoading}
              >
                {cancelRequestLoading ? "Cancelando..." : "Cancelar Atividade"}
              </Button>
              <ActivityConfirmationModal
                isActivityModalOpen={isActivityModalOpen}
                setIsActivityModalOpen={setIsActivityModalOpen}
                activityData={activityData}
                cancelRequestLoading={cancelRequestLoading}
              />
            </Box>
          </Box>
        </Box>
      </Dialog>
    </React.Fragment>
  );
}
