import React from "react";
import AppRoutes from "./Router";
import { Provider } from "react-redux";
import { store } from "./store/configureStore";
import Theme from "./config/CustomThemeProvider";

export default function App() {
  return (
    <Provider store={store}>
      <Theme>
        <AppRoutes />
      </Theme>
    </Provider>
  );
}
