import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import themeReducer from "./ducks/themeDuck";
import pageReducer from "./ducks/payment-page-reducer";

const rootReducer = combineReducers({
  theme: themeReducer,
  paymentPage: pageReducer,
  // other reducers...
});

let store = configureStore({
  reducer: rootReducer,
});

export { store };
