
// Action Types
const TOGGLE_THEME = 'myApp/theme/TOGGLE_THEME';

// Action Creators
export const toggleTheme = () => ({
  type: TOGGLE_THEME,
});

// Reducer
const initialState = {
  currentTheme: 'light', // Default theme
};

export default function themeReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_THEME:
      return {
        ...state,
        currentTheme: state.currentTheme === 'light' ? 'dark' : 'light',
      };
    default:
      return state;
  }
}

// Selectors
export const selectCurrentTheme = (state) => state.theme.currentTheme;