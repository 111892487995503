import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { startOfMonth } from "date-fns";
import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { useLoadingToast } from "../../hooks/toast/toasts";
import {
  getComissionRules,
  getComissionVariables,
} from "../../services/api/api";
import { formatDateToDatabase } from "../../services/date";
import { FormatPercentage } from "../format/Formats";
import FormatedCustomTextField from "../textFields/FormatedCustomTextField";
import ComissionVariables from "./edit/ComissionVariables";

export default function EditComissions() {
  const { initializeLoadingToast, updateLoadingToast } = useLoadingToast();

  const [date, setDate] = useState(startOfMonth(new Date()));
  const [comissionData, setComissionData] = useState();
  const [loading, setLoading] = useState(true);
  const [areVariablesUpdating, setVariablesUpdating] = useState(false);

  const handleDateChange = (value) => {
    setDate(value);
    fetchData(value);
  };

  useEffect(() => {
    const today = startOfMonth(new Date());
    setDate(today);
    fetchData(today);
  }, []);

  const fetchData = async (date) => {
    try {
      setComissionData(null);

      setLoading(true);
      const formatedDeadline = date !== "" ? formatDateToDatabase(date) : "";

      const { data: comissionRules } = await getComissionRules();
      const { data: comissionVariables } = await getComissionVariables(
        formatedDeadline
      );

      setComissionData({
        comissionRules: comissionRules,
        comissionVariables: comissionVariables,
      });
    } catch (error) {
      setComissionData(null);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <ToastContainer />

      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          label={"Selecione ano e mês"}
          disableFuture={true}
          views={["month", "year"]}
          value={date}
          onChange={handleDateChange}
          sx={{ width: "35%", alignSelf: "flex-end", paddingBottom: 2 }}
        />
      </LocalizationProvider>

      {loading ? (
        ""
      ) : (
        <React.Fragment>
          {comissionData ? (
            <Grid item xs={12}>
              <Paper
                sx={{
                  p: 4,
                  display: "flex",
                  flexDirection: "row",
                  minHeight: 400,
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    width: "50%",
                    heigth: "100%",
                    border: "1px solid",
                    borderRadius: 2,
                  }}
                >
                  {comissionRulesTable(comissionData.comissionRules)}
                </Box>
                <Box sx={{ width: "35%", heigth: "100%" }}>
                  <ComissionVariables
                    requestLoading={areVariablesUpdating}
                    comissionVariables={comissionData.comissionVariables}
                    setVariablesUpdating={setVariablesUpdating}
                    date={date}
                    initializeLoadingToast={initializeLoadingToast}
                    updateLoadingToast={updateLoadingToast}
                  />
                </Box>
              </Paper>
            </Grid>
          ) : (
            ""
          )}
        </React.Fragment>
      )}
    </Box>
  );
}

function comissionRulesTable(comissionRules) {
  const textStyle = {
    width: "100% / 3",
    p: 1,
  };
  const horizontalBoxStyle = {
    display: "flex",
    justifyContent: "space-around",
  };
  const handleChange = (value) => {};

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <Box sx={horizontalBoxStyle}>
        <Typography sx={textStyle}>Índice de Reprova Inicial</Typography>
        <Typography sx={textStyle}>Índice de Reprova Final</Typography>
        <Typography sx={textStyle}>Porcentagem Comissão</Typography>
      </Box>
      {comissionRules.map((rule) => {
        return (
          <Box sx={horizontalBoxStyle} key={rule.id}>
            <FormatedCustomTextField
              params={{ value: rule.initialIndex, onChange: handleChange }}
              disabled={true}
              // label= ""
              // errorLabel= ""
              inputcomponent={FormatPercentage}
              width="100% /3"
              style={{ borderRadius: "0px" }}
            />
            <FormatedCustomTextField
              params={{ value: rule.finalIndex, onChange: handleChange }}
              disabled={true}
              // label= ""
              // errorLabel= ""
              inputcomponent={FormatPercentage}
              width="100% /3"
              style={{ borderRadius: "0px" }}
            />
            <FormatedCustomTextField
              params={{
                value: rule.comissionPercentage,
                onChange: handleChange,
              }}
              disabled={true}
              // label= ""
              // errorLabel= ""
              inputcomponent={FormatPercentage}
              width="100% /3"
              style={{ borderRadius: "0px" }}
            />
          </Box>
        );
      })}
    </Box>
  );
}
