import { useTheme } from "@emotion/react";
import PaymentsIcon from "@mui/icons-material/Payments";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import SendIcon from "@mui/icons-material/Send";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useContext, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { ApiDataContext } from "../../../contexts/ApiDataContext";
import useDialog from "../../../hooks/dialog/useDialog";
import { useAutocomplete, useFormField } from "../../../hooks/forms/FormHooks";
import { useCheckBox, useManagementTextField } from "../../../hooks/forms/ManagementHooks";
import useStepper from "../../../hooks/forms/StepperHooks";
import {
  commonUpdateProject,
  generateAttorneyPdf,
  sendBillingPdf,
} from "../../../services/api/api";
import DatePicker from "../../DatePicker";
import CustomAutoComplete from "../../autoCompletes/CustomAutoComplete";
import ProvidencesAutoComplete from "../../autoCompletes/ProvidencesAutoComplete";
import StagesAutoComplete from "../../autoCompletes/StagesAutoComplete";
import CustomLoadingButton from "../../button/CustomLoadingButton";
import CustomContentDialog from "../../dialog/CustomContentDialog";
import CustomStepper from "../../stepper/Stepper";
import CustomTextField from "../../textFields/CustomTextField";
import { downloadFileFromApi } from "../../../services/pdf/download-file-from-api";
import { useDatePicker } from "../../../hooks/forms/useDatePicker";

const defaultBillingKinds = [{ name: "ENTRADA" }, { name: "VISTORIA" }, { name: "INTEGRAL" }];

export default function CommonEdit({
  projectData,
  clearAllFields,
  isDisabled,
  setDisabled,
  initializeLoadingToast,
  updateLoadingToast,
}) {
  const { apiData } = useContext(ApiDataContext);
  const theme = useTheme();

  const [firstPayment, setFirstPayment] = useState({
    chargeDate: null,
    paymentDate: null,
  });
  const [secondPayment, setSecondPayment] = useState({
    chargeDate: null,
    paymentDate: null,
  });

  const [billingKinds, setBillingKinds] = useState([
    { name: "ENTRADA" },
    { name: "VISTORIA" },
    { name: "INTEGRAL" },
  ]);

  useEffect(() => {
    projectData.payments.forEach((p) => {
      switch (p.paymentKinds.name) {
        case "ENTRADA":
          setFirstPayment({
            chargeDate: p.chargeDate,
            paymentDate: p.paymentDate,
          });
          break;
        case "VISTORIA":
          setSecondPayment({
            chargeDate: p.chargeDate,
            paymentDate: p.paymentDate,
          });
          break;
        case "INTEGRAL":
          setFirstPayment({
            chargeDate: p.chargeDate,
            paymentDate: p.paymentDate,
          });
          setSecondPayment({
            chargeDate: p.chargeDate,
            paymentDate: p.paymentDate,
          });
          break;
        default:
          break;
      }
    });
  }, [projectData]);

  const queryClient = useQueryClient();
  const [requestLoading, setRequestLoading] = useState(false);

  const handleUpdateClick = async () => {
    let updateToastId;
    setRequestLoading(true);
    setDisabled(true);
    try {
      updateToastId = initializeLoadingToast({
        text: `Atualizando Projeto...`,
      });

      const { status } = await commonUpdateProject(
        projectData.id,
        projectName.value,
        projectData?.stagesHistory[0]?.id,
        projectData?.providencesHistory[0]?.id,
        stage?.value?.id,
        providence?.value?.id,
        deadline.value,
        condition.value?.id,
        note.value,
        // firstChargeStep.date,
        // secondChargeStep.date,
        // firstPaymentStep.date,
        // secondPaymentStep.date,
        sentDate.value,
        secondSentDate.value,
        approvalDate.value,
        inspectionDate.value,
        finishDate.value
      );

      if (status === 200) {
        clearAllFields();

        updateLoadingToast({
          id: updateToastId,
          text: `Projeto atualizado com sucesso`,
          type: "success",
        });

        queryClient.invalidateQueries({ queryKey: ["userProvidences"] });
        queryClient.invalidateQueries({ queryKey: ["homeBaseQuery"] });
        queryClient.invalidateQueries({ queryKey: ["filteredProjectsQuery"] });
      }
    } catch (error) {
      updateLoadingToast({
        id: updateToastId,
        text: error.response.data.message ?? `Erro ao enviar Atualizar Projeto, tente novamente`,
        type: "error",
      });
    } finally {
      setRequestLoading(false);
      setDisabled(false);
    }
  };

  const [isSendBillLoading, setIsSendBillLoading] = useState(false);

  const handleSendBillClick = async () => {
    let sendBillToastId;
    setIsSendBillLoading(true);
    setDisabled(true);
    try {
      handleBillDialogClose();
      sendBillToastId = initializeLoadingToast({
        text: `Enviando Cobrança...`,
      });

      const { status } = await sendBillingPdf(projectData.id, billingKind.value.name);

      if (status === 200) {
        clearAllFields();

        updateLoadingToast({
          id: sendBillToastId,
          text: `Cobrança em processo de envio ao integrador`,
          type: "success",
        });

        // switch (billingKind.value.name) {
        //   case "ENTRADA":
        //     firstChargeStep.onCustomToggle(true);
        //     break;
        //   case "VISTORIA":
        //     secondChargeStep.onCustomToggle(true);
        //     break;
        //   case "INTEGRAL":
        //     firstChargeStep.onCustomToggle(true);
        //     secondChargeStep.onCustomToggle(true);
        //     break;
        //   default:
        //     break;
        // }
      }
    } catch (error) {
      updateLoadingToast({
        id: sendBillToastId,
        text: error.response.data.message ?? `Erro ao enviar cobrança, tente novamente`,
        type: "error",
      });
    } finally {
      setIsSendBillLoading(false);
      setDisabled(false);
    }
  };

  const [isAttorneySending, setIsAttorneySending] = useState(false);

  const handleSendAttorney = async () => {
    let sendAttorneyToastId;
    setIsAttorneySending(true);
    setDisabled(true);
    try {
      sendAttorneyToastId = initializeLoadingToast({
        text: `Gerando Procuração...`,
      });

      const fileName = `Procuração - ${projectData.projectNumber} - ${projectData.name}.pdf`;
      const { status, data } = await generateAttorneyPdf(projectData.id);

      if (status === 200) {
        updateLoadingToast({
          id: sendAttorneyToastId,
          text: `Procuração gerada com sucesso`,
          type: "success",
        });

        downloadFileFromApi(data, fileName);
      }
    } catch (error) {
      updateLoadingToast({
        id: sendAttorneyToastId,
        text: error.response.data.message ?? `Erro ao gerar procuração, tente novamente`,
        type: "error",
      });
    } finally {
      setIsAttorneySending(false);
      setDisabled(false);
    }
  };

  const { reset: resetProjectName, ...projectName } = useManagementTextField(projectData?.name);

  const { reset: resetStage, ...stage } = useFormField(
    "autocompleteChangeInitialValue",
    projectData?.stagesHistory[0]?.stages,
    false
  );

  const { reset: resetProvidence, ...providence } = useFormField(
    "autocompleteChangeInitialValue",
    projectData?.providencesHistory[0]?.providences,
    false
  );

  const { reset: resetCondition, ...condition } = useFormField(
    "autocompleteChangeInitialValue",
    projectData?.conditions,
    false
  );

  const { reset: resetDeadline, ...deadline } = useDatePicker(projectData?.dueDate);

  const { reset: resetNote, ...note } = useManagementTextField(projectData?.stagesHistory[0]?.note);

  const { reset: resetSecondSent, ...secondSent } = useCheckBox(
    Boolean(projectData?.secondSentDate)
  );

  const { reset: resetSentDate, ...sentDate } = useDatePicker(projectData?.firstSentDate);
  const { reset: resetSecondSentDate, ...secondSentDate } = useDatePicker(
    projectData?.secondSentDate
  );

  const { reset: resetApprovalDate, ...approvalDate } = useDatePicker(projectData?.approvalDate);

  const { reset: resetInspectionDate, ...inspectionDate } = useDatePicker(
    projectData?.inspectionDate
  );

  const { reset: resetFinishDate, ...finishDate } = useDatePicker(projectData?.finishDate);

  const {
    open: billDialogOpen,
    handleClickOpen: handleBillDialogOpen,
    handleClickClose: handleBillDialogClose,
  } = useDialog();

  const { reset: resetBillingKind, ...billingKind } = useAutocomplete();

  const dialogContent = (
    <CustomAutoComplete
      params={billingKind}
      options={billingKinds}
      disabled={isDisabled}
      label="Tipo de Pagamento"
      width="100%"
    />
  );

  const firstChargeStep = useStepper(
    "1º Boleto enviado",
    <SendIcon />,
    Boolean(firstPayment?.chargeDate),
    firstPayment?.chargeDate
  );

  const firstPaymentStep = useStepper(
    "1ª parcela paga",
    <PaymentsIcon />,
    Boolean(firstPayment?.paymentDate),
    firstPayment?.paymentDate
  );

  const secondChargeStep = useStepper(
    "2º Boleto enviado",
    <SendIcon />,
    Boolean(secondPayment?.chargeDate),
    secondPayment?.chargeDate
  );

  const secondPaymentStep = useStepper(
    "2ª parcela paga",
    <PaymentsIcon />,
    Boolean(secondPayment?.paymentDate),
    secondPayment?.paymentDate
  );

  useEffect(() => {
    if (
      (firstChargeStep.isActive || firstPaymentStep.isActive) &&
      (secondChargeStep.isActive || secondPaymentStep.isActive)
    ) {
      setBillingKinds([]);
      return;
    }

    if (firstChargeStep.isActive || firstPaymentStep.isActive) {
      setBillingKinds(defaultBillingKinds.filter((item) => item.name === "VISTORIA"));
      return;
    }

    if (secondChargeStep.isActive || secondPaymentStep.isActive) {
      setBillingKinds(defaultBillingKinds.filter((item) => item.name === "ENTRADA"));
      return;
    }

    setBillingKinds(defaultBillingKinds);
  }, [
    firstChargeStep.isActive,
    firstPaymentStep.isActive,
    secondChargeStep.isActive,
    secondPaymentStep.isActive,
  ]);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        mt: 2,
        height: 650,
        boxShadow: 3,
      }}
    >
      <Box
        width="100%"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        sx={{
          paddingX: 3.5,
          paddingY: 2,
          backgroundColor: "#EEEEEE",
          borderRadius: "0.625rem",
        }}
      >
        <Typography sx={{ fontSize: "1.1rem", fontWeight: 600 }}>
          Informações Complementares
        </Typography>

        <Box
          sx={{
            display: "flex",
            alignSelf: "flex-end",
            width: "25%",
            justifyContent: "space-between",
          }}
        >
          <CustomLoadingButton
            color={theme.palette.info.main}
            hoverColor={theme.palette.info.hover}
            startIcon={<WhatsAppIcon />}
            buttonText="Boleto"
            buttonLoadingText="Gerando..."
            sx={{
              width: "45%",
            }}
            disabled={isDisabled}
            handleButtonClick={handleBillDialogOpen}
            requestLoading={isSendBillLoading}
          />

          <CustomLoadingButton
            color={theme.palette.info.main}
            hoverColor={theme.palette.info.hover}
            startIcon={<PictureAsPdfIcon />}
            buttonText="Procuração"
            buttonLoadingText="Gerando..."
            sx={{
              width: "45%",
            }}
            disabled={isDisabled}
            handleButtonClick={handleSendAttorney}
            requestLoading={isAttorneySending}
          />
        </Box>
      </Box>

      <CustomContentDialog
        open={billDialogOpen}
        handleClickClose={handleBillDialogClose}
        title="Enviar Boleto/Pix"
        content={dialogContent}
        requestLoading={isSendBillLoading}
        handleConfirmationClick={handleSendBillClick}
        confirmationClickText="Enviar"
        requestLoadingText="Enviando..."
        width={500}
        height={100}
      />

      {projectData ? (
        <Box
          sx={{
            mt: 4,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            p: 2,
            justifyContent: "space-between",
            borderRadius: 3,
            height: "100%",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "85%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                width: "45%",
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
              }}
            >
              <CustomTextField
                params={projectName}
                disabled={isDisabled}
                label="Nome do Cliente"
                errorLabel="Nome Incorreto"
                maxLength={50}
              />
              <StagesAutoComplete
                disabled={isDisabled}
                params={stage}
                options={apiData.stages}
                label="Etapa"
                width="100%"
              />
              <ProvidencesAutoComplete
                disabled={isDisabled}
                params={providence}
                options={apiData.providences}
                label="Providência"
                width="100%"
              />
              <DatePicker
                disabled={isDisabled}
                width="100%"
                label="Prazo Máximo"
                params={deadline}
              />
              <CustomAutoComplete
                disabled={isDisabled}
                params={condition}
                options={apiData.conditions}
                label="Situação"
                width="100%"
              />
              <CustomTextField
                params={note}
                disabled={isDisabled}
                label="Observação"
                errorLabel="Observação Incorreta"
                maxLength={200}
              />
            </Box>
            <Box
              sx={{
                width: "45%",
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
              }}
            >
              <CustomStepper
                steps={[firstChargeStep, firstPaymentStep, secondChargeStep, secondPaymentStep]}
              />

              <DatePicker
                disabled={isDisabled}
                width="100%"
                label="Data de Envio"
                params={sentDate}
              />
              <Box display="flex" sx={{ justifyContent: "space-between" }}>
                <FormControlLabel
                  control={<Checkbox {...secondSent} disabled={sentDate.value === ""} />}
                  label="Possui Segundo envio?"
                  labelPlacement="start"
                />
                <DatePicker
                  disabled={isDisabled || !secondSent.checked || sentDate.value === ""}
                  width="40%"
                  label="Data"
                  params={secondSentDate}
                />
              </Box>
              <DatePicker
                disabled={isDisabled || sentDate.value === ""}
                width="100%"
                label="Data de Parecer de Acesso"
                params={approvalDate}
              />
              <DatePicker
                disabled={isDisabled || approvalDate.value === ""}
                width="100%"
                label="Data de Vistoria"
                params={inspectionDate}
              />
              <DatePicker
                disabled={isDisabled || approvalDate.value === "" || inspectionDate.value === ""}
                width="100%"
                label="Data de Homologação"
                params={finishDate}
              />
            </Box>
          </Box>

          <CustomLoadingButton
            buttonText="Atualizar"
            buttonLoadingText="Atualizando..."
            sx={{ width: "17%", alignSelf: "flex-end" }}
            disabled={isDisabled}
            handleButtonClick={handleUpdateClick}
            requestLoading={requestLoading}
          />
        </Box>
      ) : null}
    </Box>
  );
}
