import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import React from "react";

export default function CustomRadioGroup({
  radioGroupParams,
  label,
  radioValues,
  radioLabels,
  disabled = false,
}) {
  return (
    <FormControl>
      <FormLabel disabled={disabled}>{label}</FormLabel>
      <RadioGroup row {...radioGroupParams}>
        {radioValues.map((radioValue, index) => (
          <FormControlLabel
            key={index}
            disabled={disabled}
            value={radioValue}
            control={<Radio />}
            label={radioLabels[index]}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
