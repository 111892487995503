import { api } from "../api";
export const getComissionData = async (date) => {
  let url = `/comissions/${date}`;
  return await api.get(url);
};

export const getComissionSummary = async (date) => {
  let url = `/comissions/summary/${date}`;
  return await api.get(url);
};
