import React, { useContext, useEffect, useState } from "react";
import StagesAutoComplete from "../autoCompletes/StagesAutoComplete";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useQueryClient } from "react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ApiDataContext } from "../../contexts/ApiDataContext";
import { useAutocomplete, useFormField } from "../../hooks/forms/FormHooks";
import { useManagementTextField } from "../../hooks/forms/ManagementHooks";
import { useLoadingToast } from "../../hooks/toast/toasts";
import { updateMeetingProject } from "../../services/api/api";
import DatePicker from "../DatePicker";
import CustomAutoComplete from "../autoCompletes/CustomAutoComplete";
import ProvidencesAutoComplete from "../autoCompletes/ProvidencesAutoComplete";
import CustomLoadingButton from "../button/CustomLoadingButton";
import { FormatMoney, FormatPower } from "../format/Formats";
import CustomTextField from "../textFields/CustomTextField";
import FormatedCustomTextField from "../textFields/FormatedCustomTextField";
import { useDatePicker } from "../../hooks/forms/useDatePicker";

const doubleBoxSx = {
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
};

const doubleBoxWidth = "45%";

const isDisabled = false;

export default function ProjectMeeting({ project }) {
  const queryClient = useQueryClient();
  const { apiData } = useContext(ApiDataContext);

  const [firstPayment, setFirstPayment] = useState({
    chargeDate: null,
    paymentDate: null,
  });
  const [secondPayment, setSecondPayment] = useState({
    chargeDate: null,
    paymentDate: null,
  });

  useEffect(() => {
    project.payments.forEach((p) => {
      switch (p.paymentKinds.name) {
        case "ENTRADA":
          setFirstPayment({
            chargeDate: p.chargeDate,
            paymentDate: p.paymentDate,
          });
          break;
        case "VISTORIA":
          setSecondPayment({
            chargeDate: p.chargeDate,
            paymentDate: p.paymentDate,
          });
          break;
        case "INTEGRAL":
          setFirstPayment({
            chargeDate: p.chargeDate,
            paymentDate: p.paymentDate,
          });
          setSecondPayment({
            chargeDate: p.chargeDate,
            paymentDate: p.paymentDate,
          });
          break;
        default:
          break;
      }
    });
  }, []);

  const { reset: resetProjectName, ...projectName } = useManagementTextField(project.name);

  const { reset: resetPDC, ...powerDistributionCompany } = useAutocomplete(
    project.powerDistributionCompanies,
    false
  );

  const { reset: resetEngineer, ...engineer } = useAutocomplete(project.engineers, false);

  const initialStageValue = {
    id: project.stage.stageId,
    stageName: project.stage.stageName,
  };

  const { reset: resetStage, ...stage } = useAutocomplete(initialStageValue, false);

  const initialProvidenceValue = {
    id: project.providence.providenceId,
    providenceName: project.providence.providenceName,
  };

  const { reset: resetProvidence, ...providence } = useAutocomplete(initialProvidenceValue, false);

  const { reset: resetNote, ...note } = useManagementTextField(project?.stage?.note);

  const { reset: resetCondition, ...condition } = useAutocomplete(project.conditions, false);

  const { reset: resetDeadline, ...deadline } = useDatePicker(project.dueDate);

  const [clientValue, setClientValue] = useState(project.clients);

  const handleClientChange = async (event, newValue) => {
    setClientValue(newValue);
  };

  const client = {
    value: clientValue,
    onChange: handleClientChange,
  };

  const { reset: resetPower, ...power } = useManagementTextField(project.power);

  const { reset: resetPrice, ...price } = useManagementTextField(project.price);

  const { reset: resetFirstPayment, ...firstPaymentDate } = useDatePicker(firstPayment.paymentDate);

  const { reset: resetSecondPayment, ...secondPaymentDate } = useDatePicker(
    secondPayment.paymentDate
  );

  const { reset: resetFirstSent, ...firstSentDate } = useDatePicker(project.firstSentDate);

  const { reset: resetSecondSent, ...secondSentDate } = useDatePicker(project.secondSentDate);

  const { reset: resetApprovalDate, ...approvalDate } = useDatePicker(project.approvalDate);

  const { reset: resetInspectionDate, ...inspectionDate } = useDatePicker(project.inspectionDate);

  const { reset: resetFinishDate, ...finishDate } = useDatePicker(project.finishDate);

  const [requestLoading, setRequestLoading] = useState(false);
  const { initializeLoadingToast, updateLoadingToast } = useLoadingToast();

  const handleUpdateClick = async () => {
    let toastId;
    try {
      setRequestLoading(true);

      toastId = initializeLoadingToast({
        text: `Atualizando Projeto ${project.projectNumber}...`,
      });

      const { status } = await updateMeetingProject(
        project.id,
        projectName.value,
        client?.value?.id,
        powerDistributionCompany?.value?.id,
        engineer?.value?.id,
        project.stage.id,
        project.providence.id,
        stage?.value?.id,
        providence?.value?.id,
        note.value,
        condition.value?.id,
        deadline.value,
        power.value,
        price.value,
        firstSentDate.value,
        secondSentDate.value,
        approvalDate.value,
        inspectionDate.value,
        finishDate.value
      );

      if (status === 200) {
        queryClient.setQueryData("meeting", (prevData) => {
          const newPages = prevData.pages.map((page) => {
            return {
              data: page.data.filter((item) => item.id !== project.id),
              nextId: page.nextId,
            };
          });

          return {
            pages: newPages,
            pageParams: prevData?.pageParams,
          };
        });

        let updatedIds = [];
        if ("updatedIds" in sessionStorage)
          updatedIds = JSON.parse(sessionStorage.getItem("updatedIds"));

        updatedIds.push(project.id);
        sessionStorage.setItem("updatedIds", JSON.stringify(updatedIds));

        updateLoadingToast({
          id: toastId,
          text: `Projeto ${project.projectNumber} atualizado com sucesso!`,
          type: "success",
        });
      }
    } catch (error) {
      updateLoadingToast({
        id: toastId,
        text: `Erro ao atualizar projeto ${project.projectNumber}`,
        type: "error",
      });

      console.error(error);
    } finally {
      setRequestLoading(false);
    }
  };

  return (
    <Paper
      key={project.id}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        borderRadius: "0.625rem",
        px: 2,
        py: 2,
        mt: 2,
        boxShadow: 3,
      }}
    >
      <ToastContainer />

      <Typography sx={{ fontSize: "1.2em" }}>
        Projeto
        <span style={{ fontWeight: 700 }}> {project.projectNumber}</span>
      </Typography>

      <Box display="flex">
        <Box display="flex" flexDirection="column" width="50%" sx={{ mb: 1, gap: 2, p: 2 }}>
          <CustomTextField
            params={projectName}
            disabled={isDisabled}
            label="Nome do Cliente"
            errorLabel="Nome Incorreto"
            maxLength={50}
          />

          <CustomAutoComplete
            disabled={isDisabled}
            params={client}
            options={apiData.clients}
            label="Integrador"
            width="100%"
          />

          <CustomAutoComplete
            disabled={isDisabled}
            params={powerDistributionCompany}
            options={apiData.powerDistributionCompanies}
            label="Concessionária"
            width="100%"
          />

          <CustomAutoComplete
            disabled={isDisabled}
            params={engineer}
            options={apiData.engineers}
            label="Engenheiro"
            width="100%"
          />

          <StagesAutoComplete
            disabled={isDisabled}
            params={stage}
            options={apiData.stages}
            label="Etapa"
            width="100%"
          />

          <ProvidencesAutoComplete
            disabled={isDisabled}
            params={providence}
            options={apiData.providences}
            label="Providencia"
            width="100%"
          />
          <CustomTextField
            params={note}
            disabled={isDisabled}
            label="Observação"
            errorLabel="Observação Incorreta"
            maxLength={200}
          />
        </Box>

        {/* ------------------------------------------------------  */}

        <Box display="flex" flexDirection="column" width="50%" sx={{ gap: 2, p: 2 }}>
          <Box sx={doubleBoxSx}>
            <CustomAutoComplete
              disabled={isDisabled}
              params={condition}
              options={apiData.conditions}
              label="Situação"
              width={doubleBoxWidth}
            />

            <DatePicker
              disabled={isDisabled}
              width={doubleBoxWidth}
              label="Prazo Máximo"
              params={deadline}
            />
          </Box>

          <Box sx={doubleBoxSx}>
            <FormatedCustomTextField
              params={power}
              disabled={isDisabled}
              label="Potência"
              errorLabel="Potência Incorreta"
              inputcomponent={FormatPower}
              required={false}
              width={doubleBoxWidth}
            />
            <FormatedCustomTextField
              params={price}
              disabled={isDisabled}
              label="Valor"
              errorLabel="Valor Incorreto"
              inputcomponent={FormatMoney}
              required={false}
              width={doubleBoxWidth}
            />
          </Box>

          <Box sx={doubleBoxSx}>
            <DatePicker
              disabled={isDisabled}
              width={doubleBoxWidth}
              label="Primeiro Envio"
              params={firstSentDate}
            />
            <DatePicker
              disabled={isDisabled}
              width={doubleBoxWidth}
              label="Segundo Envio"
              params={secondSentDate}
            />
          </Box>

          <Box sx={doubleBoxSx}>
            <DatePicker
              disabled={true}
              width={doubleBoxWidth}
              label="Primeiro Pagamento"
              params={firstPaymentDate}
            />
            <DatePicker
              disabled={true}
              width={doubleBoxWidth}
              label="Segundo Pagamento"
              params={secondPaymentDate}
            />
          </Box>

          <Box sx={doubleBoxSx}>
            <DatePicker
              disabled={isDisabled}
              width={doubleBoxWidth}
              label="Data de Aprovação"
              params={approvalDate}
            />
            <DatePicker
              disabled={isDisabled}
              width={doubleBoxWidth}
              label="Data de Vistoria"
              params={inspectionDate}
            />
          </Box>

          <DatePicker
            disabled={isDisabled}
            width="100%"
            label="Data de Homologação"
            params={finishDate}
          />
        </Box>
      </Box>

      <Box
        display="flex"
        width="20%"
        sx={{ alignSelf: "flex-end", justifyContent: "space-between" }}
      >
        <CustomLoadingButton
          buttonText="Atualizar"
          buttonLoadingText="Atualizando..."
          sx={{ width: "100%" }}
          disabled={requestLoading}
          handleButtonClick={handleUpdateClick}
          requestLoading={requestLoading}
        />
      </Box>
    </Paper>
  );
}
