import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { useInfiniteQuery, useQueryClient } from "react-query";
import AppBarMenu from "../components/AppBarMenu";
import CustomButton from "../components/button/CustomButton";
import CustomLoader from "../components/loader/CustomLoader";
import ProjectMeeting from "../components/meeting/ProjectMeeting";
import { api } from "../services/api/api";

export default function MeetingPage() {
  const { ref, inView } = useInView();
  const queryClient = useQueryClient();

  const {
    status,
    data,
    error,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ["meeting"],
    async ({ pageParam = 0 }) => {
      const limit = 3;
      const res = await api.get(`/meeting?page=${pageParam}&limit=${limit}`);

      if (!("updatedIds" in sessionStorage)) {
        return res.data;
      }

      let updatedIds = [];
      updatedIds = JSON.parse(sessionStorage.getItem("updatedIds"));

      if (updatedIds.length != 0) {
        const newData = res.data.data.filter(
          (item) => !updatedIds.includes(item.id)
        );

        return {
          data: newData,
          nextId: res.data.nextId,
        };
      }

      return res.data;
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextId ?? undefined,
      staleTime: 0,
      cacheTime: 0,
    }
  );

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  const handleCleanUpdatedIds = () => {
    sessionStorage.removeItem("updatedIds");
    queryClient.invalidateQueries("meeting");
  };

  const button = (
    <CustomButton
      buttonText="Remover Ids Atualizados"
      sx={{
        marginLeft: "auto",
        marginRight: "50px",
      }}
      disabled={false}
      handleButtonClick={handleCleanUpdatedIds}
    />
  );
  return (
    <AppBarMenu icons={button}>
      <Container maxWidth={false} sx={{ maxWidth: "98%", p: 4 }}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          {status === "loading" ? (
            <CircularProgress
              size={56}
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          ) : status === "error" ? (
            <Typography color="red" sx={{ fontWeight: 700 }}>
              Error: {error.message}
            </Typography>
          ) : (
            <React.Fragment>
              {data.pages.map((page) =>
                page.data.map((project) => (
                  <React.Fragment key={project.id}>
                    <ProjectMeeting project={project} />
                  </React.Fragment>
                ))
              )}
              <Box
                sx={{
                  mt: 3,
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {isFetchingNextPage ? (
                  <CustomLoader />
                ) : hasNextPage ? (
                  <Button
                    ref={ref}
                    variant="outlined"
                    sx={{
                      borderRadius: 2,
                    }}
                    onClick={() => fetchNextPage()}
                    disabled={!hasNextPage || isFetchingNextPage}
                  >
                    Carregar
                  </Button>
                ) : null}
              </Box>
            </React.Fragment>
          )}
        </Paper>
      </Container>
    </AppBarMenu>
  );
}
