import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import CustomDateRangePicker from "../CustomDateRangePicker";
import CustomAutoComplete from "../autoCompletes/CustomAutoComplete";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
export default function FiltersDialog({
  open,
  onClose,
  client,
  clientsOptions,
  status,
  statusOptions,
  range,
  handleRangeChange,
  handleApply,
  handleClearFilters,
}) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
          <Typography variant="h6">Filtros</Typography>
          <IconButton onClick={handleClearFilters}>
            <FilterAltOffIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
          <CustomAutoComplete
            params={client}
            options={clientsOptions}
            disabled={false}
            label="Selecione o Integrador"
            width="48%"
          />
          <CustomAutoComplete
            params={status}
            options={statusOptions}
            disabled={false}
            label="Selecione o status"
            width="48%"
          />
        </Box>

        <CustomDateRangePicker ranges={range} onChange={handleRangeChange} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button onClick={handleApply} variant="contained" color="primary">
          Aplicar Filtros
        </Button>
      </DialogActions>
    </Dialog>
  );
}
