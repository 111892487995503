import { Box, Typography } from "@mui/material";
import React from "react";
import AppBarMenu from "../components/AppBarMenu";

import { NavLink } from "react-router-dom";
import UnauthorizedError from "../media/illustrations/UnauthorizedError.jpg";

export default function UnauthorizedPage() {
  return (
    <AppBarMenu>
      <Box
        width="100%"
        height="80vh"
        display="flex"
        sx={{ justifyContent: "center", alignItems: "center" }}
      >
        <Box
          display="flex"
          width="100%"
          maxWidth="700px"
          sx={{
            borderRadius: 10,
            backgroundColor: "#ffffff",
            flexDirection: "column",
            //   justifyContent: "space-around",
            alignItems: "center",
            p: 5,
          }}
        >
          <img
            width="70%"
            src={UnauthorizedError}
            style={{ marginBottom: 20 }}
          />
          <Typography sx={{ color: "#727482" }}>
            {"Oops! Você não possui permissão para acessar esta página. "}
            <NavLink style={{ color: "black", fontStyle: "italic" }} to="/home">
              Voltar para o Início
            </NavLink>
          </Typography>
        </Box>
      </Box>
    </AppBarMenu>
  );
}
