import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import React from "react";
import { PieChart, Pie, Cell } from "recharts";

const COLORS = ["#ea648a", "#f8c752", "#039586", "#9a9a9a"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  if (percent === 0) {
    return;
  }

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export default function StagesPieChart({ progressData }) {
  let parsedProgressData = [];
  Object.entries(progressData).forEach((value) => {
    let obj = {};
    obj.name = value[0];
    obj.value = value[1];

    parsedProgressData.push(obj);
  });

  return (
    <React.Fragment>
      <Typography sx={{ fontSize: "1.1em", fontWeight: "bold" }}>
        Gráfico
      </Typography>
      <Box
        display={"flex"}
        sx={{ justifyContent: "space-around", alignContent: "baseline" }}
      >
        <PieChart width={160} height={160}>
          <Pie
            data={parsedProgressData}
            cx={75}
            cy={75}
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={75}
            fill="#8884d8"
            dataKey="value"
          >
            {parsedProgressData.map((value, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
        </PieChart>

        <Box
          display={"flex"}
          sx={{
            flexDirection: "column",
            mt: 2,
            justifyContent: "space-around",
          }}
        >
          {parsedProgressData.map((value, index) => {
            let parsedName = "";

            switch (value.name.toLowerCase()) {
              case "latedeadline":
                parsedName = "Em atraso";
                break;
              case "todaydeadline":
                parsedName = "Hoje";
                break;
              case "onschedule":
                parsedName = "No prazo";
                break;
            };

            return (
              <Box display={"flex"} key={index}>
                <Box
                  sx={{
                    width: 20,
                    height: 20,
                    mr: 1,
                    backgroundColor: COLORS[index % COLORS.length],
                  }}
                />
                <Typography>{parsedName}</Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    </React.Fragment>
  );
}
