import React from "react";
import SummaryComponent from "./SummaryComponent";
import { Box, Divider } from "@mui/material";
import { useSummary } from "../../hooks/api/financesHooks";

export default function Summary({ filters }) {
  const { data, isLoading } = useSummary(filters);

  if (isLoading) return <div>Carregando Resumo...</div>;

  return (
    <Box display="flex" width="70%" sx={{ justifyContent: "space-between", mt: 4 }}>
      {data.map((item, index) => {
        return (
          <React.Fragment key={index}>
            <SummaryComponent data={item} />
            {index !== data.length - 1 ? (
              <Divider orientation="vertical" variant="middle" flexItem />
            ) : null}
          </React.Fragment>
        );
      })}

      {/* {data ? <div>{JSON.stringify(data)}</div> : <div>Nenhum dado encontrado.</div>} */}
    </Box>
  );
}
