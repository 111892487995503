import { useManagementTextField } from "../../../hooks/forms/ManagementHooks";
import { useRadioGroup } from "../../../hooks/forms/RadioGroupHooks";

//   {
//     cpf,
//     cnpj,
//     repCpf,
//     repName,
//     repNacionality,
//     clientType,
//     repRg,
//     naturalPersonRg,
//     repIssuingBody,
//     naturalPersonIssuingBody,
//   }
export default function useAttorneyForm(
) {
  const clientType = useRadioGroup("");
  const { reset: resetCPF, ...cpf } = useManagementTextField("");
  const { reset: resetCNPJ, ...cnpj } = useManagementTextField("");
  const { reset: resetRepName, ...repName } = useManagementTextField("");
  const { reset: resetRepNationality, ...repNationality } = useManagementTextField("");
  const { reset: resetRg, ...rg } = useManagementTextField("");
  const { reset: resetIssuingBody, ...issuingBody } = useManagementTextField("");

  const validateFields = () => {
    if (clientType == "PERSON") {
      return cpf.isFieldValid();
    }
    return cnpj.isFieldValid();
  };

  const loadData = (_data) => {
    if (_data) {
      const naturalPerson = _data.projectsNaturalPerson;
      const legalEntity = _data.projectsLegalEntity;
      clientType.onCustomChange(_data.clientType);
      cpf.onCustomChange(
        (_data?.clientType == "PERSON" ? naturalPerson?.cpf : legalEntity?.repCpf) ?? ""
      );
      cnpj.onCustomChange(legalEntity?.cnpj ?? "");
      repName.onCustomChange(legalEntity?.repName ?? "");
      repNationality.onCustomChange(legalEntity?.repNationality ?? "");
      rg.onCustomChange(
        (_data?.clientType === "PERSON" ? naturalPerson?.rg : legalEntity?.repRg) ?? ""
      );
      issuingBody.onCustomChange(
        (_data?.clientType == "PERSON"
          ? naturalPerson?.issuingBody
          : legalEntity?.repIssuingBody) ?? ""
      );
    }
  };

  const clearAllFields = () => {
    cpf.onCustomChange("");
    cnpj.onCustomChange("");
    repName.onCustomChange("");
    repNationality.onCustomChange("");
    rg.onCustomChange("");
    issuingBody.onCustomChange("");
  };

  const values = () => {
    return {
      clientType: clientType.value,
      cpf: cpf.value,
      cnpj: cnpj.value,
      repName: repName.value,
      repNationality: repNationality.value,
      rg: rg.value,
      issuingBody: issuingBody.value,
    };
  };

  return {
    cpf,
    cnpj,
    clientType,
    repName,
    repNationality,
    rg,
    issuingBody,
    validateFields,
    values,
    clearAllFields,
    loadData,
  };
}
