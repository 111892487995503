import React from "react";
import AppBarMenu from "../../components/AppBarMenu";

export default function TimeManagementPage() {
  return (
    <AppBarMenu>
      <div>Em construção...</div>
    </AppBarMenu>
  );
}
