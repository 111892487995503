import React, { useContext, useState } from "react";

import Box from "@mui/material/Box";

import { useQueryClient } from "react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ApiDataContext } from "../../../contexts/ApiDataContext";
import {
  useCheckBox,
  useManagementTextField,
  useMultiplePowerDistributionCompanies,
} from "../../../hooks/forms/ManagementHooks";
import { useRadioGroup } from "../../../hooks/forms/RadioGroupHooks";
import { useLoginTextFields } from "../../../hooks/forms/loginHooks";
import { useLoadingToast } from "../../../hooks/toast/toasts";
import { createClient } from "../../../services/api/api";
import CustomLoadingButton from "../../button/CustomLoadingButton";
import AddressFormController from "../../forms/address/controller";
import ClientForm from "./ClientForm";
export default function CreateClient() {
  const { apiData } = useContext(ApiDataContext);

  const {
    reset: resetClientName,
    isFieldValid: validateClientName,
    ...clientName
  } = useManagementTextField("");
  const { reset: resetEmail, isFieldValid: validateEmail, ...email } = useLoginTextFields("email");
  const {
    reset: resetCellPhone,
    isFieldValid: validateCellPhone,
    ...cellPhone
  } = useManagementTextField("");

  const {
    reset: resetInvoiceId,
    isFieldValid: validateInvoiceId,
    ...invoiceId
  } = useManagementTextField("");

  const clientType = useRadioGroup("cpf");
  const {
    reset: resetCPF,
    isFieldValid: validateCpf,
    removeFieldError: removeCpfError,
    ...cpf
  } = useManagementTextField("");
  const {
    reset: resetCNPJ,
    isFieldValid: validateCnpj,
    removeFieldError: removeCnpjError,
    ...cnpj
  } = useManagementTextField("");

  const clearChargeFields = () => {
    resetCPF();
    resetCNPJ();
    resetZipCode();
    addressController.clearAllFields();
  };

  const clearCpf = () => {
    resetCPF();
    removeCpfError();
  };

  const clearCnpj = () => {
    resetCNPJ();
    removeCnpjError();
  };

  const {
    reset: resetZipCode,
    isFieldValid: validateZipCode,
    removeFieldError: removeZipCodeError,
    ...zipCode
  } = useManagementTextField("");

  const {
    isChecked,
    reset: resetPDC,
    isFieldValid: validatePDC,
    removeFieldError: removePDCError,
    ...powerDistributionCompanies
  } = useMultiplePowerDistributionCompanies("");

  const isEngineer = useCheckBox(false);
  const willReceiveAutomaticCharges = useCheckBox(false);

  const whereToSentCharges = useRadioGroup("whatsapp");

  const isDisabled = false;

  const clearAllFields = () => {
    resetCellPhone();
    resetClientName();
    resetEmail();
    resetPDC();
    resetInvoiceId();
    clearChargeFields();
  };
  const {
    reset: resetEngCPF,
    isFieldValid: validateEngCpf,
    removeFieldError: removeEngCpfError,
    ...engCpf
  } = useManagementTextField("");
  const { reset: resetengNacionality, ...engNacionality } = useManagementTextField("");
  const { reset: resetEngRg, ...engRg } = useManagementTextField("");
  const { reset: resetEngOrgaoExpedidor, ...engOrgaoExpedidor } = useManagementTextField("");
  const { reset: resetEngCrea, ...engCrea } = useManagementTextField("");
  const {
    reset: resetEngPhone,
    isFieldValid: validateEngPhone,
    ...engPhone
  } = useManagementTextField("");
  const queryClient = useQueryClient();
  const addressController = AddressFormController();
  const [requestLoading, setRequestLoading] = useState(false);

  const { initializeLoadingToast, updateLoadingToast } = useLoadingToast();

  const areBasicFieldsValid = () => {
    const isNameValid = validateClientName();
    const isCellPhoneValid = validateCellPhone();
    const isEmailValid = validateEmail();
    const isPdcValid = validatePDC();

    return isNameValid && isCellPhoneValid && isEmailValid && isPdcValid;
  };

  const areChargeFieldsValid = () => {
    const isCpfCnpjValid = clientType.value == "cpf" ? validateCpf() : validateCnpj();
    const addressValidate = addressController.validate();

    return isCpfCnpjValid && addressValidate;
  };

  const handleClick = async () => {
    let clientToastId;
    try {
      setRequestLoading(true);

      if (!areBasicFieldsValid()) {
        return;
      }

      if (willReceiveAutomaticCharges.checked) {
        if (!areChargeFieldsValid()) {
          return;
        }
      }

      clientToastId = initializeLoadingToast({
        text: `Criando Cliente...`,
      });

      let powerDistributionCompaniesIds = [];

      powerDistributionCompanies.value.map((pdc) => {
        const powerDistributionCompanyId = {
          powerDistributionCompanyId: pdc.id,
        };

        powerDistributionCompaniesIds.push(powerDistributionCompanyId);
      });

      const communicationChannel =
        willReceiveAutomaticCharges.checked === true ? whereToSentCharges.value : null;

      const { status } = await createClient({
        clientName: clientName.value,
        cellPhone: cellPhone.value,
        email: email.value,
        powerDistributionCompanies: powerDistributionCompaniesIds,
        isEngineer: isEngineer.checked,
        willReceiveAutomaticCharges: willReceiveAutomaticCharges.checked,
        communicationChannel,
        invoiceI: invoiceId.value,
        cpf: cpf.value,
        cnpj: cnpj.value,
        ...addressController.values(),
      });

      if (status === 201) {
        queryClient.invalidateQueries({ queryKey: ["baseDataQuery"] });
        clearAllFields();

        updateLoadingToast({
          id: clientToastId,
          text: `Cliente Criado com sucesso`,
          type: "success",
        });
      }
    } catch (error) {
      updateLoadingToast({
        id: clientToastId,
        text: error.response.data.message ?? "Erro ao criar cliente, tente novamente",
        type: "error",
      });
    } finally {
      setRequestLoading(false);
    }
  };

  const totalWidth = "100%";
  const columnHeight = "100%";
  let isAutomaticChargesSelected = !willReceiveAutomaticCharges.checked;
  let areAddressFieldsDisabled = requestLoading || isAutomaticChargesSelected;

  const removeChargeFieldsErrors = () => {
    removeCpfError();
    removeCnpjError();
    addressController.removeErrors();
  };

  return (
    <Box
      sx={{
        width: totalWidth,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <ToastContainer />

      <Box
        sx={{
          width: totalWidth,
          display: "flex",
          flexDirection: "column",
          p: 2,
          justifyContent: "space-between",
          borderRadius: 3,
          height: "100%",
        }}
      >
        <ClientForm
          totalWidth={totalWidth}
          columnHeight={columnHeight}
          apiData={apiData}
          isEngineer={isEngineer}
          willReceiveAutomaticCharges={willReceiveAutomaticCharges}
          whereToSentCharges={whereToSentCharges}
          isAutomaticChargesSelected={isAutomaticChargesSelected}
          clientName={clientName}
          isDisabled={isDisabled}
          cellPhone={cellPhone}
          email={email}
          isChecked={isChecked}
          powerDistributionCompanies={powerDistributionCompanies}
          invoiceId={invoiceId}
          clientType={clientType}
          cpf={cpf}
          cnpj={cnpj}
          addressController={addressController}
          areAddressFieldsDisabled={areAddressFieldsDisabled}
          clearCnpj={clearCnpj}
          clearCpf={clearCpf}
          removeChargeFieldsErrors={removeChargeFieldsErrors}
          clearChargeFields={clearChargeFields}
          engCpf={engCpf}
          engRg={engRg}
          engOrgaoExpedidor={engOrgaoExpedidor}
          engNacionality={engNacionality}
          engCrea={engCrea}
          engPhone={engPhone}
        />

        <CustomLoadingButton
          buttonText="Cadastrar"
          buttonLoadingText="Cadastrando..."
          sx={{ alignSelf: "flex-end", width: "15%", mt: 2 }}
          disabled={requestLoading}
          handleButtonClick={handleClick}
          requestLoading={requestLoading}
        />
      </Box>
    </Box>
  );
}
