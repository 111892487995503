import React from "react";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

export default function PowerDistributionCompaniesAutoComplete({
  params: params,
  options: options,
  disabled: disabled,
  label: label,
  width: width,
}) {

  return (
    <Autocomplete
      disableClearable
      disabled={disabled}
      options={options}
      {...params}
      isOptionEqualToValue={(option, value) => {

        if (value === "") {
          return true;
        }
        return option.powerDistributionCompanies.name === value.powerDistributionCompanies.name;
      }}
      getOptionLabel={(option) => {
        if (option === "") {
          return "";
        }
        return option.powerDistributionCompanies.name;
      }}
      sx={{ width: width }}
      renderInput={(params) => (
        <TextField {...params} label={label} />
      )}
    />
  );
}
