import { useQuery } from "react-query";
import { getAllowedEmails, getUsers } from "../../services/api/api";

const adminApi = {
  fetchAllowedEmails: async () => {
    const { data } = await getAllowedEmails();
    return data;
  },
  fetchUsers: async () => {
    const {data} = await getUsers();
    return data;
  }
};

export const useFetchAllowedEmails = () => {
  const { isLoading: allowedEmailsLoading, data: allowedEmailsData, } = useQuery(
    "allowedEmails",
    adminApi.fetchAllowedEmails,
    {
      staleTime: Infinity,
      retry: 5,
    }
  );

  return {
    allowedEmailsLoading,
    allowedEmailsData,
  };
};

export const useFetchUsers = () => {
  const { isLoading: usersLoading, data: usersData, } = useQuery(
    "users",
    adminApi.fetchUsers,
    {
      staleTime: Infinity,
      retry: 5,
    }
  );

  return {
    usersLoading,
    usersData,
  };
};
