import { api } from "../api";

export const getUsers = async () => {
  let url = `/admin/users`;
  return await api.get(url);
};

export const updateUser = async (
  userId,
  userName,
  role,
  jobRoleId,
  wage,
  comission,
  userProvidences
) => {
  let url = `/admin/users/${userId}`;

  return await api.put(url, {
    userName,
    role,
    jobRoleId,
    wage,
    comission,
    userProvidences,
  });
};

export const deleteUser = async (userId) => {
  let url = `/admin/users${userId}`;
  return await api.delete(url);
};
