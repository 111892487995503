import Button from "@mui/material/Button";
import { useTheme } from "@mui/system";
import React from "react";

export default function CustomButton({
  handleButtonClick,
  buttonText,
  sx,
  disabled,
  startIcon,
  endIcon
}) {
  const theme = useTheme();

  const buttonSx = {
    ...sx,
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.hover,
    },
  };

  return (
    <Button
      variant="contained"
      sx={buttonSx}
      disabled={disabled}
      onClick={handleButtonClick}
      startIcon={startIcon}
      endIcon={endIcon}
    >
      {buttonText}
    </Button>
  );
}
