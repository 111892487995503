import Box from "@mui/material/Box";
import React from "react";
import { useManagementTextField } from "../../../hooks/forms/ManagementHooks";
import {
  createComissionVariables,
  updateComissionVariables,
} from "../../../services/api/api";
import CustomLoadingButton from "../../button/CustomLoadingButton";
import { FormatMoney, FormatPercentage } from "../../format/Formats";
import FormatedCustomTextField from "../../textFields/FormatedCustomTextField";

export default function ComissionVariables({
  requestLoading,
  comissionVariables,
  setVariablesUpdating,
  date,
  initializeLoadingToast,
  updateLoadingToast,
}) {
  const handleUpdateClick = async () => {
    let updateToastId;
    try {
      setVariablesUpdating(true);
      updateToastId = initializeLoadingToast({
        text: `Atualizando Variáveis...`,
      });

      if (comissionVariables == null) {
        await createComissionVariables({
          date: date,
          taxes: taxesPercentage.value,
          monthlyExpenses: monthlyExpenses.value,
        });
      } else {
        await updateComissionVariables(
          comissionVariables.id,
          taxesPercentage.value,
          monthlyExpenses.value
        );
      }

      updateLoadingToast({
        id: updateToastId,
        text: `Variáveis Atualizadas com sucesso`,
        type: "success",
      });
    } catch (error) {
      updateLoadingToast({
        id: updateToastId,
        text:
          error.response.data.message ??
          `Erro ao atualizar Variáveis, tente novamente`,
        type: "error",
      });
    } finally {
      setVariablesUpdating(false);
    }
  };

  const { reset: resetTaxesPercentage, ...taxesPercentage } =
    useManagementTextField(comissionVariables?.taxes);
  const { reset: resteMonthlyExpenses, ...monthlyExpenses } =
    useManagementTextField(comissionVariables?.monthlyExpenses);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "55%",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "65%",
          justifyContent: "space-between",
        }}
      >
        <FormatedCustomTextField
          params={taxesPercentage}
          disabled={requestLoading}
          label="Porcentagem de Impostos"
          errorLabel="Valor Incorreto"
          inputcomponent={FormatPercentage}
          required={true}
          width="100%"
        />
        <FormatedCustomTextField
          params={monthlyExpenses}
          disabled={requestLoading}
          label="Custos Fixos"
          errorLabel="Valor Incorreto"
          inputcomponent={FormatMoney}
          required={false}
          width="100%"
        />
      </Box>

      <CustomLoadingButton
        buttonText="Atualizar"
        buttonLoadingText="Atualizando..."
        sx={{ alignSelf: "flex-end", width: "45%" }}
        disabled={
          requestLoading ||
          taxesPercentage.value === "" ||
          monthlyExpenses.value === ""
        }
        handleButtonClick={handleUpdateClick}
        requestLoading={requestLoading}
      />
    </Box>
  );
}
