import { DateRangePicker, createStaticRanges } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import {
  startOfToday,
  startOfWeek,
  startOfMonth,
  subDays,
  endOfToday,
  endOfWeek,
  endOfMonth,
  startOfYear,
} from "date-fns";
import { Box } from "@mui/material";

export default function CustomDateRangePicker({ ranges, onChange }) {
  const predefinedRanges = createStaticRanges([
    {
      label: "Hoje",
      range: () => ({
        startDate: startOfToday(),
        endDate: endOfToday(),
      }),
    },
    {
      label: "Ontem",
      range: () => ({
        startDate: subDays(startOfToday(), 1),
        endDate: subDays(endOfToday(), 1),
      }),
    },
    {
      label: "Esta Semana",
      range: () => ({
        startDate: startOfWeek(startOfToday(), { weekStartsOn: 0 }), // Sunday as start of the week
        endDate: endOfToday(),
      }),
    },
    {
      label: "Semana Passada",
      range: () => ({
        startDate: startOfWeek(subDays(startOfToday(), 7), { weekStartsOn: 0 }),
        endDate: endOfWeek(subDays(startOfToday(), 7), { weekStartsOn: 0 }),
      }),
    },
    {
      label: "Este Mês",
      range: () => ({
        startDate: startOfMonth(startOfToday()),
        endDate: endOfToday(),
      }),
    },
    {
      label: "Mês Passado",
      range: () => ({
        startDate: startOfMonth(subDays(startOfMonth(startOfToday()), 1)),
        endDate: endOfMonth(subDays(startOfMonth(startOfToday()), 1)),
      }),
    },
    {
      label: "Últimos 30 dias",
      range: () => ({
        startDate: subDays(startOfToday(), 30),
        endDate: endOfToday(),
      }),
    },
    {
      label: "Este ano",
      range: () => ({
        startDate: startOfYear(startOfToday()),
        endDate: endOfToday(),
      }),
    },
  ]);

  return (
    <Box sx={{ boxShadow: 3, mt: 3 }}>
      <DateRangePicker
        ranges={ranges}
        onChange={onChange}
        moveRangeOnFirstSelection={false}
        staticRanges={predefinedRanges}
        inputRanges={[]}
      />
    </Box>
  );
}
