import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const useLoadingToast = () => {
  const initializeLoadingToast = ({ text, options }) => {
    const id = toast.loading(text, {
      position: toast.POSITION.TOP_RIGHT,
      draggable: false,
      theme: "dark",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,

      ...options,
    });

    return id;
  };

  const updateLoadingToast = ({ id, text, type }) => {
    if (id) {
      toast.update(id, {
        render: text,
        type: type,
        isLoading: false,

        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  return { initializeLoadingToast, updateLoadingToast };
};

export const useErrorToast = () => {
  const initializeErrorToast = ({ text, options }) => {
    const id = toast.error(text, {
      position: toast.POSITION.TOP_RIGHT,
      draggable: false,
      theme: "dark",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      progress: undefined,
      ...options,
    });

    return id;
  };

  return { initializeErrorToast };
};
