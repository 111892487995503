import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import React, { useContext, useState } from "react";
import { useQueryClient } from "react-query";
import { ApiDataContext } from "../../../contexts/ApiDataContext";
import { useAutocomplete } from "../../../hooks/forms/FormHooks";
import {
  useCheckBox,
  useManagementTextField,
} from "../../../hooks/forms/ManagementHooks";
import { useLoadingToast } from "../../../hooks/toast/toasts";
import { createPdc } from "../../../services/api/api";
import CustomAutoComplete from "../../autoCompletes/CustomAutoComplete";
import CustomLoadingButton from "../../button/CustomLoadingButton";
import { FormatMoney } from "../../format/Formats";
import CustomTextField from "../../textFields/CustomTextField";
import FormatedCustomTextField from "../../textFields/FormatedCustomTextField";
import { ToastContainer } from "react-toastify";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export default function CreatePowerDistributionCompany() {
  const { apiData } = useContext(ApiDataContext);
  const { initializeLoadingToast, updateLoadingToast } = useLoadingToast();

  const {
    reset: resetPowerDistributionCompanyName,
    ...powerDistributionCompanyName
  } = useManagementTextField("");

  const hasProcessing = useCheckBox(false);
  const hasADifferentPrice = useCheckBox(false);

  const { reset: resetState, ...state } = useAutocomplete("", false);

  const { reset: resetPrice, ...price } = useManagementTextField("");

  const isDisabled = false;

  const clearAllFields = () => {
    resetPowerDistributionCompanyName();
    resetState();
    resetPrice();
  };

  const [requestLoading, setRequestLoading] = useState(false);

  const queryClient = useQueryClient();

  const handleClick = async () => {
    let createToastId;
    try {
      setRequestLoading(true);

      createToastId = initializeLoadingToast({
        text: `Criando Concessionária...`,
      });

      const formattedPrice = price.value == "" ? null : parseInt(price.value);

      const { status } = await createPdc(
        powerDistributionCompanyName.value,
        state.value.id,
        hasProcessing.checked,
        hasADifferentPrice.checked,
        formattedPrice
      );

      if (status === 201) {
        clearAllFields();
        queryClient.invalidateQueries({ queryKey: ["baseDataQuery"] });

        updateLoadingToast({
          id: createToastId,
          text: `Concessionária Criada com sucesso`,
          type: "success",
        });
      }
    } catch (error) {
      updateLoadingToast({
        id: createToastId,
        text:
          error.response.data.message ??
          "Erro ao criar Concessionária, tente novamente",
        type: "error",
      });
    } finally {
      setRequestLoading(false);
    }
  };

  const buttonStyle = {
    alignSelf: "flex-end",
    mt: 5,
    width: "20%",
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        height: 300,
      }}
    >
      <ToastContainer />

      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          p: 2,
          justifyContent: "space-between",
          borderRadius: 3,
          height: "80%",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              width: "45%",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <CustomTextField
              params={powerDistributionCompanyName}
              disabled={isDisabled}
              label="Nome da Concessionária"
              errorLabel="Nome Incorreto"
              maxLength={30}
            />
            <CustomAutoComplete
              params={state}
              options={apiData.states}
              disabled={isDisabled}
              label="Estado"
              width="100%"
            />
          </Box>
          <Box
            sx={{
              width: "45%",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <FormControlLabel
              control={<Checkbox {...hasProcessing} />}
              label="Possui processamento?"
              labelPlacement="start"
            />
            <Box display="flex" sx={{ justifyContent: "space-between" }}>
              <FormControlLabel
                control={<Checkbox {...hasADifferentPrice} />}
                label="Outro Valor de ART?"
                labelPlacement="start"
              />
              <FormatedCustomTextField
                params={price}
                disabled={!hasADifferentPrice.checked}
                label="Valor"
                errorLabel="Valor Incorreto"
                inputcomponent={FormatMoney}
                required={false}
                width="45%"
              />
            </Box>
          </Box>
        </Box>

        <CustomLoadingButton
          buttonText="Cadastrar"
          buttonLoadingText="Cadastrando..."
          sx={buttonStyle}
          disabled={requestLoading}
          handleButtonClick={handleClick}
          requestLoading={requestLoading}
        />
      </Box>
    </Box>
  );
}
