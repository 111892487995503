import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import React, { useContext, useState } from "react";
import { useQueryClient } from "react-query";
import { ApiDataContext } from "../../../../contexts/ApiDataContext";
import { AuthContext } from "../../../../contexts/AuthContext";
import useDialog from "../../../../hooks/dialog/useDialog";
import { useAutocomplete } from "../../../../hooks/forms/FormHooks";
import { useManagementTextField } from "../../../../hooks/forms/ManagementHooks";
import { useLoginTextFields } from "../../../../hooks/forms/loginHooks";
import { deleteUser, updateUser } from "../../../../services/api/api";
import CustomAutoComplete from "../../../autoCompletes/CustomAutoComplete";
import MultipleAutocomplete from "../../../autoCompletes/MultipleAutocomplete";
import { FormatMoney, FormatPercentage } from "../../../format/Formats";
import CustomTextField from "../../../textFields/CustomTextField";
import EmailTextField from "../../../textFields/EmailTextField";
import FormatedCustomTextField from "../../../textFields/FormatedCustomTextField";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function UsersResult({
  userData,
  initializeLoadingToast,
  updateLoadingToast,
}) {
  const { apiData } = useContext(ApiDataContext);
  const { setAuth } = useContext(AuthContext);

  const { reset: emailReset, ...email } = useLoginTextFields(
    "email",
    userData.email
  );

  const [isUserEditing, setIsUserEditing] = useState(false);

  const handleEditClick = () => {
    setIsUserEditing(!isUserEditing);
  };

  const { open, handleClickOpen, handleClickClose } = useDialog();

  const queryClient = useQueryClient();

  const [requestLoading, setRequestLoading] = useState(false);
  const [deleteRequestLoading, setDeleteRequestLoading] = useState(false);

  const handleDeleteUser = async () => {
    let deleteToastId;
    try {
      setDeleteRequestLoading(true);
      deleteToastId = initializeLoadingToast({
        text: `Excluindo Usuário...`,
      });

      await deleteUser(userData.id);

      queryClient.invalidateQueries({ queryKey: ["users"] });
      queryClient.invalidateQueries({ queryKey: ["allowedEmails"] });

      updateLoadingToast({
        id: deleteToastId,
        text: `Usuário Excluído com sucesso`,
        type: "success",
      });
    } catch (error) {
      updateLoadingToast({
        id: deleteToastId,
        text:
          error.response.data.message ??
          `Erro ao Excluir Usuário, tente novamente`,
        type: "error",
      });
    } finally {
      setDeleteRequestLoading(false);
      handleClickClose();
    }
  };

  const handleUpdateUser = async () => {
    let updateToastId;
    try {
      setRequestLoading(true);
      updateToastId = initializeLoadingToast({
        text: `Atualizando Usuário...`,
      });

      const { data: updatedUser } = await updateUser(
        userData.id,
        userName?.value,
        role?.value?.role,
        jobRole?.value?.id,
        wage?.value,
        comission?.value,
        selectedProvidences
      );

      const recoveredUser = JSON.parse(localStorage.getItem("user"));

      if (userData.id === recoveredUser.id) {
        localStorage.setItem("user", JSON.stringify(updatedUser));
        setAuth(updatedUser);
      }

      queryClient.invalidateQueries({ queryKey: ["users"] });

      updateLoadingToast({
        id: updateToastId,
        text: `Usuário Atualizado com sucesso`,
        type: "success",
      });
    } catch (error) {
      updateLoadingToast({
        id: updateToastId,
        text:
          error.response.data.message ??
          `Erro ao atualizar Usuário, tente novamente`,
        type: "error",
      });
    } finally {
      setRequestLoading(false);
      handleEditClick();
    }
  };

  const { reset: resetProjectName, ...userName } = useManagementTextField(
    userData.userName
  );

  const { reset: resetWage, ...wage } = useManagementTextField(userData.wage);

  const { reset: resetComission, ...comission } = useManagementTextField(
    userData.comission
  );

  let parsedUser = { role: userData.roles };

  if (userData.roles === 2019) {
    parsedUser.name = "Admin";
  } else {
    parsedUser.name = "Usuário";
  }

  const { reset: resetRole, ...role } = useAutocomplete(parsedUser);

  const { reset: resetJobRole, ...jobRole } = useAutocomplete(
    userData.jobRoles
  );

  const roles = [
    { name: "Admin", role: 2019 },
    { name: "Usuário", role: 5050 },
  ];

  const findProvidence = (usersProvidences) => {
    return usersProvidences.map((providence) =>
      apiData.providences.find(
        (apiProvidence) =>
          apiProvidence.id === providence.providenceId &&
          apiProvidence.providenceName === providence.providences.providenceName
      )
    );
  };

  const [selectedProvidences, setSelectedProvidences] = useState(
    findProvidence(userData.usersProvidences)
  );

  const handleProvidenceSelect = (event) => {
    setSelectedProvidences(event.target.value);
  };

  const isProvidenceChecked = (selected) => {
    let isEqual = false;

    selectedProvidences.forEach((e) => {
      if (e.id === selected.id) {
        isEqual = true;
      }
    });
    return isEqual;
  };

  const userProvidencesParams = {
    value: selectedProvidences,
    onChange: handleProvidenceSelect,
  };

  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleClickClose}>
        <DialogTitle>Excluir usuário {userData.userName} </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Caso o email não seja removido dos permitidos, poderá ser criado um
            novo usuário utilizando este.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickClose} variant="outlined">
            Cancelar
          </Button>
          <Button
            onClick={handleDeleteUser}
            variant="contained"
            color="error"
            autoFocus
          >
            Excluir
          </Button>
        </DialogActions>
      </Dialog>

      <Box
        display="flex"
        sx={{
          justifyContent: "space-between",
          flexDirection: "column",
          borderRadius: 2,
          p: 2,
          backgroundColor: "#D9D9D9",
          boxShadow: 3,
          mb: 1,
        }}
      >
        <Box
          display="flex"
          width="100%"
          justifyContent="space-between"
          sx={{ mb: 1 }}
        >
          <Typography sx={{ color: "green" }}>
            {userData?.userName == undefined
              ? null
              : `Usuário: ${userData?.userName}`}
          </Typography>
          <Box width="20%" display="flex" justifyContent="space-between">
            <Button
              variant="text"
              startIcon={<EditIcon />}
              onClick={handleEditClick}
            >
              Editar
            </Button>
            <Button
              variant="text"
              startIcon={<DeleteIcon />}
              color="error"
              disabled={isUserEditing || requestLoading || deleteRequestLoading}
              onClick={handleClickOpen}
            >
              {deleteRequestLoading ? "Excluindo..." : "Excluir"}
            </Button>
          </Box>
        </Box>
        <Box
          display="flex"
          // justifyContent="space-between"
          alignItems="flex-start"
          height={300}
          gap={2}
        >
          <Box
            display="flex"
            flexDirection="column"
            width="40%"
            // justifyContent="space-between"
            gap={2}
          >
            <EmailTextField
              params={email}
              required={true}
              width="100%"
              disabled={!isUserEditing}
            />
            <CustomTextField
              params={userName}
              disabled={!isUserEditing}
              label="Nome de Usuário"
              errorLabel="Nome Incorreto"
              maxLength={30}
            />
            <CustomAutoComplete
              params={role}
              options={roles}
              disabled={!isUserEditing}
              label="Permissões"
              width="100%"
            />
            <CustomAutoComplete
              params={jobRole}
              options={apiData.jobRoles}
              disabled={!isUserEditing}
              label="Cargo"
              width="100%"
            />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            width="40%"
            justifyContent="space-between"
            gap={2}
          >
            <FormatedCustomTextField
              params={wage}
              disabled={!isUserEditing}
              label="Salário"
              errorLabel="Valor Incorreto"
              inputcomponent={FormatMoney}
              required={false}
              width="100%"
            />
            <FormatedCustomTextField
              params={comission}
              disabled={!isUserEditing}
              label="Comissão"
              errorLabel="Valor Incorreto"
              inputcomponent={FormatPercentage}
              required={false}
              width="100%"
            />

            <MultipleAutocomplete
              width={"100%"}
              required={false}
              disabled={!isUserEditing}
              valueKey="providenceName"
              label="Funções"
              params={userProvidencesParams}
              values={apiData.providences}
              isChecked={isProvidenceChecked}
            />
          </Box>
        </Box>
        {isUserEditing && (
          <Button
            sx={{ height: "15%", alignSelf: "flex-end" }}
            variant="outlined"
            disabled={
              email.error ||
              email.value === "" ||
              requestLoading ||
              deleteRequestLoading
            }
            onClick={handleUpdateUser}
          >
            {requestLoading ? "Salvando..." : "Salvar"}
          </Button>
        )}
      </Box>
    </React.Fragment>
  );
}
