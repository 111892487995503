import { useContext, useEffect, useState } from "react";
import { ApiDataContext } from "../../contexts/ApiDataContext";

export const useManagementTextField = (initialValue) => {
  const [value, setValue] = useState("");
  const [error, setError] = useState(false);
  const [errorLabel, setErrorLabel] = useState("");

  const [initializedValue, setInitializedValue] = useState("");

  const parsedInitialValue = initialValue === undefined ? "" : initialValue;

  let onChange;

  useEffect(() => {
    if (parsedInitialValue !== "" || parsedInitialValue === 0) {
      setValue(parsedInitialValue);
      setInitializedValue(parsedInitialValue);
    } else {
      setValue("");
      setInitializedValue("");
    }
  }, [parsedInitialValue]);

  onChange = (event) => {
    setValue(event.target.value);
  };

  const onCustomChange = (value) => {
    setValue(value);
  };

  const reset = () => {
    setValue(initializedValue);
  };

  const isFieldValid = () => {
    if (value.trim() === "") {
      setError(true);
      setErrorLabel("O campo é obrigatório");
      return false;
    }

    setError(false);
    setErrorLabel("");
    return true;
  };

  const removeFieldError = () => {
    if (error) {
      setError(false);
      setErrorLabel("");
    }
  };

  return {
    value,
    error,
    onChange,
    reset,
    onCustomChange,
    errorLabel,
    isFieldValid,
    removeFieldError,
  };
};

export const useCheckBox = (initialValue) => {
  const [checked, setChecked] = useState(initialValue);

  const parsedInitialValue = initialValue === undefined ? false : initialValue;

  useEffect(() => {
    setChecked(parsedInitialValue);
  }, [initialValue]);

  const onChange = () => {
    setChecked(!checked);
  };

  return {
    checked,
    onChange,
  };
};

export const useMultiplePowerDistributionCompanies = (initialValue) => {
  const [value, setValue] = useState([]);
  const [error, setError] = useState(false);
  const [errorLabel, setErrorLabel] = useState("");

  useEffect(() => {
    if (initialValue) {
      setValue(initialValue);
    }
  }, []);

  useEffect(() => {
    if (initialValue) {
      setValue(initialValue);
    }
  }, [initialValue]);

  const onChange = (event) => {
    const selected = event.target.value;
    setValue(selected);
  };

  const isChecked = (selected) => {
    let isEqual = false;

    value.forEach((e) => {
      if (e.id === selected.id) {
        isEqual = true;
      }
    });
    return isEqual;
  };

  const reset = () => {
    setValue([]);
  };

  const isFieldValid = () => {
    if (value.length === 0) {
      setError(true);
      setErrorLabel("O campo é obrigatório");
      return false;
    }

    setError(false);
    setErrorLabel("");
    return true;
  };

  const removeFieldError = () => {
    if (error) {
      setError(false);
      setErrorLabel("");
    }
  };

  return {
    isChecked,
    reset,
    value,
    onChange,
    error,
    errorLabel,
    isFieldValid,
    removeFieldError,
  };
};

export const useMultipleFunctions = (initialValue) => {
  const [value, setValue] = useState([]);

  const apiData = useContext(ApiDataContext);

  useEffect(() => {
    if (value.length === 0) {
      if (initialValue) {
        setValue(initialValue);
      }
    }
  }, []);

  useEffect(() => {
    if (value.length === 0) {
      if (initialValue) {
        setValue(initialValue);
      }
    }
  }, [initialValue]);

  const onChange = (event, newValue) => {
    setValue(event.target.value);
  };

  const isChecked = (selected) => {
    let isEqual = false;

    value.forEach((e) => {
      if (e.id === selected.id) {
        isEqual = true;
      }
    });
    return isEqual;
  };

  const reset = () => {
    setValue([]);
  };

  return {
    isChecked,
    reset,
    value,
    onChange,
  };
};
