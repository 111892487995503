import { useQuery } from "react-query";
import { getBaseData } from "../../services/api/api";


const baseDataApi = {
    fetchBaseData: async () => {
      const { data } = await getBaseData();
      return data;
    },
  };

export const useBaseData = () => {
    const { isLoading: baseDataLoading, data: baseData, error: baseDataError,  } = useQuery(
        "baseDataQuery",
        baseDataApi.fetchBaseData,
        {
          staleTime: Infinity,
          retry: 1
        }
      );
      
      return {
        baseDataLoading,
        baseData,
        baseDataError
      }
}