import { api } from "../api";

export const getComissionVariables = async (date) => {
  let url = `/comissionsVariables/${date}`;
  return await api.get(url);
};

export const updateComissionVariables = async (id, taxes, monthlyExpenses) => {
  let url = `/comissionsVariables/${id}`;
  return await api.put(url, {
    taxes,
    monthlyExpenses,
  });
};

export const createComissionVariables = async ({
  taxes,
  monthlyExpenses,
  date,
}) => {
  let url = `/comissionsVariables`;
  return await api.post(url, {
    taxes,
    monthlyExpenses,
    date: date,
  });
};
