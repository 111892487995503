import React from "react";
import { useSelector } from "react-redux";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { selectCurrentTheme } from "../store/ducks/themeDuck";
import { darkTheme, lightTheme } from "../styles/themes";

const Theme = ({ children }) => {
  const currentTheme = useSelector(selectCurrentTheme);
  const theme = currentTheme === "light" ? lightTheme : darkTheme;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default Theme;
