import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MenuIcon from "@mui/icons-material/Menu";

import PersonIcon from "@mui/icons-material/Person";
import { Avatar, Box, CssBaseline } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/system";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentTheme, toggleTheme } from "../store/ducks/themeDuck";
import ListItems from "./listItems";

const drawerWidth = 215;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

export default function AppBarMenu({ children, icons }) {
  const [open, setOpen] = useState(true);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const theme = useTheme();
  // const isLightMode = theme.palette.mode === "light";
  const currentTheme = useSelector(selectCurrentTheme);
  const isLightMode = currentTheme === "light";
  const dispatch = useDispatch();
  const textColor = theme.palette.text.primary;
  const dividerColor = theme.palette.icon.secondary;

  const handleToggleTheme = () => {
    dispatch(toggleTheme());
  };

  const { userName } = JSON.parse(localStorage.getItem("user"));

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <AppBar
        position="absolute"
        open={open}
        sx={{ backgroundColor: theme.palette.primary.main }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>

          {icons ? icons : ""}

          {/* <IconButton
            color="inherit"
            onClick={handleToggleTheme}
            sx={{
              marginLeft: "auto",
              marginRight: "20px",
            }}
          >
            {isLightMode ? <DarkModeIcon /> : <LightModeIcon />}
          </IconButton> */}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        PaperProps={{
          sx: {
            backgroundColor: theme.palette.background.default,
            color: textColor,
          },
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            px: [0],
            // px: [1],
          }}
        >
          <Avatar>
            <PersonIcon />
          </Avatar>
          <Typography
            sx={{
              fontWeight: "bold",
              maxWidth: "110px",
              whiteSpace: "pre-line",
            }}
          >
            {userName}
          </Typography>

          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon style={{ color: textColor }} />
          </IconButton>
        </Toolbar>
        <Divider sx={{ backgroundColor: dividerColor }} />
        <List component="nav">
          <ListItems />
        </List>
      </Drawer>

      <Box
        component="main"
        sx={{
          backgroundColor: theme.palette.background.secondary,
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />

        {children}
      </Box>
    </Box>
  );
}
