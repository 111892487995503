import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import React, { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import EmailTextField from "../components/textFields/EmailTextField";
import PasswordTextField from "../components/textFields/PasswordTextField";
import { AuthContext } from "../contexts/AuthContext";
import { useLoginTextFields, usePassword } from "../hooks/forms/loginHooks";
import { useLoadingToast } from "../hooks/toast/toasts";
import CompleteLogo from "../media/logo/CompleteLogoBlue.png";

export default function SignUpPage() {
  const { signUp } = useContext(AuthContext);
  const { initializeLoadingToast, updateLoadingToast } = useLoadingToast();

  const { reset: nameReset, ...name } = useLoginTextFields();
  const { reset: emailReset, ...email } = useLoginTextFields("email");
  const { reset: passwordReset, ...password } = useLoginTextFields();
  const { showPassword, handleClickShowPassword } = usePassword();

  const [loading, setLoading] = useState(false);

  const handleSignUpClick = async (event) => {
    event.preventDefault();
    let signUpToastId;
    try {
      setLoading(true);
      signUpToastId = initializeLoadingToast({
        text: `Verificando Campos...`,
      });

      if (email.value === "" || password.value === "" || name.value === "") {
        const error = new Error("ClientError");
        error.response = {
          status: 400,
          statusText: "Campos não preenchidos",
        };
        throw error;
      }

      if (!email.error) {
        await signUp(name.value, email.value, password.value);

        updateLoadingToast({
          id: signUpToastId,
          text: `Conta Criada com sucesso, você será redirecionado...`,
          type: "success",
        });
      }
    } catch (error) {
      updateLoadingToast({
        id: signUpToastId,
        text:
          error?.response?.status === 400
            ? "Verifique os campos e tente novamente."
            : error?.response?.data?.message,
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      display="flex"
      width="100vw"
      height="100vh"
      sx={{
        backgroundColor: "#1D1F2D",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        display="flex"
        height="80%"
        width="80%"
        maxWidth="400px"
        sx={{
          borderRadius: 10,
          backgroundColor: "#EEEEEE",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          p: 3,
        }}
      >
        <img
          width="70%"
          src={CompleteLogo}
          alt="A logo that contains a blue chef hat and under it a blue mustache. On the left, on the same size as the image and in black it is written 'Recipe mate, '"
          style={{ marginBottom: 25 }}
        />
        <ToastContainer />

        <form style={{ width: "100%" }} onSubmit={handleSignUpClick}>
          <TextField fullWidth required label="Nome" sx={{ mb: 2 }} {...name} />
          <EmailTextField required={true} params={email} width="100%" disabled={false} />
          <PasswordTextField
            showPassword={showPassword}
            params={password}
            handleClickShowPassword={handleClickShowPassword}
          />
          <Button
            fullWidth
            type="submit"
            variant="contained"
            disabled={loading}
            sx={{ backgroundColor: "#CA9900", mt: 3, mb: 3 }}
          >
            {loading ? "Carregando..." : "Cadastre - se"}
          </Button>
        </form>

        <Box display="flex" sx={{ justifyContent: "space-between", alignSelf: "flex-end" }}>
          <NavLink style={{ textDecoration: "none", color: "#727482" }} to="/signIn">
            Já possui conta? Conecte-se
          </NavLink>
        </Box>
      </Box>
    </Box>
  );
}
