import { useEffect, useState } from "react";

export const useFormField = (type, initialValue, autocompleteInitialValueLoading) => {
  const [value, setValue] = useState("");
  const [firstValue, setFirstValue] = useState("");
  let onChange;
  const verifiedInitialValue = initialValue === undefined ? "" : initialValue;

  useEffect(() => {
    if (type === "autocomplete") {
      if (!autocompleteInitialValueLoading) {
        if (value === "") {
          setValue(verifiedInitialValue);
          setFirstValue(verifiedInitialValue);
        }
      }
    } else if (type === "autocompleteChangeInitialValue") {
      if (!autocompleteInitialValueLoading) {
        setValue(verifiedInitialValue);
        setFirstValue(verifiedInitialValue);
      }
    } else if (type === "text") {
      setValue(verifiedInitialValue);
      // setFirstValue(verifiedInitialValue);
    }
  }, [verifiedInitialValue, initialValue]);

  if (type === "autocomplete" || type === "autocompleteChangeInitialValue") {
    onChange = (event, newValue) => {
      setValue(newValue);
    };
  } else {
    onChange = (event) => {
      setValue(event.target.value);
    };
  }

  const reset = () => {
    setValue(firstValue);
  };

  return {
    value,
    onChange,
    reset,
  };
};

export const useAutocomplete = (initialValue, autocompleteInitialValueLoading) => {
  const [value, setValue] = useState(initialValue);
  const [initialValueSet, setInitialValueSet] = useState(false);

  useEffect(() => {
    if (!initialValueSet && !autocompleteInitialValueLoading) {
      setValue(initialValue);
      setInitialValueSet(true);
    }
  }, [initialValue, autocompleteInitialValueLoading, initialValueSet]);

  const onChange = (event, newValue) => {
    setValue(newValue);
  };

  const reset = () => {
    setValue(initialValue);
  };

  return {
    value,
    onChange,
    reset,
  };
};
