import { api } from "../api";

export const getNextProjectNumber = async (internalClient) => {
  let url = `/manage/project-number`;

  return await api.get(url, {
    params: { isInternalClient: internalClient },
  });
};

export const getAddressFromZipCode = async (zipCode) => {
  let url = `/manage/address/${zipCode}`;
  return await api.get(url);
};

export const getProjectPrice = async (power, isInternalClient, isEngineer, isArtDifferent) => {
  let url = `/manage/project-price`;

  return await api.post(url, {
    power,
    isInternalClient,
    isEngineer,
    isArtDifferent,
  });
};

export const getProject = async (projectNumber) => {
  let url = `manage/project/${projectNumber}`;

  return await api.get(url);
};

export const commonUpdateProject = async (
  projectId,
  projectName,
  historyStageId,
  historyProvidenceId,
  stageId,
  providenceId,
  deadline,
  conditionId,
  note,
  sentDate,
  secondSentDate,
  approvalDate,
  inspectionDate,
  finishDate
) => {
  let url = `/manage/project/${projectId}`;

  return await api.put(url, {
    projectName,
    historyStageId,
    historyProvidenceId,
    stageId,
    providenceId,
    deadline,
    conditionId,
    note,
    sentDate,
    secondSentDate,
    approvalDate,
    inspectionDate,
    finishDate,
  });
};

export const simpleUpdateProject = async (
  projectId,
  data
  // projectName,
  // clientId,
  // powerDistributionCompanyId,
  // engineerId,
  // power,
  // price,
  // clientType,
  // npParams,
  // leParams,
  // street,
  // number,
  // district,
  // addressDetails,
  // city,
  // state,
  // zipCode
) => {
  let url = `/manage/project-simple/${projectId}`;

  return await api.put(url, data);
};
