import { Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { NumericFormat, PatternFormat } from "react-number-format";

export const FormatPower = React.forwardRef(function FormatPower(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      allowNegative={false}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            //   name: props.name,
            value: values.value,
          },
        });
      }}
      decimalSeparator={","}
      thousandSeparator={"."}
      valueIsNumericString
      suffix=" kW"
    />
  );
});

FormatPower.propTypes = {
  // name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export const FormatMoney = React.forwardRef(function FormatMoney(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            //   name: props.name,
            value: values.value,
          },
        });
      }}
      decimalScale={5}
      decimalSeparator={","}
      thousandSeparator={"."}
      valueIsNumericString
      prefix="R$"
    />
  );
});

FormatMoney.propTypes = {
  // name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export const FormatMoneyTypography = (props) => {
  const { value, sx } = props;

  return (
    <NumericFormat
      value={parseFloat(value)}
      displayType={"text"}
      decimalScale={2}
      decimalSeparator={","}
      thousandSeparator={"."}
      prefix="R$ "
      customInput={Typography}
      style={sx}
    />
  );
};

FormatMoneyTypography.propTypes = {
  value: PropTypes.string.isRequired,
  // onChange: PropTypes.func.isRequired,
};

export const FormatPercentage = React.forwardRef(function FormatPercentage(
  props,
  ref
) {
  const { onChange, ...other } = props;

  const limit = (value) => {
    if (value <= 100) {
      return value.toString();
    } else if (value === undefined) {
      return "";
    } else if (value > 100) {
      return "";
    }
  };

  return (
    <NumericFormat
      {...other}
      allowNegative={false}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            //   name: props.name,
            value: limit(values.floatValue),
          },
        });
      }}
      decimalScale={2}
      decimalSeparator={","}
      thousandSeparator={"."}
      valueIsNumericString
      suffix="%"
    />
  );
});

FormatPercentage.propTypes = {
  // name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export const FormatPercentageTypography = (props) => {
  const { value, sx } = props;
  return (
    <NumericFormat
      value={value}
      displayType={"text"}
      decimalScale={2}
      decimalSeparator={","}
      thousandSeparator={"."}
      suffix="%"
      customInput={Typography}
      valueIsNumericString
      style={sx}
    />
  );
};

FormatPercentageTypography.propTypes = {
  value: PropTypes.string.isRequired,
  // onChange: PropTypes.func.isRequired,
};

export const FormatCellPhone = React.forwardRef(function FormatCellPhone(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <PatternFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            //   name: props.name,
            value: values.value,
          },
        });
      }}
      format="(##) # ####-####"
      mask="_"
      type="tel"
      valueIsNumericString
    />
  );
});

FormatCellPhone.propTypes = {
  // name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export const FormatCPF = React.forwardRef(function FormatCPF(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <PatternFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            //   name: props.name,
            value: values.value,
          },
        });
      }}
      format="###.###.###-##"
      mask="_"
      type="text"
      valueIsNumericString
    />
  );
});

FormatCPF.propTypes = {
  // name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export const FormatCNPJ = React.forwardRef(function FormatCNPJ(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <PatternFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            //   name: props.name,
            value: values.value,
          },
        });
      }}
      format="##.###.###/####-##"
      mask="_"
      type="text"
      valueIsNumericString
    />
  );
});

FormatCNPJ.propTypes = {
  // name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export const FormatZipCode = React.forwardRef(function FormatZipCode(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <PatternFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            //   name: props.name,
            value: values.value,
          },
        });
      }}
      format="#####-###"
      mask="_"
      type="text"
      valueIsNumericString
    />
  );
});

FormatZipCode.propTypes = {
  // name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
