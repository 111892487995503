import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React from "react";

export default function EmailTextField({params: params, required: required, width: width, disabled: disabled}) {

  return (
    <Box width={width} >
      <TextField fullWidth required={required} label="E-mail" {...params} disabled={disabled} />
      <Typography color="error">
        {params.error ? "E-mail inválido" : ""}
      </Typography>
    </Box>

  );
}
