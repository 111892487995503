import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import React, { useContext, useState } from "react";
import { useQueryClient } from "react-query";
import { ApiDataContext } from "../../contexts/ApiDataContext";
import { useAutocomplete, useFormField } from "../../hooks/forms/FormHooks";
import { updateFinishedActivity } from "../../services/api/api";
import CustomButton from "../button/CustomButton";
import CustomLoadingButton from "../button/CustomLoadingButton";
import DatePicker from "../DatePicker";
import { useDatePicker } from "../../hooks/forms/useDatePicker";

export default function ActivityConfirmationModal({
  isActivityModalOpen,
  setIsActivityModalOpen,
  activityData,
  cancelRequestLoading,
}) {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isActivityComplete, setIsActivityComplete] = useState(true);
  const [isAddObsMarked, setIsAddObsMarked] = useState(false);

  const { apiData } = useContext(ApiDataContext);
  const currentProvidence = activityData.providenceName;
  const currentStage = activityData.stageName;

  const handleContinue = () => {
    setIsConfirmationModalOpen(true);
  };

  const queryClient = useQueryClient();
  const [finishedActivityRequestLoading, setFinishedActivityRequestLoading] = useState(false);

  const handleCompletedFinish = async () => {
    try {
      if (!isActivityComplete) {
        return;
      }

      setFinishedActivityRequestLoading(true);

      const projectId = activityData.id;

      const providenceHistoryId = activityData.providenceHistoryId;
      const stageHistoryId = activityData.stageHistoryId;

      const { status } = await updateFinishedActivity(
        projectId,
        providenceHistoryId,
        stageHistoryId,
        deadline.value,
        stage.value.id,
        providence.value.id,
        observation.value
      );

      queryClient.invalidateQueries({ queryKey: ["userProvidences"] });
      queryClient.invalidateQueries({ queryKey: ["homeBaseQuery"] });

      setIsActivityModalOpen(false);
      setIsConfirmationModalOpen(false);
    } catch (error) {
    } finally {
      setFinishedActivityRequestLoading(false);
    }
  };

  const handleIncompleteFinish = () => {
    setIsActivityModalOpen(false);
    setIsConfirmationModalOpen(false);
  };

  const handleClose = () => {
    setIsConfirmationModalOpen(false);
  };

  const handleIsActivityCompleteSwitch = () => {
    setIsActivityComplete(!isActivityComplete);
  };

  const handleIsAddObsMarked = () => {
    setIsAddObsMarked(!isAddObsMarked);
  };

  // const { nextStageAndProvidenceLoading, nextStageAndProvidenceData } =
  //   useNextStageAndProvidence(currentProvidence, currentStage);

  const { reset: resetStage, ...stage } = useAutocomplete(
    { id: activityData.stageId, stageName: activityData.stageName },
    false
  );

  const { reset: resetProvidence, ...providence } = useAutocomplete(
    {
      id: activityData.providenceId,
      providenceName: activityData.providenceName,
    },
    false
  );

  const { reset: resetDeadline, ...deadline } = useDatePicker(activityData.dueDate);

  const { reset: resetObservation, ...observation } = useFormField("text", activityData.note);

  const buttonStyle = {
    alignSelf: "flex-end",
  };

  return (
    <React.Fragment>
      <CustomButton
        buttonText="Continuar"
        sx={buttonStyle}
        disabled={cancelRequestLoading}
        handleButtonClick={handleContinue}
      />
      {
        // nextStageAndProvidenceLoading
        false ? (
          <Box sx={{ width: "100%" }}>
            <CircularProgress />
          </Box>
        ) : (
          <Dialog
            open={isConfirmationModalOpen}
            onClose={handleClose}
            fullWidth={true}
            maxWidth={"sm"}
          >
            <Box
              display="flex"
              sx={{
                p: 5,
                flexDirection: "column",
                gap: 2,
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <FormControlLabel
                value="start"
                control={
                  <Switch
                    color="primary"
                    onClick={handleIsActivityCompleteSwitch}
                    checked={isActivityComplete}
                  />
                }
                label="Atividade Completa?"
                labelPlacement="start"
              />

              <Autocomplete
                fullWidth
                disableClearable
                disabled={!isActivityComplete}
                options={apiData.stages}
                {...stage}
                isOptionEqualToValue={(option, value) => {
                  if (value.stageName === "") {
                    return true;
                  }
                  return option.stageName === value.stageName;
                }}
                getOptionLabel={(option) => {
                  if (option === "") {
                    return "";
                  }
                  return option.stageName;
                }}
                sx={{ width: "100%" }}
                renderInput={(params) => <TextField {...params} label="Próxima etapa" />}
              />

              <Autocomplete
                fullWidth
                disabled={!isActivityComplete}
                disableClearable
                options={apiData.providences}
                {...providence}
                isOptionEqualToValue={(option, value) => {
                  if (value.providenceName === "") {
                    return true;
                  }
                  return option.providenceName === value.providenceName;
                }}
                getOptionLabel={(option) => {
                  if (option === "") {
                    return "";
                  }
                  return option.providenceName;
                }}
                sx={{ width: "100%" }}
                renderInput={(params) => <TextField {...params} label="Próxima Providência" />}
              />

              <DatePicker
                disabled={!isActivityComplete}
                width="100%"
                label="Prazo Máximo"
                params={deadline}
              />

              {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="Prazo Máximo"
                  format="dd/MM/yyyy"
                  sx={{ width: "100%" }}
                  disabled={!isActivityComplete}
                  {...deadline}
                />
              </LocalizationProvider> */}

              <FormControlLabel
                value="start"
                control={
                  <Switch
                    color="primary"
                    onClick={handleIsAddObsMarked}
                    checked={isAddObsMarked && isActivityComplete}
                    disabled={!isActivityComplete}
                  />
                }
                label="Adicionar Observação?"
                labelPlacement="start"
              />

              <TextField
                label="Observação"
                multiline
                fullWidth
                disabled={!isAddObsMarked || !isActivityComplete}
                maxRows={4}
                type="observation"
                {...observation}
              />

              <Box
                display="flex"
                sx={{
                  justifyContent: "flex-end",
                  gap: 2,
                  p: 1,
                  width: "100%",
                }}
              >
                {!isActivityComplete ? (
                  <Button
                    variant="contained"
                    width="50%"
                    onClick={handleIncompleteFinish}
                    sx={{ backgroundColor: "#CA9900" }}
                  >
                    Sair sem finalizar
                  </Button>
                ) : (
                  <React.Fragment>
                    <Button
                      variant="outlined"
                      width="50%"
                      color="error"
                      onClick={handleClose}
                      disabled={finishedActivityRequestLoading}
                    >
                      Cancelar
                    </Button>

                    <CustomLoadingButton
                      buttonText="Finalizar"
                      buttonLoadingText="Finalizando..."
                      sx={buttonStyle}
                      disabled={finishedActivityRequestLoading}
                      handleButtonClick={handleCompletedFinish}
                      requestLoading={finishedActivityRequestLoading}
                    />
                  </React.Fragment>
                )}
              </Box>
            </Box>
          </Dialog>
        )
      }
    </React.Fragment>
  );
}
