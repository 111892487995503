import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import React, { useContext, useEffect } from "react";

import Box from "@mui/material/Box";

import { FilterContext } from "../../contexts/FilterContext";
import { useFilteredProjects } from "../../hooks/api/homeApiHooks";
import { formatDate } from "../../services/date";

import FilterResultSummary from "./FilterResultSummary";

export default function FilterResult() {
  const [
    selectedProgress,
    setSelectedProgress,
    selectedStages,
    setSelectedStages,
  ] = useContext(FilterContext);

  useEffect(() => {
    if (
      sessionStorage.getItem("selectedProgressName") &&
      sessionStorage.getItem("selectedProgressValue")
    ) {
      let name = sessionStorage.getItem("selectedProgressName");
      let value = sessionStorage.getItem("selectedProgressValue");

      setSelectedProgress({ name: name, value: value });
    }

    if (sessionStorage.getItem("selectedStages")) {
      let sessionSelectedStages = JSON.parse(
        sessionStorage.getItem("selectedStages")
      );

      setSelectedStages(sessionSelectedStages);
    }
  }, []);

  const parsedSelectedStages = selectedStages
    .map((selectedStage) => selectedStage.id)
    .toString();

  const { filteredProjectsLoading, filteredProjectsData } = useFilteredProjects(
    parsedSelectedStages,
    selectedProgress
  );

  return (
    <React.Fragment>
      <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
        <Typography sx={{ fontSize: "1.1em", fontWeight: 700 }}>
          Projetos {selectedProgress === undefined ? "" : selectedProgress.name}
        </Typography>
        {selectedStages.length === 0 || selectedProgress === undefined ? (
          <Box></Box>
        ) : filteredProjectsLoading ? (
          <Box>
            <CircularProgress />
          </Box>
        ) : (
          <React.Fragment>
            {filteredProjectsData.map((filteredProject, index) => {
              return (
                <FilterResultSummary
                  key={filteredProject.id}
                  index={index}
                  project={filteredProject}
                />
              );
            })}
          </React.Fragment>
        )}
      </Paper>
    </React.Fragment>
  );
}
