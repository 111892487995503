import React from "react";
import { useRadioGroup } from "../../hooks/forms/RadioGroupHooks";
import CustomRadioGroup from "./CustomRadioGroup";
import EditEngineer from "./engineer/EditEngineer";
import CreateEngineer from "./engineer/CreateEngineer";

export default function EngineerManagement() {
  const { ...radioGroupParams } = useRadioGroup("create");

  return (
    <React.Fragment>
      <CustomRadioGroup
        radioGroupParams={radioGroupParams}
        label={"O que fazer?"}
        radioValues={["create", "edit"]}
        radioLabels={["Criar", "Editar"]}
      />
      {radioGroupParams.value === "create" ? (
        <CreateEngineer />
      ) : (
        <EditEngineer />
      )}
    </React.Fragment>
  );
}
