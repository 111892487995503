import { useQuery } from "react-query";
import { getStagesHistory } from "../../services/api/api";

const stagesHistoryApi = {
  fetchStagesHistory: async (projectId) => {
    const { data } = await getStagesHistory(projectId);
    return data;
  },
};

export const useFetchStagesHistory = (projectId) => {
  const { isLoading: stagesHistoryLoading, data: stagesHistoryData } = useQuery(
    ["stagesHistory", projectId],
    () => stagesHistoryApi.fetchStagesHistory(projectId),
    {
      staleTime: Infinity,
      retry: 5,
    }
  );

  return {
    stagesHistoryLoading,
    stagesHistoryData
  }
};
