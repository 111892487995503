import { api } from "../api";

// Filters:
// startDate: Date
// endDate: Date
// clientIds: number[]
export const getPayments = async (filters) => {
  let url = "/finances";
  return await api.get(url, {
    params: filters,
  });
};

export const getSummary = async (filters) => {
  let url = "/finances/summary";
  return await api.get(url, {
    params: filters,
  });
};

export const markAsPaid = async (id) => {
  let url = `/finances/markAsPaid/${id}`;
  return await api.put(url);
};
