import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { green, red } from "@mui/material/colors";
import { useTheme } from "@mui/system";
import React from "react";

export default function CustomLoadingProgressButton({
  requestSuccess,
  requestError,
  requestLoading,
  handleButtonClick,
  buttonText,
  buttonLoadingText,
}) {
  const theme = useTheme();

  const buttonSx = {
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.hover,
    },

    ...(requestSuccess
      ? {
          backgroundColor: green[500],
          "&:hover": {
            backgroundColor: green[700],
          },
        }
      : {}),
    ...(requestError
      ? {
          backgroundColor: red[500],
          "&:hover": {
            backgroundColor: red[700],
          },
        }
      : {}),
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ position: "relative" }}>
        <Button
          variant="contained"
          sx={buttonSx}
          disabled={requestLoading}
          onClick={handleButtonClick}
        >
          {requestLoading ? buttonLoadingText : buttonText}
        </Button>
        {requestLoading && (
          <CircularProgress
            size={24}
            sx={{
              color: green[500],
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        )}
      </Box>
    </Box>
  );
}
