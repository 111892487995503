import { useQuery } from "react-query";
import {
  getFilteredProjects,
  getHomeBaseData,
  getProvidences
} from "../../services/api/api";

const homeApi = {
  fetchHomeBaseData: async () => {
    const { data } = await getHomeBaseData();
    return data;
  },

  fetchProvidences: async () => {
    const {data} = await getProvidences();
    return data;
  },

  fetchFilteredProjects: async (parsedSelectedStages, selectedProgress) => {
    if (parsedSelectedStages.length != 0 && selectedProgress != undefined) {
      const { data } = await getFilteredProjects(
        selectedProgress.value,
        parsedSelectedStages
      );
      return data;
    }
  },
};



export const useHomeBaseData = () => {
  const { isLoading: homeBaseDataLoading, data: homeBaseData } = useQuery(
    "homeBaseQuery",
    homeApi.fetchHomeBaseData,
    {
      staleTime: Infinity,
      retry: 5
    }
  );

  return {
    homeBaseDataLoading,
    homeBaseData,
  };
};



export const useFilteredProjects = (parsedSelectedStages, selectedProgress) => {
  const { isLoading: filteredProjectsLoading, data: filteredProjectsData } =
    useQuery(
      ["filteredProjectsQuery", [parsedSelectedStages, selectedProgress]],
      () =>
        homeApi.fetchFilteredProjects(parsedSelectedStages, selectedProgress),
      {
        staleTime: Infinity,
        retry: 5
      }
    );

  return {
    filteredProjectsLoading,
    filteredProjectsData,
  };
};


export const useProvidences = () => {
  const { isLoading: providencesLoading, data: providencesData } = useQuery(
    "providencesQuery",
    homeApi.fetchProvidences,
    {
      staleTime: Infinity,
      retry: 5
    }
  );

  return {
    providencesLoading,
    providencesData,
  };
};

